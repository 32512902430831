import { Link, useLocation } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import "../.././index.css";
import * as XLSX from "xlsx";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Progress, message } from "antd";
import Select from "@mui/material/Select";
import swal from "sweetalert";
import "react-select-search/style.css";
import {
  setLoading,
  saveDataParser,
  setExcelObject,
  setParserObject,
  parsedData,
  setConfigId,
} from "../../redux/actions/other";
import { MandatoryGroupSheetTable } from "../../components/DataParser/MandatoryGroupSheetTable";
import { SheetTable } from "../../components/DataParser/SheetTable";
import { GroupCheckTable } from "../../components/DataParser/GroupCheckTable";
import { MandatoryGroupSheetTableValidation } from "../../components/DataParser/MandatoryGroupSheetTableValidation";
import { useState, useEffect } from "react";
import { InputLabel, TextField } from "@mui/material";

XLSX = require("xlsx");
const acceptableFileName = ["xlsx", "xls", "csv"];

export default function ParserTabs(props) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const config_jobId = useSelector((state) => state.other.configId);
  const configId = state ? state.active_version.id : config_jobId;
  const [progress, setProgress] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [cusSepertaion1, setcusSepertaion1] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewSettings, setIsPreviewSettings] = useState({
    isAutoDetectPreview: false,
    previewRows: 5,
    isBlankRow: false,
    isSpecifyRange: false,
    isFirstRowHeader: false,
    specifyRange: "",
  });
  const [count, setCount] = useState(1);
  const [delimiterCSV, setDelimiterCSV] = useState();
  const [delimited1, setDelimited1] = useState();
  const [firstUpdatedSheet, setFirstUpdatedSheet] = useState();
  const [cusSepertaion, setcusSepertaion] = useState(false);
  const [cusRecord, setcusRecord] = useState(false);
  const [cusRecord1, setcusRecord1] = useState(false);
  // 3rd screen
  const [delimited, setDelimited] = useState("");
  const [delimitedTwo, setDelimitedTwo] = useState("");
  const [customDelimiterField, setCustomDelimiterField] = useState(false);
  const [customDelimiter, setCustomDelimiter] = useState("");
  const [groupCheckData, setGroupCheckData] = useState([]);
  const [filename, setfilename] = useState("");
  const [alreadyFile, setAlreadyFile] = useState(null);
  console.log(alreadyFile);
  const [groupCheckPreviewData, setGroupCheckPreviewData] = useState([]);
  const [finalParam, setFinalParam] = useState({
    optional_fields: [],
    required_fields: [],
    alias_name: {},
    group_check: [],
    rules: {},
    corporate: null,
    file_prefix: null,
    is_active: true,
    version: null,
    job: null,
    file: "",
    file_type: "",
    job_status: "Parser Configuration",
  });

  const handleChange = (e) => {
    // setselectedfileType(e.target.value);
    setFinalParam({
      ...finalParam,
      job_status: "Parser Configuration",
      file_type: e.target.value,
    });
  };
  const handleFieldSeperation = (event) => {
    // setCustomDelimiter("");
    setDelimitedTwo(event.target.value);
    if (event.target.value === "custom1") {
      setcusSepertaion(true);
      setcusSepertaion1(false);
    } else if (event.target.value === "Custom Expression1") {
      setcusSepertaion1(true);
      setcusSepertaion(false);
    } else {
      setcusSepertaion(false);
      setcusSepertaion1(false);
    }
    if (event.target.value && delimiterCSV) {
      handleThirdDataCsv();
    }
  };

  const handleRecordSeperation = (event) => {
    // setCustomDelimiter("");
    setDelimited1(event.target.value);
    if (event.target.value === "custom2") {
      setcusRecord(true);
      setcusRecord1(false);
    } else if (event.target.value === "custom Expression2") {
      setcusRecord1(true);
      setcusRecord(false);
    } else {
      setcusRecord(false);
      setcusRecord1(false);
    }
    if (event.target.value && delimiterCSV) {
      handleThirdDataCsv();
    }
  };

  /*-------------------File selection screen start-------------------*/

  // file extension
  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  // Handle file after select
  const handleFile = async (e) => {
    setAlreadyFile(null);
    const myFile = e.target.files[0];
    setfilename(myFile.name);
    setFinalParam({ ...finalParam, file: myFile });
    if (!myFile) return;
    if (myFile) {
      // Create a FileReader
      const reader = new FileReader();

      // Listen for the progress event
      reader.onprogress = (e) => {
        if (e?.lengthComputable) {
          const percentLoaded = Math.round((e.loaded / e.total) * 100);
          setProgress(percentLoaded);
        }
      };
      reader.onload = (r) => {
        setShowMessage(true);
      };

      // Read the file
      reader.readAsDataURL(myFile);
    }

    if (!checkFileName(myFile.name)) {
      alert("Invalid file type");
      return;
    }
    setFileName(myFile.name);
    // Read the xlxs meta data
    const data = await myFile.arrayBuffer();
    setTempData(data);
    console.log(myFile, "tempdata");
    // alert("ipload");
  };

  // Click on Next button after file selection

  const handleFirstNext = () => {
    setShowMessage(false);
    if (!finalParam.file_type) {
      alert("Please select file");
    } else if (finalParam.file_type === "text") {
      alert("Please select file");
    } else if (fileName == null) {
      alert("Please select file");
    } else {
      setCount(2);
    }
  };

  // Click on Next button after file import

  const handleSecExcelNext = () => {
    handleSecondData();
    handleThirdData();
    setCount(3);
    setIsPreview(false);
  };

  const handleThirdBack = () => {
    setCount(1);
  };

  const handleThirdBackexcel = () => {
    setCount(2);
  };
  /*-------------------Type detection selection screen start-------------------*/

  // automatic field type detection (Checkbox)
  const handleAutoFieldTypeDetect = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isAutoDetectPreview: !isPreviewSettings?.isAutoDetectPreview,
    }));
  };

  // Handle Previews (Rows)
  const handlePreviewRows = (e) => {
    if (e.target.value <= 0) {
      alert("You cant set preview rows less than 0");
    } else {
      setIsPreviewSettings((prevState) => ({
        ...prevState,
        previewRows: e.target.value,
      }));
    }
  };

  // Handle Blank rows (Checkbox)
  const handleBlankRows = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isBlankRow: !isPreviewSettings.isBlankRow,
    }));
  };

  // Handle First row header (Checkbox)
  const handleFirstRowHeader = () => {
    setGroupCheckData([]);
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isFirstRowHeader: !isPreviewSettings.isFirstRowHeader,
    }));
  };

  // Handle Specify Range (Checkbox)
  const handleSpecifyRange = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isSpecifyRange: !isPreviewSettings.isSpecifyRange,
    }));
  };

  // Handle Specify Range input (Range text box)
  const handleSpecifyRangeInput = (e) => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      specifyRange: e.target.value,
    }));
  };

  const readDataFromExcel1 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};
    let dummyHeading = [];

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, {
        blankrows: !isPreviewSettings?.isBlankRow,
        header: 1,
        defval: "",
        skipHeader: true,
        range: isPreviewSettings.isSpecifyRange
          ? isPreviewSettings.specifyRange !== ""
            ? isPreviewSettings.specifyRange
            : null
          : null,
      });

      for (let i = 0; i < jsonData[0]?.length; i++) {
        dummyHeading.push("Field" + (i + 1));
      }

      if (isPreviewSettings.isFirstRowHeader) {
        jsonData = jsonData.slice(0);
      } else {
        jsonData = [dummyHeading].concat(jsonData);
      }

      const firstSheetData = (mySheetData[sheetName] = jsonData);
      setFirstUpdatedSheet(firstSheetData);
    }
    return data ? mySheetData : data;
  };

  const handleSecondData = () => {
    let mySheetDataFinal = alreadyFile
      ? alreadyFile
      : readDataFromExcel1(tempData);
    if (isPreviewSettings.isBlankRow && finalParam.file_type === "excel") {
      //asuming excel sheet have atleastt 1 sheet
      let sheet1Name = Object.keys(mySheetDataFinal)[0]; //['sheet1', 'sheet2', ....]
      mySheetDataFinal[sheet1Name] = mySheetDataFinal[sheet1Name]?.filter(
        (item) => !item.includes("")
      );
    }

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
  };

  const handleSecondDatacsv = () => {
    let mySheetDataFinal = alreadyFile
      ? alreadyFile
      : readDataFromExcel1(tempData);

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
  };

  const handleSecondPreviewExcel = () => {
    handleSecondData();
    setButtonClicked2(true);
    // handleThirdData();
    setIsPreview(true);
  };
  // const handleSecondNext = () => {
  //   handleSecondData();
  //   setCount(3);
  // };

  const handleSecondBack = () => {
    setCount(1);
  };
  const handleSecExcelNextOne = () => {
    handleSecondData();
    handleThirdDataCsv();
    setCount(3);
  };
  /*-------------------Delimiter selection screen start-------------------*/

  // Handle delimiter change (Select box)

  const handleThirdDataCsv = () => {
    if (!alreadyFile) {
      const secoundSheetData = XLSX.utils.json_to_sheet(firstUpdatedSheet);

      const csvData = XLSX.utils.sheet_to_csv(secoundSheetData, {
        header: false,
        FS: customDelimiter ? customDelimiter : delimited,
      });

      const CsvData = CSVToJSON(
        csvData,
        customDelimiter ? customDelimiter : delimited
      );

      const wb = XLSX.utils.book_new();
      const newWs = XLSX.utils.aoa_to_sheet(CsvData);

      XLSX.utils.book_append_sheet(wb, newWs);
      const rawExcel = XLSX.write(wb, { type: "base64" });
      setDelimiterCSV(rawExcel);

      const mySheetDataFinal = readDataFromExcel2(rawExcel);
      if (mySheetDataFinal) {
        setSheet(Object.keys(mySheetDataFinal)[0]);
      }
      setSheetData(mySheetDataFinal);
      setHeaderAlias(mySheetDataFinal);
    } else {
      const mySheetDataFinal = alreadyFile;
      if (mySheetDataFinal) {
        setSheet(Object.keys(mySheetDataFinal)[0]);
      }
      setSheetData(mySheetDataFinal);
      setHeaderAlias(mySheetDataFinal);
    }
  };

  // Handle delimiter change (Text box)
  const handleChangeCustomDelimiter = (event) => {
    // setDelimited("");
    setCustomDelimiter(event.target.value);
    if (event.target.value && delimiterCSV) {
      handleThirdData();
    }
  };

  const readDataFromExcel2 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      const thirdSheetData = (mySheetData[sheetName] = jsonData);
    }
    return mySheetData;
  };

  const handleChangeSheet = () => {};

  // Create default array for Last group check screen
  const setHeaderAlias = (data) => {
    let tempMandatory = groupCheckData;
    data[sheet][0].map((item, index) => {
      if (groupCheckData.some((data) => data.id === index)) {
        const temp = groupCheckData.findIndex((p) => p.id === index);
        groupCheckData[temp].alias = item;
      } else {
        tempMandatory.push({
          id: index,
          field: item,
          alias:
            state && finalParam.alias_name[item]
              ? finalParam.alias_name[item]
              : item,
          mandatory: finalParam.required_fields.includes(item),
          group_check: finalParam.group_check.includes(item),
        });
      }
    });
    setGroupCheckData(tempMandatory);
  };

  const CSVToJSON = (data, delimiter) => {
    delimiter = customDelimiter ? customDelimiter : delimited;
    return data
      .slice(data.indexOf("\n") + 1)
      .split("\n")
      .map((item) => {
        return delimiter ? item.split(delimiter) : item;
      });
  };

  const CSVToJSONExcel = (data, delimiter) => {
    delimiter = ",";
    return data
      .slice(data.indexOf("\n") + 1)
      .split("\n")
      .map((item) => {
        return delimiter ? item.split(delimiter) : item;
      });
  };
  const handleThirdData = () => {
    if (!alreadyFile) {
      const secoundSheetData = XLSX.utils.json_to_sheet(firstUpdatedSheet);
      let csvData = XLSX.utils.sheet_to_csv(secoundSheetData);
      csvData = XLSX.utils.sheet_to_csv(secoundSheetData, {
        header: true,
        FS: ",",
      });

      const CsvData = CSVToJSONExcel(csvData);

      const wb = XLSX.utils.book_new();
      const newWs = XLSX.utils.aoa_to_sheet(CsvData);

      XLSX.utils.book_append_sheet(wb, newWs);
      const rawExcel = XLSX.write(wb, { type: "base64" });
      setDelimiterCSV(rawExcel);

      const mySheetDataFinal = readDataFromExcel2(rawExcel);
      if (mySheetDataFinal) {
        setSheet(Object.keys(mySheetDataFinal)[0]);
      }
      setSheetData(mySheetDataFinal);
      setHeaderAlias(mySheetDataFinal);
    } else {
    }
    const mySheetDataFinal = alreadyFile;
    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);
    setHeaderAlias(mySheetDataFinal);
  };

  const handleThirdPreview = () => {
    handleThirdData();
    setIsPreview(true);
  };

  /*-------------------Group check screen start-------------------*/

  // Manage Last screen Group check section
  const manageMandatory = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].mandatory = e.target.checked;
  };

  const manageGroupCheck = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);

    groupCheckData[temp].group_check = e.target.checked;
  };

  const manageAlias = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].alias = e.target.value;
  };

  const handleFourthData = () => {
    let mySheetDataFinal = alreadyFile
      ? alreadyFile
      : readDataFromExcel1(tempData);

    if (isPreviewSettings.isBlankRow && finalParam.file_type === "excel") {
      let sheet1Name = Object.keys(mySheetDataFinal)[0];
      mySheetDataFinal[sheet1Name] = mySheetDataFinal[sheet1Name]?.filter(
        (item) => !item.includes("")
      );
    }

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
    let required = [];
    let optional = [];
    let group_check = [];
    let alias_name =
      state && finalParam.alias_name ? finalParam.alias_name : {};
    groupCheckData.map((item) => {
      Object.assign(alias_name, { [item["field"]]: item["alias"] });

      if (item["mandatory"]) {
        required.push(item["field"]);
      } else {
        optional.push(item["field"]);
      }

      if (item["group_check"]) {
        group_check.push(item["field"]);
      }
    });

    setFinalParam((prev) => ({
      ...prev,
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    }));

    handleGroupCheckPreview(required, group_check);

    return {
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    };
  };

  const handleGroupCheckPreview = (
    mandatory_item = ["Color", "Item Size"],
    group_check_item = ["Style Number", "SKU"]
  ) => {
    let excel_data = sheetData[sheet];
    // let need_to_remove_items = [];

    if (group_check_item?.length > 0) {
      group_check_item.map((item) => {
        let group_check_index = excel_data[0].indexOf(item);

        const matchingIndexes = findMatchingIndexes(
          excel_data,
          group_check_index
        );

        let arr = [];
        Object.values(matchingIndexes).map((val) => {
          const arrr = removeCustumGroupCheck(val, excel_data);
          arr = [...arr, ...arrr];
        });
        setGroupCheckPreviewData(arr);
      });
    } else if (mandatory_item?.length > 0) {
      mandatory_item.map((item) => {
        let mandatory_index = excel_data[0].indexOf(item);
        excel_data = removeMandatory(excel_data, mandatory_index);
      });

      setGroupCheckPreviewData(excel_data);
      // setSheetData(excel_data);
    } else {
      setGroupCheckPreviewData(excel_data);
    }
  };

  // strat
  function findMatchingIndexes(excelData, fieldIndex) {
    const matchingIndexes = {};

    excelData.forEach((row, rowIndex) => {
      const fieldValue = row[fieldIndex];

      if (!matchingIndexes[fieldValue]) {
        matchingIndexes[fieldValue] = [];
      }

      matchingIndexes[fieldValue].push(rowIndex);
    });

    return matchingIndexes;
  }
  // end

  const handleFourthBack = () => {
    setCount(3);
  };

  const handleFourthPreview = () => {
    setIsPreview(true);

    handleFourthData();

    setButtonClicked(true);
  };
  const [isButtonClicked, setButtonClicked] = useState(false);
  const [isButtonClicked2, setButtonClicked2] = useState(false);
  const [isButtonClicked3, setButtonClicked3] = useState(false);

  const handleConfirmModal = () => {
    handleFourthData();
    // alert("Finished");
    setCount(4);
  };

  const handlefinish = (
    id,
    groupCheckPreviewData,
    groupCheckData,
    finalParam
  ) => {
    const fdata = {
      groupCheckPreviewData,
      groupCheckData,
      finalParam,
    };
    // let fourthData = handleFourthData();
    const payload = new FormData();
    typeof finalParam.file != "string" &&
      payload.append("file", finalParam.file);
    dispatch(setLoading(true));
    dispatch(setConfigId(id));
    dispatch(setParserObject(finalParam));
    dispatch(parsedData(fdata));
    dispatch(saveDataParser(id, payload))
      .then((res) => {
        // const { file, ...other } = finalParam;
        dispatch(
          saveDataParser(id, {
            file_type: finalParam.file_type,
            job_status: finalParam.job_status,
            alias_name: finalParam.alias_name,
            group_check: finalParam.group_check,
            optional_fields: finalParam.optional_fields,
            required_fields: finalParam.required_fields,
            ui_attributes:
              finalParam.file_type === "csv"
                ? {
                    delimited,
                    delimited1,
                    cusRecord1,
                    cusRecord,
                    customDelimiterField,
                    cusSepertaion,
                    delimitedTwo,
                    cusSepertaion1,
                    encodeText: "utf-8",
                  }
                : isPreviewSettings,
            is_active: finalParam.is_active,
          })
        )
          .then((res2) => {
            props.setCurrent(props.current + 1);
            swal("Parser data saved", "", "success");
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
    // swal("Parser data saved", "", "success");
    // props.setCurrent(props.current + 1);
  };

  const handleFourthPreviewlast = () => {
    handleFourthData();

    setIsPreview(true);
  };

  const removeMandatory = (data, index) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== "") {
        temp.push(item);
      }
    });
    return temp;
  };

  const removeCustumGroupCheck = (data, excelData) => {
    let temp = [];
    data.map((item) => {
      let isNotEmpty = true;
      for (let i = 0; i < excelData[item]?.length; i++) {
        if (excelData[item][i] === "") {
          isNotEmpty = false;
          // temp.push(excelData[item]);
        }
      }
      if (isNotEmpty) {
        temp.push(excelData[item]);
      }
    });
    return temp;
  };

  const getNeedToRemoveItems = (data, mandatory_index, group_check_index) => {
    let temp = [];
    data.map((item) => {
      if (item[mandatory_index] === "") {
        temp.push({ key: group_check_index, value: item[group_check_index] });
      }
    });
    return temp;
  };

  const removeGroupCheck = (data, index, value) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== value) {
        temp.push(item);
      }
    });
    return temp;
  };
  const handleChangeDelimiter = (event) => {
    // setCustomDelimiter("");
    setDelimited(event.target.value);
    setcusSepertaion(false);
    if (event.target.value === "Delimited") {
      setCustomDelimiterField(true);
    } else {
      setCustomDelimiterField(false);
    }
    if (event.target.value && delimiterCSV) {
      handleThirdDataCsv();
    }
  };
  const handleSecondPreview = () => {
    handleSecondDatacsv();
    handleThirdDataCsv();
    setButtonClicked3(true);
    setIsPreview(true);
  };
  const components = [
    <>
      {/*Select File*/}
      <div className="container">
        <div className="row table_section shadow">
          <div className="col-lg-6 col-sm-6 col-md-12">
            <div
              className="form-group"
              style={{
                backgroundColor: "#fff",
                color: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5rem",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select file
                </InputLabel>
                <Select
                  label="Select file"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={finalParam.file_type}
                  style={{
                    width: 200,
                  }}
                  onChange={handleChange}
                >
                  <MenuItem style={{ textAlign: "left" }} value="csv">
                    Text
                  </MenuItem>
                  <MenuItem style={{ textAlign: "left" }} value="excel">
                    Excel
                  </MenuItem>
                </Select>
              </FormControl>
              <div className="" style={{ color: "#e06968", width: "16rem" }}>
                <p>Your config Id {configId}</p>
              </div>
            </div>
          </div>

          <div className="upload-btn-wrapper">
            <label className="btn d-flex justify-content-center align-items-center">
              <span>
                <i className="fa fa-upload" />
                <br />
                {fileName ? (
                  <h4>FileName: {fileName}</h4>
                ) : (
                  <h4>Browse files</h4>
                )}
              </span>
              <input
                type="file"
                name="myfile"
                accept={
                  finalParam.file_type === "csv"
                    ? ".csv"
                    : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                }
                style={{ display: "none" }}
                multiple={false}
                onChange={handleFile}
              />
            </label>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <p className="float-left">
              {finalParam.file_type === "csv" ? (
                <>Choose only csv file</>
              ) : (
                <>Choose only Excel file</>
              )}
            </p>
            <Progress percent={progress} size="small" />
            {showMessage && message.success("file uploaded successfully")}

            <button
              className="float-right btn_red btn_next"
              onClick={() => handleFirstNext()}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    </>,
    <>
      {/*File Import*/}
      {finalParam.file_type === "csv" && (
        <div className="container" style={{ width: "66rem" }}>
          <div className="row table_section shadow">
            <div className="form_box mb-4">
              <h6>Specify how data is detected from the File Type</h6>
              <div className="divider" />
              <div className={"col-md-12"}>
                <div className={"row"}>
                  <div className="col-lg-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <label>Encoding Support</label>
                        <Select
                          id="encoding"
                          value={"utf8"}
                          style={{ width: "300px" }}
                        >
                          <MenuItem value={"utf8"}>UTF8</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <label>Format</label>
                        <Select
                          placeholder="Select"
                          value={delimited}
                          onChange={handleChangeDelimiter}
                          style={{ width: "300px", textAlign: "left" }}
                        >
                          <MenuItem style={{ textAlign: "left" }} value=",">
                            Comma Separated Values
                          </MenuItem>
                          <MenuItem style={{ textAlign: "left" }} value=" ">
                            Tab- Separated values
                          </MenuItem>
                          <MenuItem
                            style={{ textAlign: "left" }}
                            value={"Delimited"}
                          >
                            Delimited Fields
                          </MenuItem>
                          <MenuItem style={{ textAlign: "left" }} value="">
                            Fixed Width Fields
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {delimited === "Delimited" && (
                    <div
                      className={`${
                        cusSepertaion1 || cusSepertaion
                          ? "col-lg-6"
                          : "col-lg-12"
                      } col-sm-6 col-md-6`}
                    >
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Field Separation</label>

                          <Select
                            id="delimited"
                            value={delimitedTwo}
                            placeholder="Select"
                            onChange={handleFieldSeperation}
                            style={{ width: "300px", textAlign: "left" }}
                          >
                            <MenuItem style={{ textAlign: "left" }} value="">
                              Mixed Quote Comma
                            </MenuItem>
                            <MenuItem style={{ textAlign: "left" }} value="''">
                              Quote and Comma
                            </MenuItem>
                            <MenuItem style={{ textAlign: "left" }} value=",">
                              Comma
                            </MenuItem>
                            <MenuItem style={{ textAlign: "left" }} value="Tab">
                              Tab
                            </MenuItem>
                            <MenuItem
                              style={{ textAlign: "left" }}
                              value="custom1"
                            >
                              Custom
                            </MenuItem>
                            <MenuItem
                              style={{ textAlign: "left" }}
                              value="Custom Expression1"
                            >
                              Custom Expression
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {cusSepertaion1 && (
                    <div className="col-lg-4 col-sm-6 col-md-6">
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Custom Expression</label>

                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Custom Expression"
                            variant="outlined"
                            onChange={handleChangeCustomDelimiter}
                          />
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {cusSepertaion && (
                    <div className="col-lg-4 col-sm-6 col-md-6">
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Delimiter</label>

                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Delimiter"
                            variant="outlined"
                            onChange={handleChangeCustomDelimiter}
                          />
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {customDelimiterField && (
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Record Separation</label>

                          <Select
                            id="delimited"
                            value={delimited1}
                            onChange={handleRecordSeperation}
                            style={{ width: "300px", textAlign: "left" }}
                          >
                            <MenuItem
                              style={{ textAlign: "left" }}
                              value="Line Break"
                            >
                              Line Break
                            </MenuItem>
                            <MenuItem
                              style={{ textAlign: "left" }}
                              value="custom2"
                            >
                              Custom
                            </MenuItem>
                            <MenuItem
                              style={{ textAlign: "left" }}
                              value="custom Expression2"
                            >
                              Custom Expression
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {cusRecord && (
                    <div className="col-lg-4 col-sm-6 col-md-6 ">
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Delimiter</label>

                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Delimiter"
                            variant="outlined"
                            onChange={handleChangeCustomDelimiter}
                          />
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {cusRecord1 && (
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      <div className="form-group">
                        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                          <label>Custom Expression</label>

                          <TextField
                            id="outlined-basic"
                            size="small"
                            placeholder="Custom Expression"
                            variant="outlined"
                            onChange={handleChangeCustomDelimiter}
                          />
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <div className="form-group col-md-12 text-right btn_group_section">
                    <button
                      className="btn_back"
                      onClick={() => handleThirdBack()}
                    >
                      Back
                    </button>
                    <button
                      className="btn_preview"
                      onClick={() => handleSecondPreview()}
                    >
                      Preview
                    </button>
                    {isButtonClicked3 && (
                      <button
                        className="btn_red"
                        onClick={() => handleSecExcelNextOne()}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
                {isPreview && (
                  <SheetTable
                    sheetData={sheetData}
                    sheet={sheet}
                    isPreviewSettings={isPreviewSettings}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {finalParam.file_type === "excel" && (
        <div className="container">
          <div className="row table_section shadow">
            <div className="form_box mb-4">
              <h6>Specify how data is detected from the Excel Spreadsheet</h6>
              <div className="divider" />
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Use automatic field type detection (if unchecked all fields will be read as text)"
                    onChange={handleAutoFieldTypeDetect}
                    checked={isPreviewSettings.isAutoDetectPreview}
                  />

                  <div>
                    <span>Preview</span>
                    <input
                      type="number"
                      value={isPreviewSettings?.previewRows}
                      style={{ margin: "0px 10px" }}
                      onChange={handlePreviewRows}
                    />
                    records for automatic filed type detection
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="First row has field names"
                    onChange={handleFirstRowHeader}
                    checked={
                      isPreviewSettings.isFirstRowHeader &&
                      isPreviewSettings.isFirstRowHeader
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Ignore empty records"
                    onChange={handleBlankRows}
                    checked={
                      isPreviewSettings.isBlankRow &&
                      isPreviewSettings.isBlankRow
                    }
                  />
                </FormGroup>
                <FormControl sx={{ m: 1 }} size="small">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <span>No. of Sheets</span>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // value={val}
                      label="Age"
                      onChange={handleChangeSheet}
                      style={{ width: "12rem" }}
                    >
                      {sheetNames &&
                        sheetNames?.map((val, idx) => (
                          <MenuItem value={val}>
                            <em>{val}</em>
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </FormControl>
                <div
                  className=""
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Specify range"
                      onChange={handleSpecifyRange}
                      checked={isPreviewSettings.isSpecifyRange}
                    />
                  </FormGroup>

                  <input
                    type="text"
                    style={{ margin: "0px 10px" }}
                    value={isPreviewSettings.specifyRange}
                    onChange={handleSpecifyRangeInput}
                    placeholder="A3:C29"
                  />
                  <span style={{ color: "grey" }}>(Example A3:C29)</span>
                </div>
                <div className="form-group col-md-12 text-right btn_group_section">
                  <button
                    className="btn_back"
                    onClick={() => handleSecondBack()}
                  >
                    Back
                  </button>
                  <button
                    className="btn_preview"
                    onClick={() => handleSecondPreviewExcel()}
                  >
                    Preview
                  </button>
                  {isButtonClicked2 && (
                    <button
                      className="btn_red"
                      onClick={() => handleSecExcelNext()}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {isPreview && (
              <SheetTable
                sheetData={sheetData}
                sheet={sheet}
                isPreviewSettings={isPreviewSettings}
              />
            )}
          </div>
        </div>
      )}
    </>,
    <>
      {/*Field Names*/}
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">Field Names</h4>
          <MandatoryGroupSheetTable
            onclickMandatory={(e, i) => manageMandatory(e, i)}
            onClickGroupCheck={(e, i) => manageGroupCheck(e, i)}
            onTypeAlias={(e, i) => manageAlias(e, i)}
            groupCheckData={groupCheckData}
            sheetData={sheetData}
            sheet={sheet}
            isPreviewSettings={isPreviewSettings}
          />
          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={() => handleThirdBackexcel()}>
              Back
            </button>
            <button
              className="btn_preview"
              onClick={() => handleFourthPreview()}
            >
              Preview
            </button>
            {isButtonClicked && (
              <button className="btn_red" onClick={() => handleConfirmModal()}>
                Next
              </button>
            )}
          </div>
          {isPreview && (
            <GroupCheckTable
              groupCheckPreviewData={sheetData} //changed to sheetdata
              groupCheckData={groupCheckData}
              isPreviewSettings={isPreviewSettings}
            />
          )}
        </div>
      </div>
    </>,
    <>
      {/*Rejection Rules*/}
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">Rejection Rules</h4>
          <MandatoryGroupSheetTableValidation
            onclickMandatory={(e, i) => manageMandatory(e, i)}
            onClickGroupCheck={(e, i) => manageGroupCheck(e, i)}
            onTypeAlias={(e, i) => manageAlias(e, i)}
            groupCheckData={groupCheckData}
            sheetData={sheetData}
            sheet={sheet}
            isPreviewSettings={isPreviewSettings}
          />
          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={() => handleFourthBack()}>
              Back
            </button>
            <button
              className="btn_preview"
              onClick={() => handleFourthPreviewlast()}
            >
              Preview
            </button>
            <button
              className="btn_red"
              onClick={() =>
                handlefinish(
                  configId,
                  groupCheckPreviewData,
                  groupCheckData,
                  finalParam
                  // dispatch(setExcelObject(groupCheckPreviewData))
                )
              }
            >
              Finish
            </button>
          </div>
          {isPreview && (
            <GroupCheckTable
              //changed to sheetdata
              groupCheckPreviewData={groupCheckPreviewData}
              lastTable={true}
              groupCheckData={groupCheckData}
            />
          )}
        </div>
      </div>
    </>,
  ];

  useEffect(() => {
    if (state && state?.active_version) {
      setFinalParam({
        ...finalParam,
        file: state.active_version.file,
        file_type: state.active_version.file_type,
        is_active: state.active_version.is_active,
        alias_name: state.active_version.alias_name,
        group_check: state.active_version.group_check,
        required_fields: state.active_version.required_fields,
      });

      // for file name
      setfilename(state.active_version.file);
      setFileName(state.active_version.file);

      // file urls
      const fileUrl = `https://20.121.126.218${state.active_version.file}`;
      fetch(fileUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((data) => {
          const lines = data.split("\n");
          const header = lines[0].split(",");
          const dataArray = lines.slice(1).map((line) => {
            const values = line.split(",");
            const obj = {};
            header.forEach((key, index) => {
              obj[key] = values[index];
            });
            return obj;
          });
          const arr = dataArray.map((item) => {
            return Object.values(item);
          });

          setAlreadyFile({ Sheet1: [Object.values(header), ...arr] });
        })
        .catch((error) => {
          console.error("There was a problem fetching the file:", error);
        });

      // for ui attributes
      if (state.active_version.file_type === "csv") {
        setcusRecord(state.active_version.cusRecord1);
        setDelimited1(state.active_version.delimited1);
        setcusRecord1(state.active_version.cusRecord1);
        setcusSepertaion(state.active_version.cusSepertaion);
        setcusSepertaion1(state.active_version.cusSepertaion1);
        setDelimited(state.active_version.ui_attributes.delimited);
        setDelimitedTwo(state.active_version.ui_attributes.delimitedTwo);
        setCustomDelimiterField(state.active_version.customDelimiterField);
      } else {
        setIsPreviewSettings(state.active_version.ui_attributes);
      }
      // for file progress bar
      state.active_version.file_type &&
        state.active_version.file &&
        setProgress(100);
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <Link to="/dashboard"></Link>
            </div>
            <div className="container">
              <Stepper
                className={"w-100 p-0 pb-3 mt-2"}
                steps={[
                  { label: "Select File" },
                  { label: "File Import" },
                  { label: "Filed Names" },
                  { label: "Rejection Rules" },
                ]}
                activeStep={count - 1}
                stepClassName={"stepper__step"}
              />
            </div>
          </div>
        </div>
      </div>
      {components[count - 1]}
    </div>
  );
}
