import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  setLoading,
  // saveDataParser,
  getAllJob,
  getJobLog,
  setExcelObject,
  setParserObject,
} from "../../redux/actions/other";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { MandatoryGroupSheetTableValidation } from "../DataParser/MandatoryGroupSheetTableValidation";
import { GroupCheckTable } from "../DataParser/GroupCheckTable";
import { GroupCheckTableMandory } from "../DataParser/GroupCheckTableMandory";
import { SheetTable } from "../DataParser/SheetTable";

XLSX = require("xlsx");
const acceptableFileName = ["xlsx", "xls", "csv"];

const DataConfigThird = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { setActiveTab, customRule, excelValue } = props;
  // console.log(props);
  const [fileName, setFileName] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const sheetDataNew = excelValue?.sheetData;
  const [sheet, setSheet] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  console.log("groupCheckData", sheetData, sheet);

  const [jobs, setJobs] = useState([]);
  const [jobLogs, setJobLogs] = useState([]);
  const [isPreviewSettings, setIsPreviewSettings] = useState({
    isAutoDetectPreview: false,
    previewRows: 5,
    isBlankRow: false,
    isSpecifyRange: false,
    isFirstRowHeader: false,
    specifyRange: "",
  });
  const [count, setCount] = useState(1);
  const [firstUpdatedSheet, setFirstUpdatedSheet] = useState();
  const [delimiterCSV, setDelimiterCSV] = useState();
  const [fillSelected, setFillSelected] = useState(false);
  const sheetNumber = excelValue?.sheet;
  // 3rd screen
  const [delimited, setDelimited] = useState(",");
  const [customDelimiterField, setCustomDelimiterField] = useState(false);
  const [customDelimiter, setCustomDelimiter] = useState("");
  const [selectedJob, setSelectedJob] = useState({});
  const [groupCheckData, setGroupCheckData] = useState([]);
  console.log("groupCheckData", props.excelValue.sheetData);

  const [groupCheckPreviewData, setGroupCheckPreviewData] = useState([]);
  const [finalParam, setFinalParam] = useState({
    optional_fields: [],
    required_fields: [],
    alias_name: {},
    group_check: [],
    rules: {},
    corporate: null,
    file_prefix: null,
    is_active: true,
    version: null,
    job: null,
  });

  useEffect(() => {
    allJobList();
  }, []);

  useEffect(() => {
    let finalArray = sheetDataNew[sheetNumber];
    console.log("excelValue", customRule["configs"]);
    customRule["configs"]?.map((item) => {
      const field = item["field"]["value"];
      const rules = item["rules"];
      console.log("sheetData", sheetDataNew, sheetNumber);
      const fieldIndex = sheetDataNew[sheetNumber][0].indexOf(field);

      rules?.map((item) => {
        finalArray = handlePreview(finalArray, item["value"], fieldIndex);
      });
    });

    setSheet(finalArray);
    setHeaderAlias(finalArray);
    console.log("finalArray", finalArray);
  }, [customRule]);

  const handlePreview = (data, ruleName, fieldIndex) => {
    if (ruleName === "uppercase" || ruleName === "lowercase") {
      return handleUpperLowerCase(data, fieldIndex, ruleName);
    } else if (ruleName === "titlecase") {
      return handleTitleCase(data, fieldIndex);
    } else if (
      ruleName === "trim_space_on_left" ||
      ruleName === "trim_space_on_right"
    ) {
      return handleTrimLeftRightCase(data, fieldIndex, ruleName);
    } else if (
      ruleName === "discard_left_characters" ||
      ruleName === "discard_right_characters"
    ) {
      return handleDiscardLeftRightChar(data, fieldIndex, ruleName);
    }
  };

  function removeFirstWord(str) {
    const indexOfSpace = str.indexOf(" ");
    return str.substring(indexOfSpace + 1);
  }

  // remove last word from string
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");
    if (lastIndexOfSpace === -1) {
      return str;
    }
    return str.substring(0, lastIndexOfSpace);
  }

  const handleDiscardLeftRightChar = (data, fieldIndex, type) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          if (type === "discard_left_characters") {
            temp1.push(removeFirstWord(row.toString()));
          } else {
            temp1.push(removeLastWord(row.toString()));
          }
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  const handleUpperLowerCase = (data, fieldIndex, type) => {
    console.log("data , fieldIndex, type==> ", data, fieldIndex, type);

    let temp = [];
    data?.map((item, itemIndex) => {
      let temp1 = [];
      item.map((row, index) => {
        if (fieldIndex === 1) {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        } else if (fieldIndex === 2) {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        } else {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  const titleCase = (str) => {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  };

  const handleTitleCase = (data, fieldIndex) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          temp1.push(titleCase(row.toString()));
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });

    return temp;
  };

  const handleTrimLeftRightCase = (data, fieldIndex, type) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          if (type === "trim_space_on_left") {
            temp1.push(row.toString().trimStart());
          } else {
            temp1.push(row.toString().trimEnd());
          }
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  // Get all job list
  const allJobList = () => {
    dispatch(setLoading(true));
    dispatch(getAllJob())
      .then((response) => {
        setJobs(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // Get job status (Log)
  const getJobStatus = (id) => {
    dispatch(setLoading(true));
    dispatch(getJobLog(id))
      .then((response) => {
        console.log("response", response);
        setJobLogs(response["data"]);
        setCount(5);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  /*-------------------File selection screen start-------------------*/

  // Handle job selection
  const handleJobChange = (job_id) => {
    if (job_id) {
      let data = jobs.find((job) => Number(job.id) === Number(job_id));
      setSelectedJob(data);
      setFillSelected(true);
      setFinalParam((prev) => ({
        ...prev,
        corporate: data["corporate"],
        file_prefix: data["file_mask"],
        is_active: data["status"],
        version: data["active_version_no"],
        job: data["id"],
      }));
    } else {
      setSelectedJob({});
      setFillSelected(false);
      setFinalParam((prev) => ({
        ...prev,
        corporate: null,
        file_prefix: null,
        is_active: true,
        version: null,
        job: null,
      }));
    }
  };
  const modilfyjobs = (jobdata) => {
    const mydataJob = jobdata.map((item_job) => {
      return {
        name: item_job.job_name,
        value: item_job.id,
      };
    });
    return mydataJob;
  };
  // file extension
  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  // Handle file after select
  const handleFile = async (e) => {
    const myFile = e.target.files[0];
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      alert("Invalid file type");
      return;
    }
    setFileName(myFile.name);
    // Read the xlxs meta data
    const data = await myFile.arrayBuffer();
    setTempData(data);
  };

  // Click on Next button after file selection
  const handleFirstNext = () => {
    if (!fillSelected) {
      alert("Please select Job");
    } else if (fileName == null) {
      alert("Please select file");
    } else {
      setCount(2);
    }
  };

  /*-------------------Type detection selection screen start-------------------*/

  // automatic field type detection (Checkbox)
  const handleAutoFieldTypeDetect = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isAutoDetectPreview: !isPreviewSettings?.isAutoDetectPreview,
    }));
  };

  // Handle Previews (Rows)
  const handlePreviewRows = (e) => {
    if (e.target.value <= 0) {
      alert("You cant set preview rows less than 0");
    } else {
      setIsPreviewSettings((prevState) => ({
        ...prevState,
        previewRows: e.target.value,
      }));
    }
  };
  const handleThirdData = () => {
    let mySheetDataFinal = readDataFromExcel1(tempData);
    console.log(mySheetDataFinal);
    if (isPreviewSettings.isBlankRow) {
      //asuming excel sheet have atleastt 1 sheet
      let sheet1Name = Object.keys(mySheetDataFinal)[0]; //['sheet1', 'sheet2', ....]
      mySheetDataFinal[sheet1Name] = mySheetDataFinal[sheet1Name]?.filter(
        (item) => !item.includes("")
      );
    }

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
  };
  // Handle Blank rows (Checkbox)
  const handleBlankRows = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isBlankRow: !isPreviewSettings.isBlankRow,
    }));
  };

  // Handle First row header (Checkbox)
  const handleFirstRowHeader = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isFirstRowHeader: !isPreviewSettings.isFirstRowHeader,
    }));
  };

  // Handle Specify Range (Checkbox)
  const handleSpecifyRange = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isSpecifyRange: !isPreviewSettings.isSpecifyRange,
    }));
  };

  // Handle Specify Range input (Range text box)
  const handleSpecifyRangeInput = (e) => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      specifyRange: e.target.value,
    }));
  };

  const readDataFromExcel1 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};
    let dummyHeading = [];

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, {
        blankrows: !isPreviewSettings?.isBlankRow,
        header: 1,
        defval: "",
        skipHeader: true,
        range: isPreviewSettings.isSpecifyRange
          ? isPreviewSettings.specifyRange !== ""
            ? isPreviewSettings.specifyRange
            : null
          : null,
      });

      for (let i = 0; i < jsonData[0]?.length; i++) {
        dummyHeading.push("Field" + (i + 1));
      }

      if (isPreviewSettings.isFirstRowHeader) {
        jsonData = jsonData.slice(0);
      } else {
        jsonData = [dummyHeading].concat(jsonData);
      }

      const firstSheetData = (mySheetData[sheetName] = jsonData);
      console.log("firstSheetData", jsonData, dummyHeading);
      setFirstUpdatedSheet(firstSheetData);
    }
    return mySheetData;
  };

  const handleSecondPreview = () => {
    handleSecondData();
    setIsPreview(true);
  };

  const handleSecondNext = () => {
    handleSecondData();
    setCount(3);
  };

  const handleSecondBack = () => {
    setCount(1);
  };

  /*-------------------Delimiter selection screen start-------------------*/

  // Handle delimiter change (Select box)
  const handleChangeDelimiter = (event) => {
    setCustomDelimiter("");
    setDelimited(event.target.value);
    if (event.target.value === "custom") {
      setCustomDelimiterField(true);
    } else {
      setCustomDelimiterField(false);
    }
    if (event.target.value && delimiterCSV) {
      // handleThirdData();
    }
  };

  // Handle delimiter change (Text box)
  const handleChangeCustomDelimiter = (event) => {
    setDelimited("");
    setCustomDelimiter(event.target.value);
    if (event.target.value && delimiterCSV) {
      // handleThirdData();
    }
  };

  const readDataFromExcel2 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      const thirdSheetData = (mySheetData[sheetName] = jsonData);
    }
    return mySheetData;
  };

  // Create default array for Last group check screen
  const setHeaderAlias = (data) => {
    let tempMandatory = groupCheckData;
    if (data.length > 0 && data[0].length > 0) {
      data[0].map((item, index) => {
        if (groupCheckData.some((data) => data.id === index)) {
          const temp = groupCheckData.findIndex((p) => p.id === index);
          groupCheckData[temp].field = item;
        } else {
          tempMandatory.push({
            id: index,
            field: item,
            alias: item,
            mandatory: false,
            group_check: false,
          });
        }
      });
    }
    setGroupCheckData(tempMandatory);
  };

  /*useEffect(() => {
    setHeaderAlias();
  }, [props.excelValue.sheetData]);*/

  const CSVToJSON = (data, delimiter) => {
    delimiter = customDelimiter ? customDelimiter : delimited;
    return data
      .slice(data.indexOf("\n") + 1)
      .split("\n")
      .map((item) => {
        return item.split(delimiter);
      });
  };

  // const handleThirdData = () => {
  //   const secoundSheetData = XLSX.utils.json_to_sheet(firstUpdatedSheet);
  //   console.log(secoundSheetData);
  //   const csvData = XLSX.utils.sheet_to_csv(secoundSheetData, {
  //     header: false,
  //     FS: customDelimiter ? customDelimiter : delimited,
  //   });

  //   const CsvData = CSVToJSON(
  //     csvData,
  //     customDelimiter ? customDelimiter : delimited
  //   );

  //   const wb = XLSX.utils.book_new();
  //   const newWs = XLSX.utils.aoa_to_sheet(CsvData);

  //   XLSX.utils.book_append_sheet(wb, newWs);
  //   const rawExcel = XLSX.write(wb, { type: "base64" });
  //   setDelimiterCSV(rawExcel);

  //   const mySheetDataFinal = readDataFromExcel2(rawExcel);
  //   if (mySheetDataFinal) {
  //     setSheet(Object?.keys(mySheetDataFinal)[0]);
  //   }
  //   setSheetData(mySheetDataFinal);
  //   setHeaderAlias(mySheetDataFinal);
  // };

  const handleThirdPreview = () => {
    handleFourthData();
    setIsPreview(true);
  };

  // const handleThirdNext = () => {
  //   handleThirdData();
  //   setCount(4);
  // };

  const handleThirdBack = () => {
    setCount(2);
  };

  /*-------------------Group check screen start-------------------*/

  // Manage Last screen Group check section
  const manageMandatory = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].mandatory = e.target.checked;
  };

  const manageGroupCheck = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].group_check = e.target.checked;
  };

  // const manageAlias = (e, i) => {
  //   const temp = groupCheckData.findIndex((p) => p.id === i);
  //   groupCheckData[temp].alias = e.target.value;
  // };
  const handleSecondData = () => {
    let mySheetDataFinal = readDataFromExcel1(tempData);
    if (isPreviewSettings.isBlankRow) {
      //asuming excel sheet have atleastt 1 sheet
      let sheet1Name = Object.keys(mySheetDataFinal)[0]; //['sheet1', 'sheet2', ....]
      mySheetDataFinal[sheet1Name] = mySheetDataFinal[sheet1Name]?.filter(
        (item) => !item.includes("")
      );
    }

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
  };
  const handleFourthData = () => {
    let required = [];
    let optional = [];
    let group_check = [];
    let alias_name = {};
    groupCheckData.map((item) => {
      Object.assign(alias_name, { [item["field"]]: item["alias"] });

      if (item["mandatory"]) {
        required.push(item["field"]);
      } else {
        optional.push(item["field"]);
      }

      if (item["group_check"]) {
        group_check.push(item["field"]);
      }
    });

    setFinalParam((prev) => ({
      ...prev,
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    }));

    handleGroupCheckPreview(required, group_check);

    return {
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    };
  };

  const handleGroupCheckPreview = (
    mandatory_item = ["Color", "Item Size"],
    group_check_item = ["Style Number", "SKU"]
  ) => {
    let excel_data = sheet;
    let need_to_remove_items = [];
    console.log("data", excel_data);

    if (group_check_item.length > 0) {
      group_check_item.map((item) => {
        let group_check_index = excel_data[0].indexOf(item);

        mandatory_item.map((item) => {
          let mandatory_index = excel_data[0].indexOf(item);
          let temp = getNeedToRemoveItems(
            excel_data,
            mandatory_index,
            group_check_index
          );
          need_to_remove_items = [...need_to_remove_items, ...temp];
        });
      });

      console.log("need_to_remove_items", need_to_remove_items);

      need_to_remove_items.map((item) => {
        excel_data = removeGroupCheck(excel_data, item["key"], item["value"]);
      });

      setGroupCheckPreviewData(excel_data);
      console.log("group_check_item", excel_data);
    } else if (mandatory_item.length > 0) {
      mandatory_item.map((item) => {
        let mandatory_index = excel_data[0].indexOf(item);
        excel_data = removeMandatory(excel_data, mandatory_index);
      });

      setGroupCheckPreviewData(excel_data);
      console.log("mandatory_item", excel_data);
    } else {
      setGroupCheckPreviewData(excel_data);
    }
  };

  const handleFourthPreview = () => {
    handleFourthData();
    setIsPreview(true);
  };
  const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
  ];
  const handleConfirmModal = () => {
    let fourthData = handleFourthData();
    let param = finalParam;
    param["optional_fields"] = fourthData["optional_fields"];
    param["required_fields"] = fourthData["required_fields"];
    param["alias_name"] = fourthData["alias_name"];
    param["group_check"] = fourthData["group_check"];

    // console.log('FinalParam===>>>', param);

    dispatch(setLoading(true));

    dispatch(setParserObject(param));

    swal("Parser data saved", "", "success");

    history("/config/data-config");

    /*dispatch(saveDataParser(finalParam))
            .then(() => {
                getJobStatus(selectedJob['id']);
            })
            .catch(() => {
                dispatch(setLoading(false));
            });*/
  };

  const removeMandatory = (data, index) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== "") {
        temp.push(item);
      }
    });
    return temp;
  };

  const getNeedToRemoveItems = (data, mandatory_index, group_check_index) => {
    let temp = [];
    data.map((item) => {
      if (item[mandatory_index] === "") {
        temp.push({ key: group_check_index, value: item[group_check_index] });
      }
    });
    return temp;
  };

  const removeGroupCheck = (data, index, value) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== value) {
        temp.push(item);
      }
    });
    return temp;
  };

  return (
    <div>
      <Formik>
        {({ errors, values, touched, setValues, setFieldValue }) => (
          <Form>
            <div className="form_box mt-4">
              <div className="textarea2">
                <>
                  {/*Group check*/}
                  <div className="container">
                    <div className="row table_section shadow">
                      <h4 className="mt-3">
                        Mandatory Group Check
                        <span
                          className="float-end"
                          style={{ fontSize: "20px", color: "#e06968" }}
                        ></span>
                      </h4>
                      <MandatoryGroupSheetTableValidation
                        onclickMandatory={(e, i) => manageMandatory(e, i)}
                        onClickGroupCheck={(e, i) => manageGroupCheck(e, i)}
                        // onTypeAlias={(e, i) => manageAlias(e, i)}
                        groupCheckData={groupCheckData}
                        sheetData={sheet}
                        sheet={sheet}
                        isPreviewSettings={isPreviewSettings}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="form-group col-md-12 pt-4 text-right btn_group_section">
        <button
          type="button"
          className="btn_back"
          onClick={() => setActiveTab("two")}
        >
          {" "}
          Back
        </button>
        <button
          type="button"
          className="btn_back"
          onClick={() => handleFourthPreview()}
          style={{ whiteSpace: "nowrap", width: "120px", height: "40px" }}
        >
          Preview
        </button>
        <button
          type="button"
          className="btn_red"
          onClick={() => setActiveTab("four")}
        >
          {" "}
          Next
        </button>
      </div>
      {isPreview && (
        <GroupCheckTableMandory
          groupCheckPreviewData={groupCheckPreviewData}
          groupCheckData={groupCheckData}
        />
      )}
    </div>
  );
};

export default DataConfigThird;
