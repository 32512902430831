import React, { useState, useEffect, useRef } from "react";
import Pagination from "../../../components/Pagination";
import { useDispatch } from "react-redux";
import {
  deleteAdmin,
  getAdminList,
  setLoading,
} from "../../../redux/actions/other";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import AdminCreate from "./create";

const AdminList = () => {
  const dispatch = useDispatch();
  const [admins, setAdmins] = useState({
    links: {},
    total: 0,
    page: 1,
    page_size: 10,
    results: [],
  });
  const [page, setPage] = useState(1);
  const [editId, setEditId] = useState(null);
  const [show, setShow] = useState("hide");
  const [isRefresh, setIsRefresh] = useState(false);
  const inputRef = useRef(null);
  const [filter, setFilter] = useState("");
  const [sorting, setSorting] = useState({ key: "", ascending: true });

  const handleClose = () => {
    setShow("hide");
    setEditId(null);
  };
  const handleShow = () => {
    setShow("show");
    setEditId(null);
  };
  const handleRefresh = () => {
    getList(page);
    // setIsRefresh(true);
  };

  useEffect(() => {
    getList(page);
  }, [isRefresh, page, filter, sorting]);

  const remove = (id) => {
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(deleteAdmin(id))
          .then((response) => {
            dispatch(setLoading(false));
            getList(1);
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const edit = async (id) => {
    setEditId(id);
    setShow("show");
  };

  const getList = (page) => {
    dispatch(setLoading(true));
    dispatch(getAdminList(page, filter, sorting))
      .then((response) => {
        setAdmins(response);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const search = () => {
    setFilter(inputRef.current.value ? inputRef.current.value : "");
  };

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }

  return (
    <>
      <div className="container">
        <div className="col-md-12 table_section">
          <div className="row table_box">
            <div className={"col-md-3"}>
              <h4>List of Admins</h4>
            </div>
            <div className="col-md-2">
              <input
                ref={inputRef}
                type="text"
                className={"form-control"}
                placeholder="Search..."
              />
            </div>
            <div className={"col-md-2"}>
              <div className="col">
                <button
                  className="btn_search"
                  id="add-edit-corporate"
                  onClick={search}
                >
                  Search
                </button>
              </div>
            </div>
            {/* <div className="col-md-3"></div> */}
            <div className={"col-md-3"}>
              <button
                className="btn-sm btn-add-field float-end"
                id="add-edit-corporate"
                onClick={handleShow}
              >
                <i className="fa fa-plus" />
                Add Admin
              </button>
            </div>
            <div className={"col-md-2 -mt-2"}>
              <button
                className="btn-sm btn-add-field float-end"
                id="add-edit-corporate"
              >
                <i className="fa fa-cog" />
                Export
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr. No.</th>
                  <th
                    scope="col"
                    onClick={() => applySorting("user_id", !sorting.ascending)}
                  >
                    User ID&nbsp;
                    {sorting.key === "user_id" && (
                      <i
                        className={`fa fa-sort-${
                          sorting.ascending ? "up" : "down"
                        }`}
                      />
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={() => applySorting("name", !sorting.ascending)}
                  >
                    Name&nbsp;
                    {sorting.key === "name" && (
                      <i
                        className={`fa fa-sort-${
                          sorting.ascending ? "up" : "down"
                        }`}
                      />
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={() => applySorting("email", !sorting.ascending)}
                  >
                    Email&nbsp;
                    {sorting.key === "email" && (
                      <i
                        className={`fa fa-sort-${
                          sorting.ascending ? "up" : "down"
                        }`}
                      />
                    )}
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {admins.results.length > 0 ? (
                <tbody>
                  {admins.results.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="sr_no">{i + 1}</td>
                        <td className="user_id">{item.user_id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item["status"] ? "Enabled" : "Disabled"}</td>
                        <td className="action_btns">
                          <Link onClick={() => edit(item.id, item)} to={""}>
                            <i className="fa fa-pencil btn_edit" />
                          </Link>
                          <Link onClick={() => remove(item.id)} to={""}>
                            <i className="fa fa-trash-o btn_delete" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6}>No Records found</td>
                  </tr>
                </tbody>
              )}
            </table>
            <Pagination data={admins} handlePageClick={handlePagination} />
          </div>
        </div>
      </div>
      {show === "show" && (
        <AdminCreate
          open={show}
          editId={editId}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
      )}
    </>
  );
};

export default AdminList;
