import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Space, Select, Button } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { deleteJob, getJobList, setLoading } from "../../redux/actions/other";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Pagination from "../../components/Pagination";
import moment from "moment";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import Search from "antd/es/input/Search";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [toggle, setToggle] = useState(false);
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState({
    links: {},
    total: 0,
    page: 1,
    page_size: 10,
    results: [],
  });
  console.log(jobs, "alljobs");

  const [page, setPage] = useState(1);
  const [editId, setEditId] = useState(null);
  const [show, setShow] = useState("hide");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isRefresh, setIsRefresh] = useState(false);
  const inputRef = useRef(null);
  const [filter, setFilter] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [sorting, setSorting] = useState({ key: "", ascending: true });

  const handleClose = () => {
    setShow("hide");
    setEditId(null);
  };
  const handleShow = () => {
    setShow("show");
    setEditId(null);
  };
  const handleRefresh = () => {
    getList(page);
  };

  useEffect(() => {
    getList(page);
  }, [isRefresh, page, filter, filterStartDate, filterEndDate, sorting]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const edit = async (id) => {
    setEditId(id);
    setShow("show");
  };

  const getList = (page) => {
    dispatch(setLoading(true));
    dispatch(getJobList(page, filter, filterStartDate, filterEndDate, sorting))
      .then((response) => {
        setJobs(response);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const remove = (id) => {
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(deleteJob(id))
          .then((response) => {
            dispatch(setLoading(false));
            getList(1);
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const search = () => {
    setFilter(inputRef.current.value);
    setFilterStartDate(
      startDate ? moment(startDate).format("YYYY-MM-DD") : null
    );
    setFilterEndDate(endDate ? moment(endDate).format("YYYY-MM-DD") : null);
  };

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }
  const initialValues = {
    jobName: "",
    jobId: "",
    originalFile: "",
    exportedFile: "",
    originalName: "",
    recipientName: "",
    startDate: "",
    endDate: "",
  };
  const [values, setValues] = useState(initialValues);
  const toggleButton = () => {
    setToggle(!toggle);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleChange = (e, key) => {
    setValues({
      ...values,
      [key]: e,
    });
  };

  const handleDateChange = (e) => {
    let startObj = e[0];
    let endObj = e[1];
    let startDate = `${startObj["$D"]}-${startObj["$M"] + 1}-${startObj["$y"]}`;
    let endDate = `${endObj["$D"]}-${endObj["$M"] + 1}-${endObj["$y"]}`;
    console.log(startDate, endDate);
    setValues({
      ...values,
      startDate,
      endDate,
    });
  };

  const OnSubmit = (e) => {
    e.preventDefault();
    alert(values);
    console.log(values);
  };
  return (
    <>
      <div
        className="container-fluid login_page sub_page"
        style={{ position: "absolute" }}
      >
        <div className="row" style={{ paddingLeft: "12rem" }}>
          <div className="col-sm-12 col-md-12  col-lg-1 quote-section text-center sub_section"></div>

          <div className="col-sm-6 col-lg-8 col-md- services_section">
            <div className="d-flex p-5 justify-content-center ps-5">
              <div className="col-md-4 col-lg-7 col-sm-16 ">
                <div
                  className="widget-stat card bg-dashboard "
                  style={{
                    borderRadius: 15,
                    width: "250px",
                    cursor: "pointer",
                  }}
                  onClick={toggleButton}
                >
                  <div className="card-body ">
                    <div className="media">
                      <div
                        style={{
                          display: "table",
                        }}
                      >
                        <img
                          src={"/images/Asset1.png"}
                          className=""
                          style={{
                            margin: "10px",
                            width: "55px",
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                          alt=""
                        />
                        <div
                          className="text-muted  "
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                        >
                          Failed Operations{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-7 col-sm-12 ">
                <div
                  className="widget-stat card bg-dashboard "
                  style={{
                    borderRadius: 15,
                    width: "250px",
                    cursor: "pointer",
                  }}
                >
                  <div className="card-body ">
                    <div className="media">
                      <div
                        style={{
                          display: "table",
                        }}
                      >
                        <img
                          src={"/images/Asset2.png"}
                          className=""
                          style={{
                            margin: "10px",
                            width: "55px",
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                          alt=""
                        />
                        <div
                          className="text-muted "
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                        >
                          Successful Operations
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-7 col-sm-12 ">
                <div
                  className="widget-stat card bg-dashboard "
                  style={{
                    borderRadius: 15,
                    width: "250px",
                    cursor: "pointer",
                  }}
                >
                  <div className="card-body ">
                    <div className="media">
                      <div
                        style={{
                          display: "table",
                        }}
                      >
                        <img
                          src={"/images/Asset3.png"}
                          className=""
                          style={{
                            margin: "10px",
                            width: "55px",
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                          alt=""
                        />
                        <div
                          className="text-muted small"
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                          }}
                        >
                          Pending Operations
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggle && (
        <div className="">
          <div
            className="col-md-6 col-lg-10 col-sm-12 services_section pt-4 table_section"
            style={{ position: "relative", top: 190, marginLeft: "7rem" }}
          >
            <div className="row">
              <div className="col-md-3">
                <label>Job Name</label>
                <Search
                  placeholder="Job Name"
                  name="jobName"
                  value={values.jobName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label>JobId</label>
                <Search
                  placeholder="Job ID"
                  name="jobId"
                  value={values.jobId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label>Original File Name</label>
                <Search
                  placeholder="Original File Name"
                  value={values.originalFile}
                  name="originalFile"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label>Exported File</label>
                <Search
                  placeholder="Exported File"
                  name="exportedFile"
                  value={values.exportedFile}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mt-2 mb-3">
              <div className="col-md-3">
                <label>Original Name</label>
                <Select
                  defaultValue="Original Name"
                  value={values.originalName}
                  name="originalName"
                  onChange={(e) => handleChange(e, "originalName")}
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Recipient Name</label>
                <Select
                  defaultValue="Recipient Name"
                  value={values.recipientName}
                  name="recipientName"
                  onChange={(e) => handleChange(e, "recipientName")}
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "jack1",
                      label: "Jack1",
                    },
                    {
                      value: "lucy1",
                      label: "Lucy1",
                    },
                    {
                      value: "Yiminghe1",
                      label: "yiminghe1",
                    },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Date Range Picker</label>
                <Space>
                  <RangePicker onChange={handleDateChange} />
                </Space>
              </div>
              <div className="col-md-3 mt-4">
                <Button
                  type="primary"
                  onClick={OnSubmit}
                  icon={<SearchOutlined />}
                  style={{ marginTop: ".5rem", width: "100%" }}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-10 col-sm-12 services_section pt-5 table_section"
            style={{ position: "relative", top: 200, marginLeft: "7rem" }}
          >
            <div className="">
              <div className="row table_box">
                <div className={"col-md-3"}>
                  <h4>List of Failed Operations</h4>
                </div>

                <table className="table" style={{ fontSize: "13px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Action</th>
                      <th scope="col">Status Logs&nbsp;</th>
                      <th scope="col">Time</th>
                      <th scope="col">Originator</th>
                      <th scope="col">Recipient&nbsp;</th>
                      <th scope="col">Job ID&nbsp;</th>
                      <th scope="col">Job Name</th>
                      <th scope="col">Original File Name</th>
                      <th scope="col">Exported File Name&nbsp;</th>
                    </tr>
                  </thead>

                  {jobs?.results?.length > 0 ? (
                    <tbody>
                      {jobs.results.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className=""></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{item.job_id_no}</td>
                            <td>{item.job_name}</td>
                            <td className="action_btns"></td>
                            <td className="action_btns"></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={6}> No records found</td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <Pagination data={jobs} handlePageClick={handlePagination} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
