import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { JobLogTable } from "../../components/DataParser/JobLogTable";
import { getAllJob, getJobLog, setLoading } from "../../redux/actions/other";
import { useDispatch } from "react-redux";

const Reports = () => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [jobLogs, setJobLogs] = useState([]);

  useEffect(() => {
    allJobList();
  }, []);

  // Get all job list
  const allJobList = () => {
    dispatch(setLoading(true));
    dispatch(getAllJob())
      .then((response) => {
        setJobs(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // Get job status (Log)
  const getJobStatus = (id) => {
    dispatch(setLoading(true));
    dispatch(getJobLog(id))
      .then((response) => {
        setJobLogs(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // Handle job selection
  const handleJobChange = (job_id) => {
    if (job_id) {
      let data = jobs.find((job) => Number(job.id) === Number(job_id));
      setSelectedJob(data);
      getJobStatus(job_id);
    } else {
      setSelectedJob({});
      setJobLogs([]);
    }
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">Report</h1>
              <Link
                style={{ textDecoration: "none" }}
                className="float-right btn_dashboard"
                to="/dashboard"
              >
                <i className="fa fa-home" />
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12 table_section shadow">
          <div className="row table_box">
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="form-group">
                <select
                  className="form-control w-75"
                  id="exampleFormControlSelect1"
                  value={selectedJob["id"]}
                  onChange={(e) => {
                    handleJobChange(e.target.value);
                  }}
                >
                  <option value={""}>Select Job</option>
                  {jobs &&
                    jobs.map((job, idx) => (
                      <option key={idx} value={job.id}>
                        {job.job_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <JobLogTable jobLogs={jobLogs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
