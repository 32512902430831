import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { setLoading, updatePassword } from "../../redux/actions/other";
import * as Yup from "yup";

const ChangePassword = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const initialValue = {
    email: localStorage.getItem("email"),
    password: "",
    otp: "",
    ConfirmPassword: "",
  };
  const passwordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Password is required"),
    ConfirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm password is required"),
    otp: Yup.string()
      .matches(/^[0-9]{1,6}$/, "Enter 6 digit OTP")
      .required("OTP is required"),
  });

  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    dispatch(updatePassword(values))
      .then((response) => {
        dispatch(setLoading(false));
        history("/");
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  function isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    return !(
      charCode !== 46 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    );
  }

  return (
    <div>
      <div className="container-fluid login_page">
        <div className="row">
          <div className="col-sm-6 quote-section ">
            <img
              src="/images/forgot_element.png"
              alt=""
              width="60%"
              className="float-right"
            />
          </div>
          <div className="col-sm-6 form-section">
            <div className="login-wrapper">
              <img
                src="/img/AiBits_D.png"
                alt=""
                className="logo"
                style={{ height: "auto", width: "200px" }}
              />
              <div className="form_outer1">
                <div className="form_outer2">
                  <Formik
                    initialValues={initialValue}
                    validationSchema={passwordSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ errors, touched }) => (
                      <Form action="">
                        <h3 className="forgot-password">Forgot Password</h3>
                        <div className="form-group ">
                          <label htmlFor=" password" className="password">
                            Password
                          </label>
                          <Field
                            name="password"
                            placeholder="Enter Password"
                            className={`form-control ${
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }`}
                            type="password"
                          />
                          <i className="fa fa-lock" />
                          <ErrorMessage
                            component="div"
                            name="password"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="new-password"
                            className="new-password"
                          >
                            Confirm Password
                          </label>
                          <Field
                            name="ConfirmPassword"
                            placeholder="Enter Password"
                            type="password"
                            className={`form-control ${
                              touched.ConfirmPassword && errors.ConfirmPassword
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <i className="fa fa-lock" />
                          <ErrorMessage
                            component="div"
                            name="ConfirmPassword"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="otp" className="otp">
                            OTP
                          </label>
                          <Field
                            onKeyUp={(e) => isNumberKey(e)}
                            type="number"
                            name="otp"
                            min="0"
                            placeholder="Enter OTP"
                            className={`form-control ${
                              touched.otp && errors.otp ? "is-invalid" : ""
                            }`}
                          />
                          <i className="fa fa-lock" />
                          <ErrorMessage
                            component="div"
                            name="otp"
                            className="invalid-feedback"
                          />
                        </div>

                        <button
                          name="login"
                          id="login"
                          className="btn btn-block login-btn"
                          type="Submit"
                        >
                          Set Password
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
