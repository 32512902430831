import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  deleteCorporate,
  getCorporateList,
  setLoading,
} from "../../../redux/actions/other";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Pagination from "../../../components/Pagination";
import CorporateCreate from "./create";

const CorporateList = () => {
  const dispatch = useDispatch();
  const [corporates, setCorporates] = useState({
    links: {},
    total: 0,
    page: 1,
    page_size: 10,
    results: [],
  });
  const [page, setPage] = useState(1);
  const [editId, setEditId] = useState(null);
  const [show, setShow] = useState("hide");
  const [isRefresh, setIsRefresh] = useState(false);
  const inputRef = useRef(null);
  const [filter, setFilter] = useState("");
  const [sorting, setSorting] = useState({ key: "", ascending: true });

  const handleClose = () => {
    setShow("hide");
    setEditId(null);
  };
  const handleShow = () => {
    setShow("show");
    setEditId(null);
  };
  const handleRefresh = () => {
    getList(page);
  };

  useEffect(() => {
    getList(page);
  }, [isRefresh, page, filter, sorting]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const edit = (id) => {
    setEditId(id);
    setShow("show");
  };

  const getList = (page) => {
    dispatch(setLoading(true));
    dispatch(getCorporateList(page, filter, sorting))
      .then((response) => {
        setCorporates(response);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const remove = (id) => {
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(deleteCorporate(id))
          .then((response) => {
            dispatch(setLoading(false));
            getList(1);
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const search = () => {
    setFilter(inputRef.current.value);
  };

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }

  return (
    <div className="container">
      <div className="col-md-12 table_section">
        <div className="row table_box">
          <div className={"col-md-3"}>
            <h4>List of corporates</h4>
          </div>
          <div className="col-md-2">
            <input
              ref={inputRef}
              type="text"
              className={"form-control"}
              placeholder="Search..."
            />
          </div>
          <div className={"col-md-2"}>
            <div className="col">
              <button
                className="btn_search"
                id="add-edit-corporate"
                onClick={search}
              >
                Search
              </button>
            </div>
          </div>
          {/* <div className="col-md-3"></div> */}
          <div className={"col-md-3"}>
            <button
              className="btn-sm btn-add-field float-end"
              id="add-edit-corporate"
              onClick={handleShow}
            >
              <i className="fa fa-plus" />
              Add Corporate
            </button>
          </div>
          <div className={"col-md-2 -mt-2"}>
            <button
              className="btn-sm btn-add-field float-end"
              id="add-edit-corporate"
            >
              <i className="fa fa-cog" />
              Export
            </button>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Corporate Customer</th>
                <th
                  scope="col"
                  onClick={() => applySorting("brand", !sorting.ascending)}
                >
                  Brand&nbsp;
                  {sorting.key === "brand" && (
                    <i
                      className={`fa fa-sort-${
                        sorting.ascending ? "up" : "down"
                      }`}
                    />
                  )}
                </th>
                <th scope="col">Company</th>
                <th
                  scope="col"
                  onClick={() => applySorting("no_pods", !sorting.ascending)}
                >
                  No of PODs&nbsp;
                  {sorting.key === "no_pods" && (
                    <i
                      className={`fa fa-sort-${
                        sorting.ascending ? "up" : "down"
                      }`}
                    />
                  )}
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {corporates.results.length > 0 ? (
              <tbody>
                {corporates.results.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="sr_no">{i + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.brand}</td>
                      <td>{item.company.name}</td>
                      <td>{item.no_pods}</td>
                      <td className="action_btns">
                        <Link onClick={() => edit(item.id, item)} to={""}>
                          <i className="fa fa-pencil btn_edit" />
                        </Link>
                        <Link onClick={() => remove(item.id)} to={""}>
                          <i className="fa fa-trash-o btn_delete" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}> No records found</td>
                </tr>
              </tbody>
            )}
          </table>
          <Pagination data={corporates} handlePageClick={handlePagination} />
        </div>
      </div>
      {show === "show" && (
        <CorporateCreate
          open={show}
          editId={editId}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
      )}
    </div>
  );
};

export default CorporateList;
