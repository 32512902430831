import axios from "axios";
import authHeader from "../helper/auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getSubAdminList = (page, filter, sorting) => {
  let search = "",
    sort = "";
  if (filter !== "") {
    search = `&search=${filter}`;
  }
  if (sorting["key"] !== "") {
    if (!sorting["ascending"]) {
      sort = `&ordering=-${sorting["key"]}`;
    } else {
      sort = `&ordering=${sorting["key"]}`;
    }
  }
  return axios.get(
    API_URL + `api/v1/auth/subadmin-user-list?page=${page}${search}${sort}`,
    { headers: authHeader() }
  );
};

const addSubAdmin = (data) => {
  return axios.post(API_URL + `api/v1/auth/subadmin-user`, data, {
    headers: authHeader(),
  });
};

const getSubAdminDetail = (id) => {
  return axios.get(API_URL + `api/v1/auth/user/${id}`, {
    headers: authHeader(),
  });
};

const updateSubAdmin = (id, data) => {
  return axios.put(API_URL + `api/v1/auth/user/${id}`, data, {
    headers: authHeader(),
  });
};

const deleteSubAdmin = (id) => {
  return axios.delete(API_URL + `api/v1/auth/user/${id}`, {
    headers: authHeader(),
  });
};

export default {
  getSubAdminList,
  addSubAdmin,
  getSubAdminDetail,
  updateSubAdmin,
  deleteSubAdmin,
};
