import { EXCEL, LOADING, PARSE, PARSEDDATA, SET_CONFIG_ID } from "./types";
import CorporateService from "../../services/corporate.service";
import CompanyService from "../../services/company.service";
import JobService from "../../services/job.service";
import AdminService from "../../services/admin.service";
import SubAdminService from "../../services/subAdmin.service";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { logout } from "./auth";
import swal from "sweetalert";
import jobService from "../../services/job.service";

export const setLoading = (data) => ({
  type: LOADING,
  payload: data,
});

// Company CRUD ------------
export const getCompanyList = (page, filter, sorting) => (dispatch) => {
  return CompanyService.getCompanyList(page, filter, sorting).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getAllCompany = () => (dispatch) => {
  return CompanyService.getAllCompany().then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getCompanyDetail = (data) => (dispatch) => {
  return CompanyService.getCompanyDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addCompany = (data) => (dispatch) => {
  return CompanyService.addCompany(data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateCompany = (id, data) => (dispatch) => {
  return CompanyService.updateCompany(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteCompany = (id) => (dispatch) => {
  return CompanyService.deleteCompany(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// Corporate CRUD ------------
export const getCorporateList = (page, filter, sorting) => (dispatch) => {
  return CorporateService.getCorporateList(page, filter, sorting).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getCorporateDetail = (data) => (dispatch) => {
  return CorporateService.getCorporateDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addCorporate = (data) => (dispatch) => {
  return CorporateService.addCorporate(data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateCorporate = (id, data) => (dispatch) => {
  return CorporateService.updateCorporate(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteCorporate = (id) => (dispatch) => {
  return CorporateService.deleteCorporate(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getCorporateByCompany = (id) => (dispatch) => {
  return CorporateService.getCorporateByCompany(id).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// Job CRUD ------------
export const getJobList =
  (page, filter, filterStartDate, filterEndDate, sorting) => (dispatch) => {
    return JobService.getJobList(
      page,
      filter,
      filterStartDate,
      filterEndDate,
      sorting
    ).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        errorHandling(error, dispatch);
        return Promise.reject();
      }
    );
  };

export const getJobDetail = (data) => (dispatch) => {
  return JobService.getJobDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addJob = (data) => (dispatch) => {
  return JobService.addJob(data).then(
    (response) => {
      swal(
        `Job ID no ${response["data"]["data"]["job_id_no"]} is successfully created`,
        "",
        "success"
      );
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateJob = (id, data) => (dispatch) => {
  return JobService.updateJob(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteJob = (id) => (dispatch) => {
  return JobService.deleteJob(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const chnageStatJob = (id, data) => (dispatch) => {
  return JobService.chnageStatJob(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};
export const changeStatToEnable = (id) => (dispatch) => {
  return JobService.changeStatToEnable(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};
export const getAllJob = () => (dispatch) => {
  return JobService.getAllJob().then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getThreadList = (id) => (dispatch) => {
  return JobService.getThreadList(id).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};
export const getJobSettings = (id) => (dispatch) => {
  return JobService.getJobSettings(id).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getJobLog = (id) => (dispatch) => {
  return JobService.getJobLog(id).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// Change password
export const sendOTP = (data) => (dispatch) => {
  return AuthService.sendOTP(data).then(
    (response) => {
      localStorage.setItem("email", data.email);
      swal(response.data.message, "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updatePassword = (data) => (dispatch) => {
  return AuthService.updatePassword(data).then(
    (response) => {
      localStorage.removeItem("email");
      swal(response.data.message, "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// Admin CRUD
export const getAdminList = (page, filter, sorting) => (dispatch) => {
  return AdminService.getAdminList(page, filter, sorting).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addAdmin = (data) => (dispatch) => {
  return AdminService.addAdmin(data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateAdmin = (id, data) => (dispatch) => {
  return AdminService.updateAdmin(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteAdmin = (id) => (dispatch) => {
  return AdminService.deleteAdmin(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getAdminDetail = (data) => (dispatch) => {
  return AdminService.getAdminDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};
export const getJobId = (data) => (dispatch) => {
  return jobService.getJobId(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// SubAdmin CRUD
export const getSubAdminList = (page, filter, sorting) => (dispatch) => {
  return SubAdminService.getSubAdminList(page, filter, sorting).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addSubAdmin = (data) => (dispatch) => {
  return SubAdminService.addSubAdmin(data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateSubAdmin = (id, data) => (dispatch) => {
  return SubAdminService.updateSubAdmin(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteSubAdmin = (id) => (dispatch) => {
  return SubAdminService.deleteSubAdmin(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getSubAdminDetail = (data) => (dispatch) => {
  return SubAdminService.getSubAdminDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// USER crud
export const getUserList = (page, filter, sorting) => (dispatch) => {
  return UserService.getUserList(page, filter, sorting).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const getUserDetail = (data) => (dispatch) => {
  return UserService.getUserDetail(data).then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const addUser = (data) => (dispatch) => {
  return UserService.addUser(data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateUser = (id, data) => (dispatch) => {
  return UserService.updateUser(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const deleteUser = (id) => (dispatch) => {
  return UserService.deleteUser(id).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

// Data parser
export const getSettingsList = () => (dispatch) => {
  return JobService.getSettingsList().then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const saveDataParser = (id, data) => (dispatch) => {
  return JobService.saveDataParser(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const updateDataParser = (id, data) => (dispatch) => {
  return JobService.updateDataParser(id, data).then(
    (response) => {
      swal(response["data"]["message"], "", "success");
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const setExcelObject = (data) => ({
  type: EXCEL,
  payload: data,
});

export const setParserObject = (data) => ({
  type: PARSE,
  payload: data,
});

// Data config
export const getRuleList = () => (dispatch) => {
  return JobService.getRuleList().then(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      errorHandling(error, dispatch);
      return Promise.reject();
    }
  );
};

export const errorHandling = (error, dispatch) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  if (error.response.status === 401) {
    dispatch(logout());
  }
  swal(message, "", "error");
};
export const setConfigId = (id) => {
  return {
    type: SET_CONFIG_ID,
    payload: id,
  };
};
export const parsedData = (data) => {
  return {
    type: PARSEDDATA,
    payload: data,
  };
};
