import "../.././index.css";
import swal from "sweetalert";
import { useState } from "react";
import "react-select-search/style.css";
import { Link } from "react-router-dom";
import { MdHelp } from "react-icons/md";
import { useSelector } from "react-redux";
import { Stepper } from "react-form-stepper";
import { Popover, Select, Modal } from "antd";
import { Button } from "@mui/material";
import { OriginalData } from "../../components/DataParser/OriginalData";
import { EditFilled } from "@ant-design/icons";
import { Input } from "antd";
import { FaCode } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  getJobDetail,
  saveDataParser,
  setLoading,
  setParserObject,
} from "../../redux/actions/other";
const functionsToApply = [
  { value: "All", label: "All" },
  { value: "Numeric Only (0-9)", label: "Numeric Only (0-9)" },
  {
    value: "Currency Only (0-9, ., $...)",
    label: "Currency Only (0-9, ., $...)",
  },
  {
    value: "Hexadecimal Only (0-9, A-F, a-f)",
    label: "Hexadecimal Only (0-9, A-F, a-f)",
  },
  { value: "Alpha Only (A-Z, a-z)", label: "Alpha Only (A-Z, a-z)" },
  {
    value: "Alphanumeric (0-9, A-Z, a-z)",
    label: "Alphanumeric (0-9, A-Z, a-z)",
  },
  { value: "Custom (Regex)", label: "Custom (Regex)" },
];

export default function ValidationTabs(props) {
  const dispatch = useDispatch();
  const config_jobId = useSelector((state) => state.other.configId);
  const parsedFinalData = useSelector((state) => state.other.parsedData);
  const groupCheckPreviewData = parsedFinalData.groupCheckPreviewData;
  const groupCheckData = parsedFinalData.groupCheckData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const handleOk = () => {
    if (validationValues?.[isModalOpen.index]?.regexCode) {
      setIsModalOpen({});
    } else {
      setError("Please enter a Custom Regex.");
    }
  };
  const handleCancel = () => {
    setIsModalOpen({});
  };
  const [count, setCount] = useState(1);
  const initialValidation = groupCheckData.map((item) => {
    return {
      ...item,
      dataType: "All",
      status: "",
      regexCode: "",
    };
  }); // status will be Fail or Pass

  const [validationValues, setValidationValues] = useState(initialValidation);
  let [updateValidationValues, setUpdateValidationValues] = useState([]);

  const handleChange = (event, index) => {
    const { name, value } = event;
    const arr = [...validationValues];
    arr[index] = { ...arr[index], [name]: value };
    if (value === "Custom (Regex)") {
      setIsModalOpen({ isOpen: true, index: index });
    }
    setValidationValues(arr);
  };

  // Click on Next button after file import
  const handleClick = () => {
    // Redirect to google.com
    window.open(
      "https://confluence.atlassian.com/proforma/regex-validation-1087521274.html",
      "_blank"
    );
  };

  const handleSecExcelNext = () => {
    validationValues.map((item, index) => {
      const isLast = index == validationValues.length - 1;
      validateDataTypes(item, isLast);
      if (isLast) {
        setCount(3);
      }
    });
  };

  // useEffect(() => {
  //   if (count == 3) {
  //     console.log(JSON.parse(localStorage.getItem("updateValidationValues")));
  //     setUpdateValidationValues(
  //       JSON.parse(localStorage.getItem("updateValidationValues"))
  //     );
  //     console.log(updateValidationValues, "l");
  //     console.log("kkkk");
  //   }
  // }, [count]);

  const validateDataTypes = (data, isLast) => {
    const ind = groupCheckData.findIndex((item, index) => {
      if (data.alias === item.alias) {
        return index;
      }
    });
    const fieldIndex = ind == -1 ? 0 : ind;
    handleApplyDataTypes(fieldIndex, data, isLast);
  };

  // To Aplly DataTypes
  const handleApplyDataTypes = (fieldIndex, data, isLast) => {
    groupCheckPreviewData.shift();
    const getUpdatedValues = JSON.parse(
      localStorage.getItem("updateValidationValues")
    );
    const finalValue = getUpdatedValues ? getUpdatedValues : validationValues;
    // isLast && setValidationValues(finalValue);
    // handle all
    const handleAll = () => {
      finalValue.findIndex((item, index) => {
        if (data.alias === item.alias) {
          const arr = [...finalValue];
          arr[index] = { ...arr[index], status: "Pass" };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle check number
    const handleValidateNumeric = () => {
      const numericPattern = /^[0-9]+$/;
      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        console.log(arr2[fieldIndex], fieldIndex);
        return numericPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle check currency
    const handleValidateCurrency = () => {
      const currencyPattern = /[$€£¥₹]/;
      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        return currencyPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle check Hexadecimal
    const handleValidateHexadecimal = () => {
      const hexadeciPattern = /\b(?:0[xX])?[0-9a-fA-F]+\b/;
      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        return hexadeciPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle check Alpha
    const handleValidateAlpha = () => {
      const alphaPattern = /^[A-Za-z\s]+$/;
      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        return alphaPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle check Alphanumeric
    const handleValidateAlphanumeric = () => {
      const alphanumericPattern = /^[A-Za-z0-9]+$/;
      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        return alphanumericPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // handle custom regex
    const handleCustomRegex = () => {
      const re = finalValue.filter((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          return arr[index].regexCode;
        }
      });
      const customRegexPattern = new RegExp(re?.[0].regexCode);

      const values = groupCheckPreviewData.map((item) => {
        const arr = item;
        const arr2 = [...arr];
        return customRegexPattern.test(arr2[fieldIndex]);
      });

      finalValue.findIndex((item2, index) => {
        if (data.alias === item2.alias) {
          const arr = [...finalValue];
          arr[index] = {
            ...arr[index],
            status: values.includes(false) ? "Fail" : "Pass",
          };
          console.log(arr[index]);
          localStorage.setItem("updateValidationValues", JSON.stringify(arr));
        }
      });
    };

    // Call validate functions
    switch (data.dataType) {
      case "Numeric Only (0-9)":
        return handleValidateNumeric();
      case "Currency Only (0-9, ., $...)":
        return handleValidateCurrency();
      case "Hexadecimal Only (0-9, A-F, a-f)":
        return handleValidateHexadecimal();
      case "Alpha Only (A-Z, a-z)":
        return handleValidateAlpha();
      case "Alphanumeric (0-9, A-Z, a-z)":
        return handleValidateAlphanumeric();
      case "Custom (Regex)":
        return handleCustomRegex();
      default:
        return handleAll();
    }
  };

  const handleBackButton = () => {
    localStorage.removeItem("updateValidationValues");
    setValidationValues(initialValidation);
    setUpdateValidationValues([]);
    setCount(count - 1);
  };

  const handleNextButton = () => {
    localStorage.removeItem("updateValidationValues");
    setValidationValues(initialValidation);
    setCount(count + 1);
  };

  const handleThirdBackButtonSubmit = () => {
    const dat = JSON.parse(localStorage.getItem("updateValidationValues"));
    const arr = dat.map((item) => item.status);

    if (!arr.includes("Fail")) {
      const payload = {
        validationValues: dat,
        job_status: "Validation Config",
      };
      dispatch(setLoading(true));
      dispatch(setParserObject(payload));
      dispatch(saveDataParser(config_jobId, payload))
        .then(async (res) => {
          getJobDetail();
          dispatch(setLoading(false));
          swal("Validation config", "", "success").then(async (willProceed) => {
            if (willProceed) {
              props.setCurrent(props.current + 1);
            }
          });
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      swal("Validation config not valid", "", "error");
    }
  };

  const components = [
    <>
      {/* <Test /> */}
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">
            Parsed Data Preview
            <span
              className="float-end"
              style={{ fontSize: "20px", color: "#e06968" }}
            ></span>
          </h4>
          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_red" onClick={() => handleNextButton()}>
              Next
            </button>
          </div>
          <div className="row table_box red_table mt-3">
            <OriginalData
              groupCheckPreviewData={groupCheckPreviewData}
              groupCheckData={groupCheckData}
            />
          </div>
        </div>
      </div>
    </>,
    <>
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">
            Validations
            <span className="float-end" style={{ marginRight: "1rem" }}>
              <Popover
                placement="rightTop"
                title={"Help"}
                content={"For custom validation"}
              >
                <Button onClick={handleClick}>
                  <MdHelp />
                </Button>
              </Popover>
            </span>
          </h4>
          <div className="row table_box red_table mt-3">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr. No.</th>
                  <th scope="col">Field Name</th>
                  <th scope="col">Mandatory</th>
                  <th scope="col">Data Types</th>
                </tr>
              </thead>
              <tbody>
                {validationValues?.map((item, index) => (
                  <tr key={index}>
                    <td className="sr_no">{index + 1}</td>
                    <td>{item.alias}</td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input check-box"
                        checked={item.mandatory}
                        name="mandatory"
                        onChange={(event) =>
                          handleChange(
                            { name: "mandatory", value: event.target.checked },
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <Select
                        name="dataType"
                        defaultValue="All"
                        style={{ width: 250 }}
                        onChange={(event) =>
                          handleChange(
                            { name: "dataType", value: event },
                            index
                          )
                        }
                        options={functionsToApply}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            title="Enter your Custom Regex"
            open={isModalOpen.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Input
              size="large"
              placeholder="Enter your Regex"
              value={validationValues?.[isModalOpen.index]?.regexCode}
              name="regexCode"
              onChange={(event) => {
                setError("");

                handleChange(
                  {
                    name: "regexCode",
                    value: event.target.value,
                  },
                  isModalOpen.index
                );
              }}
              addonBefore={<EditFilled />}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
          </Modal>

          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={() => handleBackButton()}>
              Back
            </button>
            <button className="btn_red" onClick={() => handleSecExcelNext()}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>,
    <>
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">Preview Results</h4>
          <div className="row table_box red_table mt-3">
            <div className="row table_box red_table mt-3">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Field Name</th>
                    <th scope="col">Mandatory</th>
                    <th scope="col">Data Type</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {(localStorage.getItem("updateValidationValues")
                    ? JSON.parse(localStorage.getItem("updateValidationValues"))
                    : []
                  )?.map((item, index) => (
                    <tr key={index}>
                      <td className="sr_no">{index + 1}</td>
                      <td>{item.alias}</td>
                      <td>
                        <input
                          type="checkbox"
                          disabled
                          className="form-check-input check-box"
                          checked={item.mandatory}
                          name="mandatory"
                          onChange={(event) =>
                            handleChange(
                              {
                                name: "mandatory",
                                value: event.target.checked,
                              },
                              index
                            )
                          }
                        />
                      </td>
                      <td>
                        {item.dataType === "Custom (Regex)" ? (
                          <div>
                            Custom (Regex)
                            <Popover placement="right" content="Show Code">
                              <Button
                                type="primary"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  swal({
                                    title: "Custom Regex Code",
                                    text: item.regexCode,
                                    dangerMode: true,
                                    showConfirmButton: true,
                                    confirmButtonText: "Ok",
                                  }).then(() => {});
                                }}
                              >
                                <FaCode />
                              </Button>
                            </Popover>
                          </div>
                        ) : (
                          item.dataType
                        )}
                      </td>
                      <td
                        className={`${
                          item.status === "Fail" ? "text-red" : "text-green"
                        }`}
                      >
                        {item.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={() => handleBackButton()}>
              Back
            </button>

            <button
              className="btn_red"
              onClick={() => handleThirdBackButtonSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>,
  ];

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <Link to="/dashboard"></Link>
            </div>
            <div className="container">
              <Stepper
                className={"w-100 p-0 pb-3 mt-2"}
                steps={[
                  { label: "Parsed Data Preview" },
                  { label: "Validations" },
                  { label: "Preview Results" },
                ]}
                activeStep={count - 1}
                stepClassName={"stepper__step"}
              />
            </div>
          </div>
        </div>
      </div>
      {components[count - 1]}
    </div>
  );
}
