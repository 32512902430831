import axios from "axios";
import authHeader from "../helper/auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getCorporateDetail = (id) => {
  return axios.get(API_URL + `api/v1/jobs/corporate/${id}`, {
    headers: authHeader(),
  });
};

const addCorporate = (data) => {
  return axios.post(API_URL + `api/v1/jobs/corporate`, data, {
    headers: authHeader(),
  });
};

const updateCorporate = (id, data) => {
  return axios.put(API_URL + `api/v1/jobs/corporate/${id}`, data, {
    headers: authHeader(),
  });
};

const deleteCorporate = (id) => {
  return axios.delete(API_URL + `api/v1/jobs/corporate/${id}`, {
    headers: authHeader(),
  });
};

const getCorporateList = (page, filter, sorting) => {
  let search = "",
    sort = "";
  if (filter !== "") {
    search = `&search=${filter}`;
  }
  if (sorting["key"] !== "") {
    if (!sorting["ascending"]) {
      sort = `&ordering=-${sorting["key"]}`;
    } else {
      sort = `&ordering=${sorting["key"]}`;
    }
  }
  return axios.get(
    API_URL + `api/v1/jobs/corporate-list?page=${page}${search}${sort}`,
    { headers: authHeader() }
  );
};

const getCorporateByCompany = (company_id) => {
  return axios.get(API_URL + `api/v1/jobs/corporate-by-company/${company_id}`, {
    headers: authHeader(),
  });
};

export default {
  getCorporateDetail,
  addCorporate,
  updateCorporate,
  deleteCorporate,
  getCorporateList,
  getCorporateByCompany,
};
