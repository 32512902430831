import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { login } from "../../redux/actions/auth";
import * as Yup from "yup";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();

  let initialValue = { email: "admin@gmail.com", password: "12345" };
  let loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(login(values.email, values.password))
      .then((response) => {
        setLoading(false);
        history("/dashboard");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid login_page">
      <div className="row">
        <div className="col-sm-6 quote-section ">
          <img
            src="/images/laptop.png"
            alt=""
            width="80%"
            className="float-right"
          />
        </div>
        <div className="col-sm-6 form-section">
          <div className="login-wrapper">
            <img
              src="/img/AiBits_D.png"
              alt=""
              className="logo"
              style={{ height: "auto", width: "200px" }}
            />
            <div className="form_outer1">
              <div className="form_outer2">
                <Formik
                  initialValues={initialValue}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <h3>Login</h3>
                      <p>
                        Enter your email and password to access admin panel.
                      </p>
                      <div className="form-group">
                        <label className="email">Email ID</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter Name"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                        <i className="fa fa-user" />
                      </div>
                      <div className="form-group mb-3">
                        <label className="password">Password</label>
                        <Field
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                        <i className="fa fa-lock" />
                      </div>
                      <label className="form-check-label remember_box">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="Remember"
                        />
                        Remember
                      </label>
                      <Link
                        className="forgot-password-link float-right"
                        to="/forgot-password"
                      >
                        {" "}
                        Forgot Your Password?
                      </Link>
                      {loading ? (
                        <button
                          id="login"
                          disabled={true}
                          className="btn btn-block login-btn"
                        >
                          SIGNING IN..
                        </button>
                      ) : (
                        <button
                          id="login"
                          type="submit"
                          className="btn btn-block login-btn"
                        >
                          SIGN IN
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
