import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import React from "react";
import Loader from "./components/Loader";
import "bootstrap/dist/js/bootstrap";
import Unauthorized from "./components/Unauthorized";
import "react-toastify/dist/ReactToastify.css";
import ChangePassword from "./pages/Password/ForgotPassword";
import ForgotPasswordChangePassword from "./pages/Password/ChangePassword";
import DefaultLayout from "./components/DefaultLayout";
import "./App.css";

import AddJob from "./pages/Automation/AddJob";
function App() {
  const { isLoading } = useSelector((state) => state.other);

  return (
    <div className="App">
      <ToastContainer />
      {isLoading && <Loader />}
      <Routes>
        <Route
          path="/"
          element={
            <Unauthorized>
              <Login />
            </Unauthorized>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Unauthorized>
              <ChangePassword />
            </Unauthorized>
          }
        />
        <Route
          path="/change-password"
          element={
            <Unauthorized>
              <ForgotPasswordChangePassword />
            </Unauthorized>
          }
        />
        <Route path="*" element={<DefaultLayout />} />
      </Routes>
    </div>
  );
}

export default App;
