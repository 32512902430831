/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

const DataConfigTwo = (props) => {
  const { parserValue, excelValue, customRule, setActiveTab } = props;
  const sheetData = excelValue?.sheetData;
  const [headerOptions, setHeaderOptions] = useState([]);
  const [updatedHeaderOptions, setUpdatedHeaderOptions] = useState([]);
  const sheetNumber = excelValue?.sheet;
  const [sheet, setSheet] = useState([]);

  useEffect(() => {
    let finalArray = sheetData[sheetNumber];
    customRule["configs"]?.map((item) => {
      const field = item["field"]["value"];
      const rules = item["rules"];
      const fieldIndex = sheetData[sheetNumber][0].indexOf(field);

      rules?.map((item) => {
        finalArray = handlePreview(finalArray, item["value"], fieldIndex);
      });
    });

    setSheet(finalArray);
    setUpdatedHeaderOptions(finalArray[0]);
    console.log("finalArray", finalArray);

    allHeaderList();
    setUpdatedHeader();
  }, [customRule]);

  const setUpdatedHeader = () => {
    let latestArray = sheetData[sheetNumber];
    customRule["configs"]?.map((item) => {
      const field = item["field"]["value"];
      const rules = item["rules"];
      const fieldIndex = sheetData[sheetNumber][0].indexOf(field);

      rules?.map((item) => {
        latestArray = handlePreview(latestArray, item["value"], fieldIndex);
      });
    });
    const headers = Object.values(latestArray[0]);
    const tempLatest = [];
    headers?.map((item) => {
      tempLatest.push({ value: item, label: item });
    });
    setUpdatedHeaderOptions(tempLatest);
  };
  console.log("updatedHeaderOptions ", updatedHeaderOptions);

  const allHeaderList = () => {
    // const headers = Object.values(parserValue['alias_name']);
    const headers = Object.values(sheet[0] || parserValue["alias_name"]);
    console.log("headers", headers);
    const temp = [];
    headers?.map((item) => {
      temp.push({ value: item, label: item });
    });
    setHeaderOptions(temp);
  };

  const handlePreview = (data, ruleName, fieldIndex) => {
    if (ruleName === "uppercase" || ruleName === "lowercase") {
      return handleUpperLowerCase(data, fieldIndex, ruleName);
    } else if (ruleName === "titlecase") {
      return handleTitleCase(data, fieldIndex);
    } else if (
      ruleName === "trim_space_on_left" ||
      ruleName === "trim_space_on_right"
    ) {
      return handleTrimLeftRightCase(data, fieldIndex, ruleName);
    } else if (
      ruleName === "discard_left_characters" ||
      ruleName === "discard_right_characters"
    ) {
      return handleDiscardLeftRightChar(data, fieldIndex, ruleName);
    }
  };

  const handleUpperLowerCase = (data, fieldIndex, type) => {
    console.log("data , fieldIndex, type==> ", data, fieldIndex, type);
    let temp = [];
    data?.map((item, itemIndex) => {
      let temp1 = [];
      item.map((row, index) => {
        if (fieldIndex === 1) {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        } else if (fieldIndex === 2) {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        } else {
          if (index === fieldIndex) {
            if (itemIndex == 0 && index === fieldIndex) {
              temp1.push(row);
              temp1.push(row + "_" + type);
            } else if (type === "uppercase") {
              temp1.push(row);
              temp1.push(row.toString().toUpperCase());
            } else {
              temp1.push(row);
              temp1.push(row.toString().toLowerCase());
            }
          } else {
            temp1.push(row);
          }
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  const titleCase = (str) => {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  };

  const handleTitleCase = (data, fieldIndex) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          temp1.push(titleCase(row.toString()));
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });

    return temp;
  };

  const handleTrimLeftRightCase = (data, fieldIndex, type) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          if (type === "trim_space_on_left") {
            temp1.push(row.toString().trimStart());
          } else {
            temp1.push(row.toString().trimEnd());
          }
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  // remove first word from string
  function removeFirstWord(str) {
    const indexOfSpace = str.indexOf(" ");
    return str.substring(indexOfSpace + 1);
  }

  // remove last word from string
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");
    if (lastIndexOfSpace === -1) {
      return str;
    }
    return str.substring(0, lastIndexOfSpace);
  }

  const handleDiscardLeftRightChar = (data, fieldIndex, type) => {
    let temp = [];
    data?.map((item) => {
      let temp1 = [];
      item.map((row, index) => {
        if (index === fieldIndex) {
          if (type === "discard_left_characters") {
            temp1.push(removeFirstWord(row.toString()));
          } else {
            temp1.push(removeLastWord(row.toString()));
          }
        } else {
          temp1.push(row);
        }
      });
      temp.push(temp1);
    });
    return temp;
  };

  return (
    <>
      <div
        className="row table_box mt-4 red_table"
        style={{ maxHeight: "600px" }}
      >
        {sheetData && (
          <table className="table">
            <thead>
              <tr>
                {updatedHeaderOptions?.map((item, index) => (
                  <th key={index} scope="col">
                    {item["label"]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sheet &&
                sheet?.slice(1).map((row, index) => (
                  <tr key={index}>
                    {row.map((item, index) => (
                      <td key={index}>{item}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="form-group col-md-12 pt-4 text-right btn_group_section">
        <button
          type="button"
          className="btn_back"
          onClick={() => setActiveTab("one")}
        >
          {" "}
          Back
        </button>
        <button
          type="button"
          className="btn_red"
          onClick={() => {
            setActiveTab("three");
          }}
        >
          {" "}
          Next
        </button>
      </div>
    </>
  );
};

export default DataConfigTwo;
