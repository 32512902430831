import React from "react";

export const SheetTable = (props) => {
  const sheetData = props.sheetData;
  const sheet = props.sheet;
  const isPreviewSettings = props.isPreviewSettings;

  console.log("props===>", props, sheetData[sheet][0]);

  return (
    <>
      <div className="table_box red_table">
        <h4>File Preview</h4>
        {sheetData && (
          <table className="table">
            <thead>
              <tr>
                {sheetData[sheet][0]?.map((item, index) => (
                  <th key={index} scope="col">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sheetData[sheet]
                ?.slice(1, isPreviewSettings?.previewRows)
                .map((row, index) => (
                  <tr key={index}>
                    {row.map((item, index) => (
                      <td key={index}>{item}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
