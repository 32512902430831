import React, { useState } from "react";
import { toast } from "react-toastify";

const OrderTable = ({ rules, setCustomRule }) => {
  const [rulesArray, setRulesArray] = useState(rules);
  const arrayMove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  };

  const handleChange = (event, index) => {
    if (
      Number(event.target.value) > rulesArray.length - 1 ||
      Number(event.target.value) < 0
    ) {
      toast.error("Invalid order number");
    } else {
      let newIndex = Number(event.target.value);
      let data = arrayMove(rulesArray, index, newIndex),
        temp = [];
      data.map((x) => temp.push(x));
      setRulesArray(temp);
      setCustomRule(temp);
    }
  };

  return (
    <table className="table table-bordered">
      <tbody>
        {rulesArray?.map((row, index) => {
          return (
            <tr key={index}>
              <td width={70}>
                <input
                  type={"number"}
                  className={"form-control"}
                  value={index}
                  min={0}
                  max={rulesArray.length}
                  onChange={(e) => handleChange(e, index)}
                />
              </td>
              <td>{row?.label}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrderTable;
