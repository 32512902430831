/* eslint-disable default-case */
import { Link } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import "../.././index.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import { FaCode } from "react-icons/fa";

import {
  MenuItem,
  TextField,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import "react-select-search/style.css";
import {
  setLoading,
  saveDataParser,
  setParserObject,
  getJobSettings,
  setExcelObject,
  parsedData,
} from "../../redux/actions/other";
import { useState, useEffect } from "react";
import Code from "../../components/DataConfig/DataConfigCode";
import swal from "sweetalert";
import { OriginalData } from "../../components/DataParser/OriginalData";
import { Popover, Switch, Tooltip } from "antd";

const functionsToApply = [
  { value: 1, label: "Convert to uppercase" },
  { value: 2, label: "Convert to lowercase" },
  { value: 3, label: "Convert to titlecase" },
  { value: 4, label: "Trim spaces on left" },
  { value: 5, label: "Trim spaces on right" },
  { value: 6, label: "Substring" },
  { value: 7, label: "Discard left characters" },
  { value: 8, label: "Discard right characters" },
  { value: 9, label: "Keep left characters" },
  { value: 10, label: "Keep right characters" },
  { value: 11, label: "Replace string" },
  { value: 12, label: "Concatenate" },
  // { value: 13, label: "Lookup" },
  // { value: 14, label: "If…else…" },
  { value: "Custom rules", label: " Custom rules" },
];

export default function DataConfigTabs(props) {
  const dispatch = useDispatch();
  const config_jobId = useSelector((state) => state.other.configId);
  const { excelValue } = useSelector((state) => state.other);
  const parsedFinalData = useSelector((state) => state.other.parsedData);
  const [filterDataToPython, setFilterDataToPython] = useState({
    index: "",
    value: [],
  });
  const groupCheckPreviewData = parsedFinalData.groupCheckPreviewData;
  const groupCheckData = parsedFinalData.groupCheckData;
  const [rules, setRules] = useState([]);
  const [isEditRule, setIsEditRule] = useState(false);

  const [singleRuleCheckData, setSingleRuleCheckData] = useState(null);
  const [multipleRuleCheckData, setMultipleRuleCheckData] = useState(null);
  const [singleRuledPreviewData, setSingleRuledPreviewData] = useState(null);
  const [multipleRuledPreviewData, setMultipleRuledPreviewData] =
    useState(null);

  // handle rule status change
  const handleStatusChange = (ruleId) => {
    const updateRules = rules.map((item) =>
      item.RuleId == ruleId
        ? { ...item, status: item.status ? false : true }
        : item
    );
    const payload = {
      rules: ruleId ? updateRules : [...rules, formData],
      job_status: "Data Configuration",
    };
    swal({
      title: "Are you sure want to change rule status?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(setParserObject(payload));
        dispatch(saveDataParser(config_jobId, payload))
          .then((res) => {
            getDetail();
            dispatch(setLoading(false));
            swal("Rule status changed successfully", "", "success");
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(rules);

    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    const payload = {
      rules: tempData,
      job_status: "Data Configuration",
    };

    dispatch(setLoading(true));
    dispatch(setParserObject(payload));
    dispatch(saveDataParser(config_jobId, payload))
      .then((res) => {
        getDetail();
        dispatch(setLoading(false));
        swal("Rule sequence updated", "", "success");
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const [formData, setFormData] = useState({
    RuleId: "",
    AliasFieldName: "",
    RuleName: "",
    RuleDescription: "",
    FunctionSelection: "",
    FieldName: [],
    oldString: "",
    newString: "",
    status: true,
    customData: [],
    code: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const val = e.target.value;
    if (val === "Custom rules") {
      setCustom_rule(true);
    } else {
      setCustom_rule(false);
    }
  };

  const handleFiledNameChange = (event) => {
    setFormData({ ...formData, FieldName: event.target.value });
  };

  const [inputs, setInputs] = useState([]);
  const [newInput, setNewInput] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [custom_rule, setCustom_rule] = useState(false);
  const [childExcelValue, setChildExcelValue] = useState("");

  const AddMoreRules = () => {
    setInputs([...inputs, newInput]);
    setNewInput("");
  };

  const handleChildExcelValue = () => {
    setChildExcelValue(formData.FieldName);
    setIsPreview(true);
  };

  const [count, setCount] = useState(1);
  const [isInputVisible2, setInputVisible2] = useState(true);
  const toggleInputVisibility2 = () => setInputVisible2(false);

  useEffect(() => {
    getDetail();
    const keys = parsedFinalData.groupCheckData.map((item) => item.alias);
    dispatch(
      setExcelObject({
        sheetData: { Sheet1: [keys, ...parsedFinalData.groupCheckPreviewData] },
        sheet: "Sheet1",
      })
    );
  }, []);

  const handleRuleDelete = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
  };

  // for one rules on add rule
  const previewResult = () => {
    const ind = groupCheckData.findIndex((item) => {
      if (formData.FieldName.includes(item.alias)) {
        return item.id;
      }
    });

    const fieldIndex = ind == -1 ? 0 : ind;
    let isSingle = true;
    handleUpperLowerCase(
      fieldIndex,
      formData.FunctionSelection,
      formData,
      isSingle
    );
    setIsPreview(true);
  };

  // after add  rule all rules apply
  const previewResultOnThirdStep = (data) => {
    const updatedMultipleCheckData = JSON.parse(
      localStorage.getItem("updatedMultipleCheckData")
    );
    const ind = (
      updatedMultipleCheckData ? updatedMultipleCheckData : groupCheckData
    ).findIndex((item, index) => {
      if (data.FieldName?.includes(item.alias)) {
        return index;
      }
    });
    const fieldIndex = ind == -1 ? 0 : ind;
    let isSingle = false;
    const customData =
      data.FunctionSelection === "Custom rules" ? data.customData : [];
    handleUpperLowerCase(
      fieldIndex,
      data.FunctionSelection,
      data,
      isSingle,
      customData
    );
  };

  const addId = (ruleData) => {
    let finalData = [];
    ruleData.map((rule) => {
      let mystrid = Math.random() * 1234;
      finalData.push({ ...rule, id: mystrid.toString() });
    });
    return finalData;
  };

  const getDetail = () => {
    dispatch(setLoading(true));
    dispatch(getJobSettings(config_jobId))
      .then((response) => {
        const result = response.data.rules;
        setRules(addId(result));
        setFormData({ ...formData, RuleId: result?.length + 1 });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleThirdBack = () => {
    setFormData({});
    setIsEditRule(false);
    setInputVisible2(true);
  };

  const handleFirstNext = () => setCount(2);

  const loadMoreItems = (e) => {
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {
      // add your code here
    }
  };

  // Click on Next button after file import
  const handleSecExcelNext = () => {
    setMultipleRuleCheckData(null);
    setMultipleRuledPreviewData(null);
    localStorage.removeItem("updatedMultipleCheckData");
    localStorage.removeItem("updatedMultiplePreviewData");

    rules.map((item, index) => {
      const isLast = index == rules.length - 1;
      item.status && previewResultOnThirdStep(item);
      if (isLast) {
        setInputVisible2(true);
        setCount(count + 1);
      }
    });
  };

  // add / edit rules
  const handleRuleSubmit = () => {
    const payload = {
      rules: [...rules, formData],
      job_status: "Data Configuration",
    };
    dispatch(setLoading(true));
    dispatch(setParserObject(payload));
    dispatch(saveDataParser(config_jobId, payload))
      .then(async (res) => {
        getDetail();
        setRules(payload.rules);
        dispatch(setLoading(false));
        swal("Rule created", "", "success").then(async (willProceed) => {
          if (willProceed) {
            setRules(payload);
            setInputVisible2(true);
            // handleSecExcelNext();
          }
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // delete rules
  const handleDeleteRule = (ruleId) => {
    const updateRules = [];
    rules.forEach((item) => {
      if (item.RuleId != ruleId) {
        updateRules.push({ ...item, RuleId: updateRules.length + 1 });
      }
    });

    const payload = {
      rules: ruleId ? updateRules : [...rules, formData],
      job_status: "Data Configuration",
    };
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        setRules(updateRules);
        dispatch(setLoading(true));
        dispatch(setParserObject(payload));
        dispatch(saveDataParser(config_jobId, payload))
          .then((res) => {
            getDetail();
            // setRules(updateRules);
            dispatch(setLoading(false));
            swal("Rule Deleted Successfully", "", "success");
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  // edit rules
  const handleConfirmationRule = (item) => {
    setIsEditRule(true);
    swal({
      title: "Are you sure want to Edit?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        setFormData(item);
        setSingleRuleCheckData(null);
        setSingleRuledPreviewData(null);
        setInputVisible2(false);
      }
    });
  };

  const handleUpdateRule = () => {
    const data = rules.map((val) =>
      formData.RuleId == val.RuleId ? formData : val
    );
    const payload = {
      rules: data,
      job_status: "Data Configuration",
    };

    dispatch(setLoading(true));
    dispatch(setParserObject(payload));
    dispatch(saveDataParser(config_jobId, payload))
      .then((res) => {
        getDetail();
        dispatch(setLoading(false));
        swal("Rule Updated", "", "success");
        setInputVisible2(true);
        setIsEditRule(false);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleChange = (e) => {
    const val = e.target.value;
    if (val === 15) {
      setCustom_rule(true);
    } else {
      setCustom_rule(false);
    }
    setFormData({ ...formData, FieldName: [val.field] });
  };
  const handleThirdBackButton = () => {
    localStorage.removeItem("updatedMultipleCheckData");
    localStorage.removeItem("updatedMultiplePreviewData");
    setMultipleRuleCheckData(null);
    setMultipleRuledPreviewData(null);
    setCount(count - 1);
  };

  const handleThirdBackButtonSubmit = () => {
    swal("Data config", "", "success");
    const fdata = {
      groupCheckPreviewData: multipleRuledPreviewData,
      groupCheckData: multipleRuleCheckData,
    };
    dispatch(parsedData(fdata));
    props.setCurrent(props.current + 1);
  };

  // handle functions of rules
  const handleUpperLowerCase = (
    fieldIndex,
    type,
    data,
    isSingle,
    customData
  ) => {
    const functionType = functionsToApply.filter((item) => item.value === type);
    let tempRow = [];
    let tempData = [];

    // Local Storage use for multiple rules aplly on preview
    const updatedMultipleCheckData = JSON.parse(
      localStorage.getItem("updatedMultipleCheckData")
    );
    const updatedMultiplePreviewData = JSON.parse(
      localStorage.getItem("updatedMultiplePreviewData")
    );

    if (isSingle) {
      groupCheckData.map((item, index) => {
        tempRow.push({ ...item, id: index });
        if (item.id === fieldIndex) {
          tempRow.push({
            field: `${data.FieldName[0]}`,
            alias: `${data.FieldName[0]}_${data.AliasFieldName}`,
            group_check: false,
            mandatory: false,
            id: index,
          });
        }
      });
      setSingleRuleCheckData(tempRow);
    } else {
      (updatedMultipleCheckData
        ? updatedMultipleCheckData
        : groupCheckData
      ).map((item, index) => {
        tempRow.push({ ...item, id: index });
        if (index == fieldIndex) {
          tempRow.push({
            field: `${data.FieldName[0]}`,
            alias: `${data.FieldName[0]}_${data.AliasFieldName}`,
            group_check: false,
            mandatory: false,
            id: index,
          });
        }
      });
      localStorage.setItem("updatedMultipleCheckData", JSON.stringify(tempRow));
      setMultipleRuleCheckData(tempRow);
    }

    // upper case logic
    const handleUpperCase = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(
          fieldIndex + 1,
          0,
          arr[fieldIndex]?.toString().toUpperCase()
        );
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // handle custom case
    const handleCustomCase = () => {
      console.log(isSingle, customData);
      if (isSingle) {
        customData?.shift();
      }

      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item, index) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(fieldIndex + 1, 0, customData?.[index]?.[0]);
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // lower case logic
    const handleLowerCase = () => {
      if (isSingle) {
        groupCheckPreviewData.map((item) => {
          const arr = item;
          const arr2 = [...arr];
          arr2.splice(
            fieldIndex + 1,
            0,
            arr[fieldIndex]?.toString().toLowerCase()
          );
          tempData.push(arr2);
        });
        setSingleRuledPreviewData(tempData);
      } else {
        (updatedMultiplePreviewData
          ? updatedMultiplePreviewData
          : groupCheckPreviewData
        ).map((item) => {
          const arr = item;
          const arr2 = [...arr];
          arr2.splice(
            fieldIndex + 1,
            0,
            arr[fieldIndex]?.toString().toLowerCase()
          );
          tempData.push(arr2);
        });
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
        setMultipleRuledPreviewData(tempData);
      }
    };

    // title case logic
    const handleTitleCase = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(
          fieldIndex + 1,
          0,
          arr[fieldIndex]
            .toString()
            ?.toLowerCase()
            .replace(/(?:^|\s)\w/g, function (match) {
              return match.toUpperCase();
            })
        );
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // trim space from right
    const handleTrimSpaceRight = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(
          fieldIndex + 1,
          0,
          arr[fieldIndex]?.toString()?.trimRight()
        );
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // trim space from left
    const handleTrimSpaceLeft = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(fieldIndex + 1, 0, arr[fieldIndex]?.toString().trimLeft());
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // remove first word from string
    function removeFirstWord(str) {
      const indexOfSpace = str?.indexOf(" ");
      return str.substring(indexOfSpace + 1);
    }

    // Discard left characters
    const handleDiscardLeft = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(
          fieldIndex + 1,
          0,
          removeFirstWord(arr[fieldIndex].toString())
        );
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // keep first word from string
    function keepFirstWord(str) {
      const indexOfSpace = str?.indexOf(" ");
      return str.substring(0, indexOfSpace + 1);
    }

    // keep left characters
    const handleKeepLeft = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(fieldIndex + 1, 0, keepFirstWord(arr[fieldIndex]));
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // remove last word from string
    function removeLastWord(str) {
      const lastIndexOfSpace = str.lastIndexOf(" ");
      if (lastIndexOfSpace === -1) {
        return str;
      }
      return str.substring(0, lastIndexOfSpace);
    }

    // Discard right characters
    const handleDiscardRight = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(fieldIndex + 1, 0, removeLastWord(arr[fieldIndex]));
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // keep last word from string
    function KeepLastWord(str) {
      const lastIndexOfSpace = str.lastIndexOf(" ");
      if (lastIndexOfSpace === -1) {
        return str;
      }
      return str.substring(lastIndexOfSpace);
    }

    // keep right characters
    const handleKeepRight = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(fieldIndex + 1, 0, KeepLastWord(arr[fieldIndex]));
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // handle concatenate
    const handleConcatenate = () => {
      // table heading
      let tempRow = [];
      (updatedMultipleCheckData
        ? updatedMultipleCheckData
        : groupCheckData
      ).map((item, index) => {
        tempRow.push({ ...item, id: index });
        if (item.id === fieldIndex) {
          tempRow.push({
            field: `${data.FieldName[0]}`,
            alias: `${data.FieldName.join("_")}_${data.AliasFieldName}`,
            group_check: false,
            mandatory: false,
            id: index,
          });
        }
      });
      isSingle
        ? setSingleRuleCheckData(tempRow)
        : setMultipleRuleCheckData(tempRow);

      // table data
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        const indexes = data.FieldName.map((item) =>
          groupCheckData.findIndex((obj) => obj.alias === item)
        );
        const str = indexes.map((item) => arr[item]);
        // console.log(str.join("-"));

        arr2.splice(fieldIndex + 1, 0, str.join("-"));
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // handle replace string
    const handleReplaceString = () => {
      (updatedMultiplePreviewData
        ? updatedMultiplePreviewData
        : groupCheckPreviewData
      ).map((item) => {
        const arr = item;
        const arr2 = [...arr];
        arr2.splice(
          fieldIndex + 1,
          0,
          arr[fieldIndex]?.replace(data.oldString, data.newString)
        );
        tempData.push(arr2);
      });
      !isSingle &&
        localStorage.setItem(
          "updatedMultiplePreviewData",
          JSON.stringify(tempData)
        );
      isSingle
        ? setSingleRuledPreviewData(tempData)
        : setMultipleRuledPreviewData(tempData);
    };

    // functions calling
    switch (functionType[0]?.label) {
      case "Convert to uppercase":
        return handleUpperCase();

      case "Convert to lowercase":
        return handleLowerCase();

      case "Convert to titlecase":
        return handleTitleCase();

      case "Trim spaces on right":
        return handleTrimSpaceRight();

      case "Trim spaces on left":
        return handleTrimSpaceLeft();

      case "Substring":
        return handleDiscardLeft();

      case "Discard left characters":
        return handleDiscardLeft();

      case "Discard right characters":
        return handleDiscardRight();

      case "Keep left characters":
        return handleKeepLeft();

      case "Keep right characters":
        return handleKeepRight();

      case "Replace string":
        return handleReplaceString();

      case "Concatenate":
        return handleConcatenate();

      default:
        return handleCustomCase();
    }
  };

  const handleSecondBack = () => setCount(1);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "Field1",
    "Field2",
    "Field3",
    "Field4",
    "Field5",
    "Field6",
    "Field7",
    "Field8",
    "Field9",
  ];

  const [personName2, setPersonName2] = useState([]);
  const handleChangefieled2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName2(typeof value === "string" ? value.split(",") : value);
  };

  const components = [
    <>
      {/* original data */}
      <div className="container">
        <div
          className="d-flex mt-3"
          style={{ justifyContent: "space-between" }}
        >
          <h4 className="m-3">
            Original File
            <span
              className="float-end"
              style={{ fontSize: "20px", color: "#e06968" }}
            ></span>
          </h4>
          <button
            className="btn_red"
            style={{ width: "10rem" }}
            onClick={() => handleFirstNext()}
          >
            Next
          </button>
        </div>
        <div className="row table_section shadow">
          <OriginalData
            groupCheckPreviewData={groupCheckPreviewData}
            groupCheckData={groupCheckData}
          />
        </div>
      </div>
    </>,
    <>
      {isInputVisible2 ? (
        <div
          className="col-md-6 col-lg-12 col-sm-12 services_section pt-5 table_section"
          style={{ width: "100%" }}
        >
          <div className="">
            <div className="row table_box">
              <div className={"col-md-8"}>
                <h4>List of Data Rules</h4>
              </div>
              <div className={"col-md-2 -mt-2"}>
                <button
                  className="btn-sm btn-add-field float-end"
                  style={{
                    width: "11rem",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  id="add-edit-corporate"
                  onClick={() => {
                    setFormData({
                      RuleId: rules.length + 1,
                      AliasFieldName: "",
                      RuleName: "",
                      RuleDescription: "",
                      FunctionSelection: "",
                      FieldName: [],
                      oldString: "",
                      newString: "",
                      status: true,
                    });
                    setSingleRuleCheckData(null);
                    setSingleRuledPreviewData(null);
                    toggleInputVisibility2();
                  }}
                >
                  <i className="fa fa-plus" />
                  Add New Rule
                </button>
              </div>
              <div className={"col-md-2 -mt-2"}>
                <button
                  className="btn-sm btn-add-field float-end"
                  id="add-edit-corporate"
                  style={{ width: "11rem" }}
                >
                  <i className="fa fa-cog" />
                  Export Rule
                </button>
              </div>
              <div className="row table_box red_table mt-3">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <table className="table" style={{ fontSize: "13px" }}>
                    <thead>
                      <tr>
                        <th scope="col">Sequence</th>
                        <th scope="col">Rule Id</th>
                        <th scope="col">Rule Name&nbsp;</th>
                        <th scope="col">Fields included &nbsp;</th>
                        <th scope="col">Function Name&nbsp;</th>
                        <th scope="col">Enable/Disable&nbsp;</th>
                        <th scope="col">Action&nbsp;</th>
                      </tr>
                    </thead>
                    {rules && rules?.length > 0 && (
                      <Droppable droppableId="droppable-1">
                        {(provider) => (
                          <tbody
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                          >
                            {rules.map((item, i) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={i}
                              >
                                {(provided) => (
                                  <tr
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                  >
                                    <td {...provided.dragHandleProps}>
                                      {" "}
                                      <Popover
                                        placement="right"
                                        content={"Drag & Drop"}
                                      >
                                        <RiArrowUpDownFill
                                          size={20}
                                          color="blue"
                                        />
                                      </Popover>
                                    </td>
                                    <td className="sr_no">
                                      {item.RuleId ? item.RuleId : "---"}
                                    </td>
                                    <td className="user_id">
                                      {item.RuleName ? item.RuleName : "---"}
                                    </td>
                                    <td className="user_id">
                                      {item.FieldName?.length > 1
                                        ? item.FieldName?.join(", ")
                                        : item.FieldName?.[0]}
                                    </td>
                                    <td className="user_id">
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "1rem",
                                        }}
                                      >
                                        {functionsToApply.map(
                                          (val) =>
                                            val.value ==
                                              item.FunctionSelection &&
                                            val.label
                                        )}

                                        <div>
                                          <div>
                                            {item.FunctionSelection === 11 && (
                                              <>
                                                Previous String :{" "}
                                                {item.oldString} <br />
                                                New String : {item.newString}
                                              </>
                                            )}
                                          </div>
                                          <div>
                                            {item.FunctionSelection ===
                                              "Custom rules" && (
                                              <div>
                                                <Popover
                                                  placement="right"
                                                  content="Show Code"
                                                >
                                                  <Button
                                                    type="primary"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      swal({
                                                        title:
                                                          "Costume Rule's Code",
                                                        text: item.code,
                                                        dangerMode: true,
                                                        showConfirmButton: true,
                                                        confirmButtonText: "Ok",
                                                      }).then(() => {});
                                                    }}
                                                  >
                                                    <FaCode />
                                                  </Button>
                                                </Popover>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="user_id">
                                      <Switch
                                        checked={item.status}
                                        onChange={() =>
                                          handleStatusChange(item.RuleId)
                                        }
                                      />
                                    </td>
                                    <td className="action_btns">
                                      {/* <Link> */}
                                      <i
                                        onClick={() =>
                                          handleConfirmationRule(item)
                                        }
                                        className="fa fa-pencil btn_edit"
                                        style={{ cursor: "pointer" }}
                                      />
                                      {/* </Link> */}
                                      <i
                                        onClick={() =>
                                          handleDeleteRule(item.RuleId)
                                        }
                                        className="fa fa-trash-o btn_delete"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provider.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    )}
                  </table>
                </DragDropContext>
              </div>
            </div>
          </div>

          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={handleSecondBack}>
              Back
            </button>
            <button className="btn_red" onClick={() => handleSecExcelNext()}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="form_box mb-5 text-center">
              <h4 className="">{isEditRule ? "Update" : "Create"} New Rule</h4>
              <p>Your config Id {config_jobId}</p>
              <div className="divider" />
              <div className={"col-md-12"} style={{ marginTop: "4rem" }}>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="d-flex"
                      style={{
                        alignItems: "center",
                        gap: "3rem",
                      }}
                    >
                      <label>Alias Field Name:</label>
                      <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        name="AliasFieldName"
                        value={formData.AliasFieldName}
                        onChange={handleInputChange}
                        style={{ width: "20rem" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div
                      className=" d-flex"
                      style={{
                        alignItems: "center",
                        gap: "2.8rem",
                        // marginTop: "4rem",
                      }}
                    >
                      <label>Rule Name:</label>
                      <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        name="RuleName"
                        value={formData.RuleName}
                        onChange={handleInputChange}
                        style={{ width: "20rem" }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className=" d-flex"
                      style={{
                        alignItems: "center",
                        gap: "3rem",
                        marginTop: "4rem",
                      }}
                    >
                      <label>Rule Description:</label>
                      <TextField
                        id="outlined-required"
                        multiline
                        maxRows={4}
                        label="Rule Description"
                        name="RuleDescription"
                        value={formData.RuleDescription}
                        onChange={handleInputChange}
                        style={{ width: "20rem" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className=" d-flex"
                      style={{
                        alignItems: "center",
                        gap: "2.4rem",
                        marginTop: "4rem",
                      }}
                    >
                      <label>Function Name:</label>
                      <FormControl>
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ paddingLeft: "1.5rem" }}
                        >
                          Select Function Name
                        </InputLabel>
                        <Select
                          style={{
                            width: "20rem",
                            marginLeft: "1rem",
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          MenuProps={{
                            PaperProps: {
                              onScroll: loadMoreItems,
                            },
                            style: {
                              maxHeight: 300,
                            },
                          }}
                          label=" Select Function Name"
                          name="FunctionSelection"
                          value={formData.FunctionSelection}
                          onChange={handleInputChange}
                        >
                          {functionsToApply.map((item) => {
                            return (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {formData?.FunctionSelection === 12 ? (
                    <div className="col-md-6">
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          gap: "2.5rem",
                          marginTop: "4rem",
                        }}
                      >
                        <label>Field Name:</label>
                        <FormControl>
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            // style={{ paddingRight: "-3rem" }}
                          >
                            Select Field Name
                          </InputLabel>
                          <Select
                            style={{ width: "20rem" }}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={formData.FieldName}
                            name="FieldName"
                            onChange={handleFiledNameChange}
                            input={<OutlinedInput label="Select Field Name" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {parsedFinalData.groupCheckData &&
                              parsedFinalData.groupCheckData?.map(
                                (name, id) => (
                                  <MenuItem key={id} value={name["alias"]}>
                                    <Checkbox
                                      checked={formData?.FieldName?.includes(
                                        name["alias"]
                                      )}
                                    />
                                    <ListItemText primary={name["alias"]} />
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          gap: "2.5rem",
                          marginTop: "4rem",
                        }}
                      >
                        <label>Field Name:</label>
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ paddingRight: "-3rem" }}
                          >
                            Select Field Name
                          </InputLabel>
                          <Select
                            style={{ width: "20rem" }}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={formData.FieldName}
                            name="FieldName"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                FieldName: [e.target.value],
                              })
                            }
                            input={<OutlinedInput label="Select Field Name" />}
                            MenuProps={MenuProps}
                          >
                            {parsedFinalData.groupCheckData &&
                              parsedFinalData.groupCheckData?.map(
                                (name, id) => (
                                  <MenuItem key={id} value={name["alias"]}>
                                    <ListItemText primary={name["alias"]} />
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  {formData?.FunctionSelection === 11 && (
                    <div className="d-flex row">
                      <div
                        className="col-md-6 d-flex"
                        style={{
                          alignItems: "center",
                          gap: "3.5rem",
                          marginTop: "4rem",
                        }}
                      >
                        <label>Replace String:</label>
                        <TextField
                          required
                          id="outlined-required"
                          multiline
                          maxRows={4}
                          label="String to be replace"
                          name="oldString"
                          value={formData.oldString}
                          onChange={handleInputChange}
                          style={{ width: "20rem" }}
                        />
                      </div>
                      <div
                        className="col-md-6 d-flex"
                        style={{
                          alignItems: "center",
                          gap: "9rem",
                          marginTop: "4rem",
                        }}
                      >
                        <label></label>
                        <TextField
                          required
                          id="outlined-required"
                          multiline
                          maxRows={4}
                          label="Old String Replace with new"
                          name="newString"
                          value={formData.newString}
                          onChange={handleInputChange}
                          style={{ width: "20rem" }}
                        />
                      </div>
                    </div>
                  )}

                  {formData.FunctionSelection === "Custom rules" && (
                    <div className="py-5">
                      <Code
                        // onChildExcelValue={formData.FieldName}
                        filterDataToPython={filterDataToPython}
                        excelValue={excelValue}
                        previewResult={(val) => {
                          setFormData({ ...formData, customData: val });
                          handleUpperLowerCase(
                            filterDataToPython.index,
                            formData.FunctionSelection,
                            formData,
                            true,
                            val
                          );
                          setIsPreview(true);
                        }}
                        handleInputChange={(val) =>
                          setFormData({ ...formData, code: val })
                        }
                      />
                    </div>
                  )}

                  <div>
                    {inputs.map((input, index) => (
                      <>
                        <div className="row" key={index}>
                          <div className="col-md-6">
                            <div
                              className=" d-flex"
                              style={{
                                alignItems: "center",
                                gap: "3.3rem",
                                marginTop: "4rem",
                              }}
                            >
                              <label>Function Name:</label>
                              <Select
                                style={{ width: "20rem" }}
                                onChange={handleChange}
                              >
                                {/* {parsedFinalData.groupCheckData &&
                                  parsedFinalData.groupCheckData?.map((val, idx) => (
                                    <MenuItem value={val}>
                                      <em>{val}</em>
                                    </MenuItem>
                                  ))} */}
                                {/* <MenuItem value={10}>Lower case</MenuItem>
                                <MenuItem value={20}>Upper case</MenuItem>
                                <MenuItem value={30}>Custom</MenuItem> */}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className=" d-flex"
                              style={{
                                alignItems: "center",
                                gap: "2.5rem",
                                marginTop: "4rem",
                              }}
                            >
                              <label>Field Name:</label>
                              <Select
                                style={{ width: "20rem" }}
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={personName2}
                                onChange={handleChangefieled2}
                                input={<OutlinedInput />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {names.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    <Checkbox
                                      checked={personName2.indexOf(name) > -1}
                                    />
                                    <ListItemText primary={name} />
                                  </MenuItem>
                                ))}
                              </Select>
                              <Tooltip title="Delete">
                                <span>
                                  <MdDeleteForever
                                    size={20}
                                    style={{ cursor: "pointer" }}
                                    color="red"
                                    onClick={() => handleRuleDelete(index)}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="col-md-6 mt-5 d-flex"
                    style={{
                      alignItems: "center",
                      gap: "3rem",
                    }}
                  >
                    <label>Add more Rules :</label>
                    <Button
                      variant="contained"
                      onClick={AddMoreRules}
                      style={{ width: "52%" }}
                    >
                      Add more Rules
                    </Button>
                  </div>
                </div>

                <div
                  className="form-group col-md-12 text-right btn_group_section"
                  style={{ marginTop: "5rem" }}
                >
                  <button
                    className="btn_back"
                    onClick={() => handleThirdBack()}
                  >
                    Back
                  </button>
                  <button className="btn_preview" onClick={previewResult}>
                    Preview
                  </button>
                  <button
                    className="btn_red"
                    onClick={isEditRule ? handleUpdateRule : handleRuleSubmit}
                  >
                    {isEditRule ? "Update" : "Apply"}
                  </button>
                </div>
                <div className="row table_section">
                  {isPreview && (
                    <OriginalData
                      groupCheckPreviewData={
                        singleRuledPreviewData
                          ? singleRuledPreviewData
                          : groupCheckPreviewData
                      }
                      groupCheckData={
                        singleRuleCheckData
                          ? singleRuleCheckData
                          : groupCheckData
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>,
    <>
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">
            Preview Results
            <span
              className="float-end"
              style={{ fontSize: "20px", color: "#e06968" }}
            ></span>
          </h4>
          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button
              className="btn_back"
              onClick={() => handleThirdBackButton()}
            >
              Back
            </button>
            {/* <button className="btn_preview">Preview</button> */}
            <button
              className="btn_red"
              onClick={() => handleThirdBackButtonSubmit()}
            >
              Submit
            </button>
          </div>

          <div className="row table_box red_table mt-3">
            <OriginalData
              groupCheckPreviewData={multipleRuledPreviewData}
              groupCheckData={multipleRuleCheckData}
            />
          </div>
        </div>
      </div>
    </>,
  ];

  const filterData = (value) => {
    const ind = excelValue.sheetData.Sheet1[0].findIndex((item, index) => {
      if (value.includes(item)) {
        return index;
      }
    });
    const fieldIndex = ind == -1 ? 0 : ind;
    const arr = excelValue.sheetData.Sheet1.map((item) => {
      return [item[fieldIndex]];
    });
    setFilterDataToPython({ index: fieldIndex, value: arr });
  };

  useEffect(() => {
    if (excelValue && formData.FieldName) {
      filterData(formData.FieldName);
    }
  }, [formData.FieldName]);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <Link to="/dashboard"></Link>
            </div>
            <div className="container">
              <Stepper
                className={"w-100 p-0 pb-3 mt-2"}
                steps={[
                  { label: "Original File" },
                  { label: "DataRules" },
                  { label: "Preview Results" },
                ]}
                activeStep={count - 1}
                stepClassName={"stepper__step"}
              />
            </div>
          </div>
        </div>
      </div>
      {components[count - 1]}
    </div>
  );
}
