import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import "../.././index.css";
import * as XLSX from "xlsx";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import "react-select-search/style.css";
import {
  setLoading,
  // saveDataParser,
  getAllJob,
  getJobLog,
  setExcelObject,
  setParserObject,
} from "../../redux/actions/other";
import { MandatoryGroupSheetTable } from "../../components/DataParser/MandatoryGroupSheetTable";
import { SheetTable } from "../../components/DataParser/SheetTable";
import { JobLogTable } from "../../components/DataParser/JobLogTable";
import { GroupCheckTable } from "../../components/DataParser/GroupCheckTable";
import swal from "sweetalert";
// import Test from "../../components/DataConfig/test";
import SelectSearch from "react-select-search";

XLSX = require("xlsx");
const acceptableFileName = ["xlsx", "xls", "csv"];

export default function DataParser() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [fileName, setFileName] = useState(null);
  const [sheetNames, setSheetNames] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobLogs, setJobLogs] = useState([]);
  const [isPreviewSettings, setIsPreviewSettings] = useState({
    isAutoDetectPreview: false,
    previewRows: 5,
    isBlankRow: false,
    isSpecifyRange: false,
    isFirstRowHeader: false,
    specifyRange: "",
  });
  const [count, setCount] = useState(1);
  const [firstUpdatedSheet, setFirstUpdatedSheet] = useState();
  const [delimiterCSV, setDelimiterCSV] = useState();
  const [fillSelected, setFillSelected] = useState(false);

  // 3rd screen
  const [delimited, setDelimited] = useState(",");
  const [customDelimiterField, setCustomDelimiterField] = useState(false);
  const [customDelimiter, setCustomDelimiter] = useState("");
  const [selectedJob, setSelectedJob] = useState({});
  const [groupCheckData, setGroupCheckData] = useState([]);
  const [groupCheckPreviewData, setGroupCheckPreviewData] = useState([]);
  const [finalParam, setFinalParam] = useState({
    optional_fields: [],
    required_fields: [],
    alias_name: {},
    group_check: [],
    rules: {},
    corporate: null,
    file_prefix: null,
    is_active: true,
    version: null,
    job: null,
  });

  useEffect(() => {
    allJobList();
    getJobStatus();
  }, []);

  // Get all job list
  const allJobList = () => {
    dispatch(setLoading(true));
    dispatch(getAllJob())
      .then((response) => {
        console.log(response);
        setJobs(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // Get job status (Log)
  const getJobStatus = (id) => {
    dispatch(setLoading(true));
    dispatch(getJobLog(id))
      .then((response) => {
        console.log("response", response);
        setJobLogs(response["data"]);
        setCount(5);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  /*-------------------File selection screen start-------------------*/

  // Handle job selection
  const handleJobChange = (job_id) => {
    if (job_id) {
      let data = jobs.find((job) => Number(job.id) === Number(job_id));
      setSelectedJob(data);
      setFillSelected(true);
      setFinalParam((prev) => ({
        ...prev,
        corporate: data["corporate"],
        file_prefix: data["file_mask"],
        is_active: data["status"],
        version: data["active_version_no"],
        job: data["id"],
      }));
    } else {
      setSelectedJob({});
      setFillSelected(false);
      setFinalParam((prev) => ({
        ...prev,
        corporate: null,
        file_prefix: null,
        is_active: true,
        version: null,
        job: null,
      }));
    }
  };
  const modilfyjobs = (jobdata) => {
    const mydataJob = jobdata.map((item_job) => {
      return {
        name: item_job.job_name,
        value: item_job.id,
      };
    });
    return mydataJob;
  };
  // file extension
  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  // Handle file after select
  const handleFile = async (e) => {
    const myFile = e.target.files[0];
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      alert("Invalid file type");
      return;
    }
    setFileName(myFile.name);
    // Read the xlxs meta data
    const data = await myFile.arrayBuffer();
    setTempData(data);
  };

  // Click on Next button after file selection
  const handleFirstNext = () => {
    if (!fillSelected) {
      alert("Please select Job");
    } else if (fileName == null) {
      alert("Please select file");
    } else {
      setCount(2);
    }
  };

  /*-------------------Type detection selection screen start-------------------*/

  // automatic field type detection (Checkbox)
  const handleAutoFieldTypeDetect = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isAutoDetectPreview: !isPreviewSettings?.isAutoDetectPreview,
    }));
  };

  // Handle Previews (Rows)
  const handlePreviewRows = (e) => {
    if (e.target.value <= 0) {
      alert("You cant set preview rows less than 0");
    } else {
      setIsPreviewSettings((prevState) => ({
        ...prevState,
        previewRows: e.target.value,
      }));
    }
  };

  // Handle Blank rows (Checkbox)
  const handleBlankRows = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isBlankRow: !isPreviewSettings.isBlankRow,
    }));
  };

  // Handle First row header (Checkbox)
  const handleFirstRowHeader = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isFirstRowHeader: !isPreviewSettings.isFirstRowHeader,
    }));
  };

  // Handle Specify Range (Checkbox)
  const handleSpecifyRange = () => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      isSpecifyRange: !isPreviewSettings.isSpecifyRange,
    }));
  };

  // Handle Specify Range input (Range text box)
  const handleSpecifyRangeInput = (e) => {
    setIsPreviewSettings((prevState) => ({
      ...prevState,
      specifyRange: e.target.value,
    }));
  };

  const readDataFromExcel1 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};
    let dummyHeading = [];

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, {
        blankrows: !isPreviewSettings?.isBlankRow,
        header: 1,
        defval: "",
        skipHeader: true,
        range: isPreviewSettings.isSpecifyRange
          ? isPreviewSettings.specifyRange !== ""
            ? isPreviewSettings.specifyRange
            : null
          : null,
      });

      for (let i = 0; i < jsonData[0]?.length; i++) {
        dummyHeading.push("Field" + (i + 1));
      }

      if (isPreviewSettings.isFirstRowHeader) {
        jsonData = jsonData.slice(0);
      } else {
        jsonData = [dummyHeading].concat(jsonData);
      }

      const firstSheetData = (mySheetData[sheetName] = jsonData);
      console.log("firstSheetData", jsonData, dummyHeading);
      setFirstUpdatedSheet(firstSheetData);
    }
    return mySheetData;
  };

  const handleSecondData = () => {
    let mySheetDataFinal = readDataFromExcel1(tempData);
    if (isPreviewSettings.isBlankRow) {
      //asuming excel sheet have atleastt 1 sheet
      let sheet1Name = Object.keys(mySheetDataFinal)[0]; //['sheet1', 'sheet2', ....]
      mySheetDataFinal[sheet1Name] = mySheetDataFinal[sheet1Name]?.filter(
        (item) => !item.includes("")
      );
    }

    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);

    //store sheet data in redux store
    dispatch(
      setExcelObject({
        sheetData: mySheetDataFinal,
        sheet: Object.keys(mySheetDataFinal)[0],
      })
    );
  };

  const handleSecondPreview = () => {
    handleSecondData();
    setIsPreview(true);
  };

  const handleSecondNext = () => {
    handleSecondData();
    setCount(3);
  };

  const handleSecondBack = () => {
    setCount(1);
  };

  /*-------------------Delimiter selection screen start-------------------*/

  // Handle delimiter change (Select box)
  const handleChangeDelimiter = (event) => {
    setCustomDelimiter("");
    setDelimited(event.target.value);
    if (event.target.value === "custom") {
      setCustomDelimiterField(true);
    } else {
      setCustomDelimiterField(false);
    }
    if (event.target.value && delimiterCSV) {
      handleThirdData();
    }
  };

  // Handle delimiter change (Text box)
  const handleChangeCustomDelimiter = (event) => {
    setDelimited("");
    setCustomDelimiter(event.target.value);
    if (event.target.value && delimiterCSV) {
      handleThirdData();
    }
  };

  const readDataFromExcel2 = (data) => {
    const wb = XLSX.read(data);
    setSheetNames(wb.SheetNames);
    const mySheetData = {};

    // Loop through the sheets
    for (let i = 0; i < wb?.SheetNames?.length; i++) {
      let sheetName = wb.SheetNames[i];
      const worksheet = wb.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      const thirdSheetData = (mySheetData[sheetName] = jsonData);
    }
    return mySheetData;
  };

  // Create default array for Last group check screen
  const setHeaderAlias = (data) => {
    let tempMandatory = groupCheckData;
    data[sheet][0].map((item, index) => {
      if (groupCheckData.some((data) => data.id === index)) {
        const temp = groupCheckData.findIndex((p) => p.id === index);
        groupCheckData[temp].field = item;
      } else {
        tempMandatory.push({
          id: index,
          field: item,
          alias: item,
          mandatory: false,
          group_check: false,
        });
      }
    });
    setGroupCheckData(tempMandatory);
  };

  const CSVToJSON = (data, delimiter) => {
    delimiter = customDelimiter ? customDelimiter : delimited;
    return data
      .slice(data.indexOf("\n") + 1)
      .split("\n")
      .map((item) => {
        return item.split(delimiter);
      });
  };

  const handleThirdData = () => {
    const secoundSheetData = XLSX.utils.json_to_sheet(firstUpdatedSheet);
    const csvData = XLSX.utils.sheet_to_csv(secoundSheetData, {
      header: false,
      FS: customDelimiter ? customDelimiter : delimited,
    });

    const CsvData = CSVToJSON(
      csvData,
      customDelimiter ? customDelimiter : delimited
    );

    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(CsvData);

    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: "base64" });
    setDelimiterCSV(rawExcel);

    const mySheetDataFinal = readDataFromExcel2(rawExcel);
    if (mySheetDataFinal) {
      setSheet(Object.keys(mySheetDataFinal)[0]);
    }
    setSheetData(mySheetDataFinal);
    setHeaderAlias(mySheetDataFinal);
  };

  const handleThirdPreview = () => {
    handleThirdData();
    setIsPreview(true);
  };

  const handleThirdNext = () => {
    handleThirdData();
    setCount(4);
  };

  const handleThirdBack = () => {
    setCount(2);
  };

  /*-------------------Group check screen start-------------------*/

  // Manage Last screen Group check section
  const manageMandatory = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    console.log(groupCheckData);
    groupCheckData[temp].mandatory = e.target.checked;
  };

  const manageGroupCheck = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].group_check = e.target.checked;
  };

  const manageAlias = (e, i) => {
    const temp = groupCheckData.findIndex((p) => p.id === i);
    groupCheckData[temp].alias = e.target.value;
  };

  const handleFourthData = () => {
    let required = [];
    let optional = [];
    let group_check = [];
    let alias_name = {};
    groupCheckData.map((item) => {
      Object.assign(alias_name, { [item["field"]]: item["alias"] });

      if (item["mandatory"]) {
        required.push(item["field"]);
      } else {
        optional.push(item["field"]);
      }

      if (item["group_check"]) {
        group_check.push(item["field"]);
      }
    });

    setFinalParam((prev) => ({
      ...prev,
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    }));

    handleGroupCheckPreview(required, group_check);

    return {
      optional_fields: optional,
      required_fields: required,
      alias_name: alias_name,
      group_check: group_check,
    };
  };

  const handleGroupCheckPreview = (
    mandatory_item = ["Color", "Item Size"],
    group_check_item = ["Style Number", "SKU"]
  ) => {
    let excel_data = sheetData[sheet];
    let need_to_remove_items = [];
    console.log("data", excel_data);

    if (group_check_item.length > 0) {
      group_check_item.map((item) => {
        let group_check_index = excel_data[0].indexOf(item);
        console.log("data", group_check_index);

        mandatory_item.map((item) => {
          let mandatory_index = excel_data[0].indexOf(item);
          let temp = getNeedToRemoveItems(
            excel_data,
            mandatory_index,
            group_check_index
          );
          need_to_remove_items = [...need_to_remove_items, ...temp];
        });
      });

      console.log("need_to_remove_items", need_to_remove_items);

      need_to_remove_items.map((item) => {
        excel_data = removeGroupCheck(excel_data, item["key"], item["value"]);
      });

      setGroupCheckPreviewData(excel_data);
      console.log("group_check_item", excel_data);
    } else if (mandatory_item.length > 0) {
      mandatory_item.map((item) => {
        let mandatory_index = excel_data[0].indexOf(item);
        excel_data = removeMandatory(excel_data, mandatory_index);
      });

      setGroupCheckPreviewData(excel_data);
      console.log("mandatory_item", excel_data);
    } else {
      setGroupCheckPreviewData(excel_data);
    }
  };

  const handleFourthBack = () => {
    setCount(3);
  };

  const handleFourthPreview = () => {
    handleFourthData();
    setIsPreview(true);
  };
  const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
  ];
  const handleConfirmModal = () => {
    let fourthData = handleFourthData();
    let param = finalParam;
    param["optional_fields"] = fourthData["optional_fields"];
    param["required_fields"] = fourthData["required_fields"];
    param["alias_name"] = fourthData["alias_name"];
    param["group_check"] = fourthData["group_check"];

    console.log("FinalParam===>>>", JSON.stringify(param));
    // console.log('FinalParam===>>>', param);

    dispatch(setLoading(true));

    dispatch(setParserObject(param));

    swal("Parser data saved", "", "success");

    history("/config/data-config");

    /*dispatch(saveDataParser(finalParam))
            .then(() => {
                getJobStatus(selectedJob['id']);
            })
            .catch(() => {
                dispatch(setLoading(false));
            });*/
  };

  const removeMandatory = (data, index) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== "") {
        temp.push(item);
      }
    });
    return temp;
  };

  const getNeedToRemoveItems = (data, mandatory_index, group_check_index) => {
    let temp = [];
    data.map((item) => {
      if (item[mandatory_index] === "") {
        temp.push({ key: group_check_index, value: item[group_check_index] });
      }
    });
    return temp;
  };

  const removeGroupCheck = (data, index, value) => {
    let temp = [];
    data.map((item) => {
      if (item[index] !== value) {
        temp.push(item);
      }
    });
    return temp;
  };

  const components = [
    <>
      {/* <Test /> */}
      {/*File selection*/}
      <div className="container">
        <div className="row table_section shadow">
          <div className="col-lg-6 col-sm-6 col-md-12">
            <div
              className="form-group"
              style={{ backgroundColor: "#fff", color: "#000" }}
            >
              <SelectSearch
                options={modilfyjobs(jobs)}
                value={selectedJob["id"]}
                name="jobs"
                onChange={(e) => {
                  handleJobChange(e);
                }}
                search={true}
                placeholder="Select Job"
              />

              {/* <select
                className="form-control"
                id="exampleFormControlSelect1"
                value={selectedJob["id"]}
                type={"search"}
                onChange={(e) => {
                  handleJobChange(e.target.value);
                }}
              >
                <option value={""}>Select Job</option>
                {jobs &&
                  jobs.map((job, idx) => (
                    <option key={idx} value={job.id}>
                      {job.job_name}
                    </option>
                  ))}
              </select> */}
            </div>
          </div>

          <div className="upload-btn-wrapper">
            <label className="btn d-flex justify-content-center align-items-center">
              <span>
                <i className="fa fa-upload" />
                <br />
                {fileName ? (
                  <h4>FileName: {fileName}</h4>
                ) : (
                  <h4>Browse files</h4>
                )}
              </span>
              <input
                type="file"
                name="myfile"
                accept="xlsx, xls"
                style={{ display: "none" }}
                multiple={false}
                onChange={handleFile}
              />
            </label>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <p className="float-left">Choose only Excel, CSV </p>
            <button
              className="float-right btn_red btn_next"
              onClick={() => handleFirstNext()}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </div>
    </>,
    <>
      {/*Type detection*/}
      <div className="container">
        <div className="row table_section shadow">
          <div className="form_box mb-4">
            <h6>Specify how data is detected from the Excel Spreadsheet</h6>
            <div className="divider" />
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Use automatic field type detection (if unchecked all fields will be read as text)"
                  onChange={handleAutoFieldTypeDetect}
                  checked={isPreviewSettings.isAutoDetectPreview}
                />

                <div>
                  <span>Preview</span>
                  <input
                    type="number"
                    value={isPreviewSettings?.previewRows}
                    style={{ margin: "0px 10px" }}
                    onChange={handlePreviewRows}
                  />
                  records for automatic filed type detection
                </div>
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="First row has field names"
                  onChange={handleFirstRowHeader}
                  checked={
                    isPreviewSettings.isFirstRowHeader &&
                    isPreviewSettings.isFirstRowHeader
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ignore empty records"
                  onChange={handleBlankRows}
                  checked={
                    isPreviewSettings.isBlankRow && isPreviewSettings.isBlankRow
                  }
                />
              </FormGroup>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Specify range"
                    onChange={handleSpecifyRange}
                    checked={isPreviewSettings.isSpecifyRange}
                  />
                </FormGroup>
                <input
                  type="text"
                  style={{ margin: "0px 10px" }}
                  value={isPreviewSettings.specifyRange}
                  onChange={handleSpecifyRangeInput}
                  placeholder="A3:C29"
                />
                <span style={{ color: "grey" }}>(Example A3:C29)</span>
              </div>
              <div className="form-group col-md-12 text-right btn_group_section">
                <button className="btn_back" onClick={() => handleSecondBack()}>
                  Back
                </button>
                <button
                  className="btn_preview"
                  onClick={() => handleSecondPreview()}
                >
                  Preview
                </button>
                <button className="btn_red" onClick={() => handleSecondNext()}>
                  Next
                </button>
              </div>
            </div>
          </div>
          {isPreview && (
            <SheetTable
              sheetData={sheetData}
              sheet={sheet}
              isPreviewSettings={isPreviewSettings}
            />
          )}
        </div>
      </div>
    </>,
    <>
      {/*Delimiter*/}
      <div className="container">
        <div className="row table_section shadow">
          <div className="form_box mb-4">
            <h6>Specify how data is detected from the Excel Spreadsheet</h6>
            <div className="divider" />
            <div className={"col-md-12"}>
              <div className={"row"}>
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="form-group">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="delimited">Delimited Fields</InputLabel>
                      <Select
                        labelId="delimited"
                        id="delimited"
                        value={delimited}
                        label="Delimited Fields"
                        onChange={handleChangeDelimiter}
                        style={{ width: "300px", textAlign: "left" }}
                      >
                        <MenuItem style={{ textAlign: "left" }} value="&nbsp">
                          Tab
                        </MenuItem>
                        <MenuItem style={{ textAlign: "left" }} value=",">
                          Comma
                        </MenuItem>
                        <MenuItem style={{ textAlign: "left" }} value=";">
                          Semi Colon
                        </MenuItem>
                        <MenuItem style={{ textAlign: "left" }} value=" ">
                          Space
                        </MenuItem>
                        <MenuItem style={{ textAlign: "left" }} value="custom">
                          Custom
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="form-group">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="encoding">Encoding Support</InputLabel>
                      <Select
                        labelId="encoding"
                        id="encoding"
                        value={"utf8"}
                        label="Encoding Support"
                        style={{ width: "300px" }}
                      >
                        <MenuItem value={"utf8"}>UTF8</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {customDelimiterField && (
                  <div className="col-lg-6 col-sm-6 col-md-6">
                    <div className="form-group">
                      <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                        <TextField
                          id="outlined-basic"
                          label="Custom Expression"
                          variant="outlined"
                          onChange={handleChangeCustomDelimiter}
                          value={customDelimiter}
                        />
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="form-group col-md-12 text-right btn_group_section">
                  <button
                    className="btn_back"
                    onClick={() => handleThirdBack()}
                  >
                    Back
                  </button>
                  <button
                    className="btn_preview"
                    onClick={() => handleThirdPreview()}
                  >
                    Preview
                  </button>
                  <button className="btn_red" onClick={() => handleThirdNext()}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isPreview && (
            <SheetTable
              sheetData={sheetData}
              sheet={sheet}
              isPreviewSettings={isPreviewSettings}
            />
          )}
        </div>
      </div>
    </>,
    <>
      {/*Group check*/}
      <div className="container">
        <div className="row table_section shadow">
          <h4 className="mt-3">
            Mandatory Group Check
            <span
              className="float-end"
              style={{ fontSize: "20px", color: "#e06968" }}
            >
              You have select {selectedJob["job_id_no"]}
            </span>
          </h4>
          <MandatoryGroupSheetTable
            onclickMandatory={(e, i) => manageMandatory(e, i)}
            onClickGroupCheck={(e, i) => manageGroupCheck(e, i)}
            onTypeAlias={(e, i) => manageAlias(e, i)}
            groupCheckData={groupCheckData}
            sheetData={sheetData}
            sheet={sheet}
            isPreviewSettings={isPreviewSettings}
          />
          <div className="form-group col-md-12 text-right btn_group_section mb-4">
            <button className="btn_back" onClick={() => handleFourthBack()}>
              Back
            </button>
            <button
              className="btn_preview"
              onClick={() => handleFourthPreview()}
            >
              Preview
            </button>
            <button className="btn_red" onClick={() => handleConfirmModal()}>
              Submit
            </button>
          </div>
          {isPreview && (
            <GroupCheckTable
              groupCheckPreviewData={groupCheckPreviewData}
              groupCheckData={groupCheckData}
            />
          )}
        </div>
      </div>
    </>,
    <>
      {/*Job log*/}
      <div className="container">
        <div className="row table_section shadow">
          <JobLogTable jobLogs={jobLogs} />
        </div>
        <div className="form-group col-md-12 text-right">
          <button
            className="btn_red"
            onClick={() => history("/config/data-config")}
          >
            Finish
          </button>
        </div>
      </div>
    </>,
  ];

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">Data Parser</h1>
              <Link
                style={{ textDecoration: "none" }}
                className="float-right btn_dashboard"
                to="/dashboard"
              >
                <i className="fa fa-home" />
                Go To Dashboard
              </Link>
            </div>
            <div className="container">
              <Stepper
                className={"w-100 p-0 pb-3"}
                steps={[
                  { label: "Start" },
                  { label: "Data Selection" },
                  { label: "Detect Delimiter" },
                  { label: "Edit Alias" },
                  { label: "Data Parser" },
                ]}
                activeStep={count - 1}
              />
            </div>
          </div>
        </div>
      </div>
      {components[count - 1]}
    </div>
  );
}
