import {
  LOADING,
  EXCEL,
  PARSE,
  SET_CONFIG_ID,
  PARSEDDATA,
} from "../actions/types";

// const initialState = {isLoading: false, excelValue: excel, parserValue: parser};
const initialState = {
  isLoading: false,
  excelValue: null,
  parserValue: null,
  submittedId: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return { ...state, isLoading: payload };
    case EXCEL:
      return { ...state, excelValue: payload };
    case PARSE:
      return { ...state, parserValue: payload };
    case SET_CONFIG_ID:
      return {
        ...state,
        configId: action.payload,
      };
    case PARSEDDATA:
      return {
        ...state,
        parsedData: action.payload,
      };
    default:
      return state;
  }
}
