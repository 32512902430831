import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  setLoading,
  getCorporateDetail,
  updateCorporate,
  addCorporate,
  getAllCompany,
} from "../../../redux/actions/other";

const CorporateCreate = ({ editId, open, handleClose, handleRefresh }) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState();
  const corporateInitialValue = {
    name: "",
    brand: "",
    email: "",
    no_pods: "",
    company: "",
  };
  const [initialValue, setInitialValue] = useState(corporateInitialValue);
  const corporateSchema = Yup.object().shape({
    name: Yup.string().required("Corporate name is required."),
    email: Yup.string()
      .email("Invalid email address format")
      .required("The Email is required."),
    company: Yup.string().required("The Company is required. "),
    brand: Yup.string().required("The brand is required."),
    no_pods: Yup.string().required("The No of Pods is required."),
  });

  useEffect(() => {
    setInitialValue(corporateInitialValue);
    companyList(editId);
  }, [editId]);

  const companyList = (id = null) => {
    dispatch(setLoading(true));
    dispatch(getAllCompany())
      .then((response) => {
        setCompanies(response["data"]);
        dispatch(setLoading(false));

        if (id != null) {
          corporateDetail(id);
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const corporateDetail = (id) => {
    dispatch(setLoading(true));
    dispatch(getCorporateDetail(id))
      .then((response) => {
        const result = response["data"];
        setInitialValue({
          name: result["name"],
          brand: result["brand"],
          email: result["email"],
          no_pods: result["no_pods"],
          company: result["company"]["id"],
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const closeModal = () => {
    setInitialValue(corporateInitialValue);
    handleClose();
  };

  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    if (editId) {
      dispatch(updateCorporate(editId, values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(addCorporate(values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };

  return (
    <div
      className={`modal fade ${open}`}
      id="editData"
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <Formik
          initialValues={initialValue}
          validationSchema={corporateSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, resetForm, handleReset }) => (
            <Form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fw-bold"
                    style={{ color: "#2565ab" }}
                  >
                    {editId ? "Update" : "Add"} Detail
                  </h5>
                  <div
                    onClick={closeModal}
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="modal_form row">
                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="name">
                        {" "}
                        Corporate Customer (Subset to companies){" "}
                      </label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_building.png"}
                        alt=""
                      />
                      <Field
                        name="name"
                        placeholder="Enter Name"
                        className={`form-control ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="company">Company</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_building.png"}
                        alt=""
                      />
                      <Field
                        as="select"
                        name="company"
                        className={`form-control ${
                          touched.company && errors.company ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select company</option>
                        {companies &&
                          companies.map((company, idx) => (
                            <option key={idx} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="company"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="info">Brand</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_brand.png"}
                        alt=""
                      />
                      <Field
                        name="brand"
                        placeholder="Info"
                        className={`form-control ${
                          touched.brand && errors.brand ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="brand"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="email2">POC Email</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_mail.png"}
                        alt=""
                      />
                      <Field
                        name="email"
                        placeholder="Enter Email"
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="">No. of PODs</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_setting.png"
                        alt=""
                      />
                      <Field
                        type="number"
                        name="no_pods"
                        min="0"
                        className={`form-control ${
                          touched.no_pods && errors.no_pods ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="no_pods"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary blue_button">
                    {editId ? "Update" : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CorporateCreate;
