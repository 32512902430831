import { Link } from "react-router-dom";
import { Stepper } from "react-form-stepper";
import "../.././index.css";
import { useDispatch } from "react-redux";
import { TextField, Select, Button, FormControl } from "@mui/material";
import "react-select-search/style.css";
import {
  setLoading,
  // saveDataParser,
  getAllJob,
} from "../../redux/actions/other";
import { useState, useEffect } from "react";

export default function OutputTabs(props) {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [count, setCount] = useState(1);
  const [isInputVisibletab, setInputVisibletab] = useState(false);

  const toggleInputVisibilitytab = () => {
    setInputVisibletab(!isInputVisibletab);
  };

  useEffect(() => {
    allJobList();
  }, []);

  // Get all job list
  const allJobList = () => {
    dispatch(setLoading(true));
    dispatch(getAllJob())
      .then((response) => {
        setJobs(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleFirstNext = () => {
    setCount(2);
  };

  const handleSecondBack = () => {
    setCount(1);
  };

  const components = [
    <>
      {/* <Test /> */}
      <div>
        <div className="container">
          <div className="row table_section shadow">
            <div className="form_box mb-4">
              <h6>Decide on the output type</h6>
              <div className="divider" />
              <div className="d-flex flex-column">
                <button className="btn_back">JSON</button>
                <button className="btn_back" onClick={toggleInputVisibilitytab}>
                  Table
                </button>
                {isInputVisibletab && (
                  <div className="col-lg-12 col-sm-6 col-md-6">
                    <div className="form-group">
                      <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                        <label>Enter Table name</label>

                        <TextField
                          id="outlined-basic"
                          size="small"
                          // label="Custom Expression"
                          placeholder="Enter table name"
                          variant="outlined"
                          //   onChange={handleChangeCustomDelimiter}
                          // value={customDelimiter}
                        />
                      </FormControl>
                    </div>
                    <div className="form-group">
                      <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                        <label>Table Updates Rules</label>
                        <div className="col-md-12">
                          <label>Full Replace :</label>
                          <Button
                            type="primary"
                            className="ml-2"
                            //   onClick={OnSubmit}
                            //   icon={<SearchOutlined />}
                            style={{ marginBottom: ".5rem", width: "12rem" }}
                          >
                            Search
                          </Button>
                        </div>
                        <div className="col-md-12">
                          <label>Fields :</label>
                          <Select
                            defaultValue="lucy"
                            //   mode="multiple"
                            style={{
                              width: 200,
                              marginLeft: "3rem",
                            }}
                            //   onChange={handleChange}
                            options={[
                              {
                                value: "jack",
                                label: "Field1",
                              },
                              {
                                value: "lucy",
                                label: "Field2",
                              },
                              {
                                value: "Yiminghe",
                                label: "Field3",
                              },
                            ]}
                          />
                        </div>
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="form-group col-md-12 text-right btn_group_section mb-4">
                  <button className="btn_preview">Preview</button>
                  <button className="btn_red" onClick={() => handleFirstNext()}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    <div className="container">
      <div className="row table_section shadow">
        <div className="form_box mb-4">
          <h6>Save Jobs</h6>
          <div className="divider" />
        </div>
        <div className=" d-flex justify-content-evenly  ">
          <button className="btn_back" style={{ width: "25rem" }}>
            Save as a Draft
          </button>
          <button className="btn_preview" style={{ width: "25rem" }}>
            Deploy to Dev
          </button>
        </div>
      </div>
      <div className="form-group col-md-12 text-right btn_group_section mb-4">
        <button className="btn_back" onClick={() => handleSecondBack()}>
          Back
        </button>
      </div>
    </div>,
  ];

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <Link to="/dashboard"></Link>
            </div>
            <div className="container">
              <Stepper
                className={"w-100 p-0 pb-3 mt-2"}
                steps={[{ label: "Output type" }, { label: "Save job" }]}
                activeStep={count - 1}
                stepClassName={"stepper__step"}
              />
            </div>
          </div>
        </div>
      </div>
      {components[count - 1]}
    </div>
  );
}
