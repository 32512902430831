import React from "react";
import { Avatar, Tabs } from "antd";
import { TabPane } from "react-bootstrap";
import AddJob from "./AddJob";
import DataConfigTabs from "./DataConfigTabs";
import ValidationTabs from "./ValidationTabs";
import OutputTabs from "./OutputTabs";
import { useState } from "react";
import ParserTabs from "./ParserTabs";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function MainTab(props) {
  let { slug } = useParams();
  const [current, setCurrent] = useState(1);
  const onChange = (key) => {
    setCurrent(parseInt(key));
  };

  useEffect(() => {
    if (slug && slug === "add-job") {
      setCurrent(1);
    } else if (slug && slug === "parser-config") {
      setCurrent(2);
    } else if (slug && slug === "data-config") {
      setCurrent(3);
    } else if (slug && slug === "valid-config") {
      setCurrent(4);
    } else if (slug && slug === "output-config") {
      setCurrent(5);
    }
  }, [slug]);

  return (
    <div>
      <div className="" style={{ backgroundColor: "white", padding: "3rem" }}>
        <Tabs
          onChange={onChange}
          type="card"
          size="large"
          tabBarGutter={15}
          activeKey={`${current}`}
        >
          <TabPane tab="Job information" key="1">
            <Avatar
              style={{
                backgroundColor: "red",
                marginLeft: "4rem",
                fontWeight: 500,
              }}
            >
              1
            </Avatar>
            <p
              style={{
                marginLeft: "3.3rem",
                fontWeight: 500,
                marginTop: ".6rem",
              }}
            >
              Add Job
            </p>
            <AddJob current={current} setCurrent={setCurrent} />
          </TabPane>
          <TabPane tab="Parser configuration" key="2">
            <ParserTabs current={current} setCurrent={setCurrent} />
          </TabPane>
          <TabPane tab="Data configuration" key="3">
            <DataConfigTabs current={current} setCurrent={setCurrent} />
          </TabPane>
          <TabPane tab="Validation configuration" key="4">
            <ValidationTabs current={current} setCurrent={setCurrent} />
          </TabPane>
          <TabPane tab="Output configuration" key="5">
            <OutputTabs current={current} setCurrent={setCurrent} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default MainTab;
