import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getCorporateByCompany,
  getJobDetail,
  updateJob,
  addJob,
  getAllCompany,
} from "../../../redux/actions/other";
import { setLoading } from "../../../redux/actions/other";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useParams } from "react-router-dom";

const EditJob = ({ editId, open, handleClose, handleRefresh }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [companyObject, setCompanyObject] = useState({
    id: null,
    dev_url: "",
    dev_user_id: "",
    dev_password: "",
    prod_url: "",
    prod_user_id: "",
    prod_password: "",
  });

  const [corporates, setCorporates] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [jobStatus, setJobStatus] = useState("");
  const params = useParams();
  const prefix = "SO_";
  const [initialValue, setInitialValue] = useState({
    company: "",
    corporate: "",
    job_id_no: "",
    job_name: "",
    file_mask: `${prefix}`,
    // active_version_no: "",
    send_notification_company: true,
    send_notification_corporate: false,
    status: true,
    path: "Development",
    path2: "Development",
    host: "",
    username: "",
    password: "",
    execution_schedule: "1",
    execution_schedule_minutes: "",
    execution_schedule_utc: "",
  });

  useEffect(() => {
    if (editId != null) {
      jobDetail(editId);
    }
  }, [editId]);

  let jobSchema = Yup.object().shape({
    company: Yup.string().required("Required"),
    corporate: Yup.string().required("Required"),
    job_name: Yup.string().required("Required"),
    file_mask: Yup.string().required("Required"),
    execution_schedule: Yup.string().required("Required"),
    execution_schedule_minutes: Yup.string().when("execution_schedule", {
      is: (execution_schedule) => execution_schedule === "1",
      then: Yup.string().required("Execution schedule is required"),
    }),
    execution_schedule_utc: Yup.string().when("execution_schedule", {
      is: (execution_schedule) => execution_schedule === "2",
      then: Yup.string().required("Execution schedule is required"),
    }),
  });

  useEffect(() => {
    allCompanyList();
  }, [params.id]);

  const allCompanyList = () => {
    dispatch(setLoading(true));
    dispatch(getAllCompany())
      .then((response) => {
        setCompanies(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const corporateListFromCompany = (company_id = null) => {
    dispatch(setLoading(true));
    dispatch(getCorporateByCompany(company_id))
      .then((response) => {
        setCorporates(response["data"]);
        dispatch(setLoading(false));
      })
      .catch(() => {
        setCorporates([]);
        initialValue.corporate = "";
        dispatch(setLoading(false));
      });
  };

  const jobDetail = (id) => {
    dispatch(setLoading(true));
    dispatch(getJobDetail(id))
      .then((response) => {
        const result = response["data"];
        console.log(result);
        setJobStatus(result["job_status"]);
        if (result["company"]["id"]) {
          corporateListFromCompany(result["company"]["id"]);
        }
        let executionSchedule = {
          execution_schedule: "1",
          execution_schedule_utc: "",
          execution_schedule_minutes: "",
        };
        if (result["execution_schedule"]) {
          const splitExecutionTime = result["execution_schedule"].split(" ");
          if (splitExecutionTime.length >= 2 && splitExecutionTime[1] === "M") {
            executionSchedule = {
              execution_schedule: "1",
              execution_schedule_utc: "",
              execution_schedule_minutes: splitExecutionTime[0],
            };
          } else if (
            splitExecutionTime.length >= 2 &&
            splitExecutionTime[1] === "H"
          ) {
            executionSchedule = {
              execution_schedule: "2",
              execution_schedule_utc: `${splitExecutionTime[0]}`,
              execution_schedule_minutes: "",
            };
          }
        }
        setInitialValue({
          company: result["company"]["id"],
          corporate: result["corporate"]["id"],
          job_id_no: result["job_id_no"],
          job_name: result["job_name"],
          file_mask: result["file_mask"],
          execution_schedule: executionSchedule["execution_schedule"],
          execution_schedule_minutes:
            executionSchedule["execution_schedule_minutes"],
          execution_schedule_utc: executionSchedule["execution_schedule_utc"],
          status: result["status"],
          active_version_no: result["active_version_no"],
          send_notification_company: result["send_notification_company"],
          send_notification_corporate: result["send_notification_corporate"],
          path: result["path"],
          path2: result["path2"],
          host: result["host"],
          username: result["username"],
          password: result["password"],
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (companies.length > 0 && initialValue.company) {
      const companyIndex = companies.findIndex(
        (el) => el.id === Number(initialValue.company)
      );
      setCompanyObject(companies[companyIndex]);
      handlePathRadioChange(initialValue.path, companies[companyIndex]);
      handlePathRadioChangePickup(initialValue.path2, companies[companyIndex]);
    }
  }, [companies, initialValue]);

  const handleChangeCompany = async (company_id) => {
    initialValue.company = company_id;
    const companyIndex = companies.findIndex(
      (el) => el.id === Number(company_id)
    );
    setCompanyObject(companies[companyIndex]);
    if (company_id) {
      await corporateListFromCompany(company_id);
    } else {
      setCorporates([]);
      initialValue.corporate = "";
    }
    handlePathRadioChange(ref.current.values.path, companies[companyIndex]);
    handlePathRadioChangePickup(
      ref.current.values.path2,
      companies[companyIndex]
    );
  };

  const handleChangeCorporate = (corporate_id) => {
    initialValue.corporate = corporate_id;
  };

  const handlePathRadioChange = (type, comObject = "") => {
    const companyData = comObject ? comObject : companyObject;
    ref.current.setFieldValue("path", type);
    if (type === "Development" && companyData) {
      ref.current.setFieldValue("host", companyData["dev_url"]);
      ref.current.setFieldValue("username", companyData["dev_user_id"]);
      ref.current.setFieldValue("password", companyData["dev_password"]);
    } else if (type === "Production" && companyData) {
      ref.current.setFieldValue("host", companyData["prod_url"]);
      ref.current.setFieldValue("username", companyData["prod_user_id"]);
      ref.current.setFieldValue("password", companyData["prod_password"]);
    }
  };
  const handlePathRadioChangePickup = (type, comObject = "") => {
    const companyData = comObject ? comObject : companyObject;
    ref.current.setFieldValue("path2", type);
    if (type === "Development" && companyData) {
      ref.current.setFieldValue("host", companyData["dev_url"]);
      ref.current.setFieldValue("username", companyData["dev_user_id"]);
      ref.current.setFieldValue("password", companyData["dev_password"]);
    } else if (type === "Production" && companyData) {
      ref.current.setFieldValue("host", companyData["prod_url"]);
      ref.current.setFieldValue("username", companyData["prod_user_id"]);
      ref.current.setFieldValue("password", companyData["prod_password"]);
    }
  };
  const handleExecutonScheduleRadioChange = (type) => {
    ref.current.setFieldValue("execution_schedule", type);
  };

  const handleExecutionTimeChange = (e) => {
    var dateTime = new Date(e);
    const selectedTime = dateTime.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    ref.current.setFieldValue("execution_schedule_utc", selectedTime);
  };

  const handleSubmit = async (values) => {
    const param = {
      ...values,
      execution_schedule:
        values.execution_schedule === "1"
          ? values.execution_schedule_minutes + " M"
          : values.execution_schedule_utc + " H",
    };
    delete param.execution_schedule_minutes;
    delete param.execution_schedule_utc;
    delete param.active_version_no;
    dispatch(setLoading(true));
    if (editId) {
      dispatch(updateJob(editId, param))
        .then((response) => {
          dispatch(setLoading(false));
          closeModal();
          handleRefresh();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(addJob(param))
        .then((response) => {
          dispatch(setLoading(false));
          closeModal();
          handleRefresh();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };
  const closeModal = () => {
    setInitialValue({
      company: "",
      corporate: "",
      job_id_no: "",
      job_name: "Job name",
      file_mask: "www.website.com",
      execution_schedule: startDate,
      // active_version_no: "",
      send_notification_company: true,
      send_notification_corporate: false,
      status: true,
      path: "Development",
      host: "",
      username: "",
      password: "",
    });
    handleClose();
  };
  return (
    <>
      <div className="addModals">
        <div
          className={`modal fade ${open}`}
          id="editData"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog  modal-lg modal-dialog-centered">
            <Formik
              innerRef={ref}
              initialValues={initialValue}
              validationSchema={jobSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                resetForm,
                handleReset,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title fw-bold"
                        style={{ color: "#2565ab" }}
                      >
                        {editId ? "Update" : "Add"} Jobs
                      </h5>
                      <div
                        onClick={closeModal}
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="modal_form row">
                        {/*Job ID Number*/}
                        {/* {editId != null && ( */}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor={"Name"}>Job ID Number</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_bag.png"}
                            alt=""
                          />
                          <Field
                            name="job_id_no"
                            placeholder=""
                            disabled={true}
                            className={`form-control`}
                          />
                        </div>
                        {/* )} */}

                        {/*Company*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="company">Company</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_building.png"}
                            alt=""
                          />
                          <Field
                            as="select"
                            name="company"
                            onChange={(e) => {
                              setFieldValue("company", e.target.value);
                              handleChangeCompany(e.target.value);
                            }}
                            disabled={true}
                            className={`new_select form-control ${
                              touched.company && errors.company
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select company</option>
                            {companies &&
                              companies.map((company, idx) => (
                                <option key={idx} value={company.id}>
                                  {company.name}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="company"
                            className="invalid-feedback"
                          />
                        </div>

                        {/*Corporate*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">Corporate Customer</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            as="select"
                            name="corporate"
                            onChange={(e) => {
                              setFieldValue("corporate", e.target.value);
                              handleChangeCorporate(e.target.value);
                            }}
                            disabled={true}
                            className={`new_select form-control ${
                              touched.corporate && errors.corporate
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select corporate</option>
                            {corporates &&
                              corporates.map((corporate, idx) => (
                                <option key={idx} value={corporate.id}>
                                  {corporate.name}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="corporate"
                            className="invalid-feedback"
                          />
                        </div>

                        {/*Job name*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">Job name </label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_bag.png"}
                            alt=""
                          />
                          <Field
                            name="job_name"
                            placeholder="Enter Job Name"
                            disabled={["Dev", "Production"].includes(jobStatus)}
                            className={`form-control ${
                              touched.job_name && errors.job_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="job_name"
                            className="invalid-feedback"
                          />
                        </div>

                        {/*File Mask*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">File Mask </label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_website.png"}
                            alt=""
                          />
                          <Field
                            name="file_mask"
                            disabled={["Draft", "Dev", "Production"].includes(
                              jobStatus
                            )}
                            placeholder="www.website.com"
                            className={`form-control ${
                              touched.file_mask && errors.file_mask
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="file_mask"
                            className="invalid-feedback"
                          />
                        </div>

                        {/*Active Version Number*/}
                        {/* {editId != null && ( */}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">Active Version Number</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_brand.png"}
                            alt=""
                          />
                          <Field
                            name="active_version_no"
                            disabled={true}
                            placeholder="1.0.0"
                            style={{ color: "#4E85C5" }}
                            className={`form-control`}
                          />
                        </div>
                        {/* )} */}

                        {/*Send Notification*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">Send Notification</label>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="checkbox"
                              disabled={["Draft", "Dev", "Production"].includes(
                                jobStatus
                              )}
                              name="send_notification_company"
                            />
                            &nbsp;To company
                          </div>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="checkbox"
                              disabled={["Draft", "Dev", "Production"].includes(
                                jobStatus
                              )}
                              name="send_notification_corporate"
                            />
                            &nbsp;To corporate customer
                          </div>
                        </div>

                        {/*FTP Drop Location*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">FTP Location</label>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="radio"
                              name="path"
                              value="Development"
                              onChange={() =>
                                handlePathRadioChange("Development")
                              }
                            />
                            &nbsp; Development
                          </div>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="radio"
                              name="path"
                              value="Production"
                              onChange={() =>
                                handlePathRadioChange("Production")
                              }
                            />
                            &nbsp; Production
                          </div>
                        </div>

                        {/*FTP Pickup Location*/}
                        {/* <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info">FTP Location</label>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="radio"
                              name="path2"
                              value="Development"
                              onChange={() =>
                                handlePathRadioChangePickup("Development")
                              }
                            />
                            &nbsp; Development
                          </div>
                          <div className="form-check-label form_checkbox">
                            <Field
                              type="radio"
                              name="path2"
                              value="Production"
                              onChange={() =>
                                handlePathRadioChangePickup("Production")
                              }
                            />
                            &nbsp; Production
                          </div>
                        </div> */}
                        {/*Execution Schedule*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="info"> Execution Schedule </label>
                          <div role="group" aria-labelledby="my-radio-group">
                            <div className="d-flex align-items-center">
                              <Field
                                type="radio"
                                name="execution_schedule"
                                value={"1"}
                                onChange={() =>
                                  handleExecutonScheduleRadioChange("1")
                                }
                              />
                              <span className={"pl-2"}>Every</span>
                              <Field
                                name="execution_schedule_minutes"
                                className={`pl-2 form-control ${
                                  touched.execution_schedule_minutes &&
                                  errors.execution_schedule_minutes
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <span> min </span>
                            </div>
                            <div className={"d-flex align-items-center"}>
                              <Field
                                type="radio"
                                name="execution_schedule"
                                value={"2"}
                                onChange={() =>
                                  handleExecutonScheduleRadioChange("2")
                                }
                              />
                              <span className={"pl-2"}>Every day at</span>
                              <DatePicker
                                className={`form-control ${
                                  touched.execution_schedule_utc &&
                                  errors.execution_schedule_utc
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={"execution_schedule_utc"}
                                value={values.execution_schedule_utc}
                                onChange={handleExecutionTimeChange}
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="H:mm"
                              />
                              <span>UTC</span>
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            name="execution_schedule_minutes"
                            className="invalid-feedback"
                          />
                        </div>

                        {/*Status*/}
                        <div className="form-group col-md-4 col-sm-12">
                          <label htmlFor="status">Status </label>
                          <div className="custom-switch d-flex">
                            <Field
                              type="checkbox"
                              name="status"
                              disabled={["Dev", "Production"].includes(
                                jobStatus
                              )}
                              className="custom-control-input"
                              id="enable_btn"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="enable_btn"
                            />
                            <p style={{ marginLeft: "10px" }}>Enable/Disable</p>
                          </div>
                        </div>
                      </div>

                      {/*FTP Drop Location*/}
                      <div className="modal_form sub_modal_form row mb-3">
                        <div className="title">
                          FTP Location ({values.path})
                        </div>

                        <div className="form-group col-md-6 col-sm-12 ">
                          <label htmlFor="dev_url">URL</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"url"}
                            placeholder="Enter URL"
                            name={`host`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_url"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group  col-md-6 col-sm-12">
                          <label htmlFor="dev_user_id">User ID</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"text"}
                            placeholder="Enter User Id"
                            name={`username`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_user_id"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group  col-md-6 col-sm-12">
                          <label htmlFor="dev_user_id">Password</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"text"}
                            placeholder="Enter Password"
                            name={`username`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_user_id"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      {/*FTP Pickup Location*/}
                      {/* <div className="modal_form sub_modal_form row mb-3">
                        <div className="title">
                          FTP Location ({values.path2})
                        </div>

                        <div className="form-group col-md-6 col-sm-12 ">
                          <label htmlFor="dev_url">URL</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"url"}
                            placeholder="Enter URL"
                            name={`host`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_url"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group  col-md-6 col-sm-12">
                          <label htmlFor="dev_user_id">User ID</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"text"}
                            placeholder="Enter User Id"
                            name={`username`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_user_id"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group  col-md-6 col-sm-12">
                          <label htmlFor="dev_user_id">Password</label>
                          <img
                            className="form_icon"
                            src={"/images/form_icon_user.png"}
                            alt=""
                          />
                          <Field
                            type={"text"}
                            placeholder="Enter Password"
                            name={`username`}
                            disabled={true}
                            className={`form-control w-100 ${
                              touched.name && errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="dev_user_id"
                            className="invalid-feedback"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="modal-footer text-center">
                      <button
                        type="submit"
                        className="btn btn-primary blue_button"
                      >
                        {editId ? "Update" : "Submit"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => handleReset(resetForm)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJob;
