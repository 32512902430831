/* eslint-disable array-callback-return */
import React from "react";
import OrderTable from "./OrderTable";
import { useDispatch, useSelector } from "react-redux";
import {
  saveDataParser,
  setLoading,
  setParserObject,
  updateDataParser,
} from "../../redux/actions/other";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const DataConfigFourth = ({
  setActiveTab,
  customRule,
  setCustomRule,
  customScript,
}) => {
  console.log("customRule", customRule);
  console.log("customScript", customScript);
  const { parserValue } = useSelector((state) => state.other);
  const configsData = customRule?.configs[0];
  const dispatch = useDispatch();
  const history = useNavigate();
  const validationVal = configsData?.validation.map((item) => item.value);
  const manipulationVal = configsData?.rules.map((item) => item.value);
  console.log("customRule", configsData);

  const setData = (data, index) => {
    let temp = customRule;
    temp["configs"][index]["rules"] = data;
    setCustomRule(temp);
  };

  const save = () => {
    let result = {};
    customRule?.configs?.map((item) => {
      let temp = [];
      item?.rules?.map((sub_item) => {
        temp.push(sub_item["value"]);
      });
      Object.assign(result, { [item["field"]["value"]]: temp });
    });
    console.log("result==>>", result);
    parserValue["rules"] = {
      // customScript
      validation: { color: validationVal },
      manipulation: { color: manipulationVal },
      // "custom_script": { "color": { "replace_char": "def replace_char(x):\n\ta = x.replace('G', 'T')\n\treturn a" } }
      custom_script: {
        color: {
          replace_char:
            "def replace_char(x):\n\ta = x.replace('G', 'T')\n\treturn a",
        },
      },
    };
    parserValue["function_name"] = customRule?.functionName;
    console.log("FinalParam=>>>", parserValue);

    dispatch(setParserObject(parserValue));

    if (customRule?.id) {
      swal({
        title: "Are you sure want to update rule?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Add new rule", "Update existing rule"],
      }).then(async (willDelete) => {
        if (willDelete) {
          dispatch(setLoading(true));
          dispatch(updateDataParser(customRule?.id, parserValue))
            .then(() => {
              history("/reports");
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        } else {
          dispatch(setLoading(true));
          dispatch(saveDataParser(parserValue))
            .then(() => {
              history("/reports");
            })
            .catch(() => {
              dispatch(setLoading(false));
            });
        }
      });
    } else {
      dispatch(setLoading(true));
      dispatch(saveDataParser(parserValue))
        .then(() => {
          history("/reports");
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };

  return (
    <div className="row function_box mt-4 red_table">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" width={200}>
              Rule Order
            </th>
            <th scope="col">Rule Name</th>
            <th scope="col">Validation</th>
          </tr>
        </thead>
        <tbody>
          {customRule?.configs?.map((row, index) => {
            return (
              <tr key={index}>
                <td className="sr_no">{row?.field?.label}</td>
                <td className="user_id">
                  <OrderTable
                    rules={row?.rules}
                    setCustomRule={(e) => setData(e, index)}
                  />
                </td>
                <td className="user_id">
                  <OrderTable
                    rules={row?.validation}
                    setCustomRule={(e) => setData(e, index)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="form-group col-md-12 pt-4 text-right btn_group_section">
        <button
          type="button"
          className="btn_back"
          onClick={() => setActiveTab("three")}
        >
          {" "}
          Back
        </button>
        <button className="btn_save" onClick={() => save()}>
          Save
        </button>
      </div>
    </div>
  );
};

export default DataConfigFourth;
