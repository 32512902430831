import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteJob,
  getJobList,
  setLoading,
  getAllCompany,
  getCorporateByCompany,
  chnageStatJob,
  getJobDetail,
  getThreadList,
  saveDataParser,
} from "../../redux/actions/other";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import Pagination from "../../components/Pagination";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Search from "antd/es/input/Search";
import { SearchOutlined } from "@mui/icons-material";
import { Button, Popover, Select, Switch } from "antd";
import { Option } from "antd/es/mentions";
import EditJob from "../Jobs/Job/EditJob";

const Reports = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    jobName: "",
    jobId: "",
    corpCus: "",
    jobStatus: "",
    company: "",
  });
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState({
    links: {},
    total: 0,
    page: 1,
    page_size: 10,
    results: [],
  });
  const [page, setPage] = useState(1);
  const [editId, setEditId] = useState(null);
  const [show, setShow] = useState("hide");
  const [isRefresh, setIsRefresh] = useState(false);
  const [filter, setFilter] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [sorting, setSorting] = useState({ key: "", ascending: true });
  const [companies, setCompanies] = useState([]);
  const [corpCus, setcorpCus] = useState([]);
  const [jobList, setJobList] = useState();

  const handleClose = () => {
    setShow("hide");
    setEditId(null);
  };

  const handleRefresh = () => {
    getList(page);
  };

  useEffect(() => {
    getList(page);
    allCompanyList();
  }, [isRefresh, page, filter, filterStartDate, filterEndDate, sorting]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };
  const corporateListFromCompany = (company_id = null) => {
    dispatch(setLoading(true));
    dispatch(getCorporateByCompany(company_id))
      .then((response) => {
        setcorpCus(response.data);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const allCompanyList = () => {
    dispatch(setLoading(true));
    dispatch(getAllCompany())
      .then((response) => {
        setCompanies(response["data"]);
        // dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const getList = (page) => {
    dispatch(setLoading(true));
    dispatch(getJobList(page, filter, filterStartDate, filterEndDate, sorting))
      .then((response) => {
        setJobs(response);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const remove = (id) => {
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(getThreadList(id)).then((response) => {
          console.log(response);
          if (!response.isprocess) {
          }
        });

        dispatch(deleteJob(id))
          .then((response) => {
            dispatch(setLoading(false));
            getList(1);
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  // Get job details on onclick edit button
  const getDetail = (id) => {
    dispatch(setLoading(true));
    dispatch(getJobDetail(id))
      .then((response) => {
        const result = response["data"];
        navigate("/tabs/add-job", { state: result });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const statusChange = (event, item) => {
    let id = item?.id;
    swal({
      title: "Are you sure want to Change Status?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        //changeStatToEnable
        dispatch(chnageStatJob(id, { status: event }))
          .then((response) => {
            dispatch(setLoading(false));
            getList(1);
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  function applySorting(key, ascending) {
    setSorting({ key: key, ascending: ascending });
  }

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    const arr = [...jobs?.results];
    setValues({
      ...values,
      [name]: value,
    });

    // search by jobName
    const filterByJobName = () => {
      const data = arr.filter((item) =>
        item.job_name.toLowerCase().includes(value)
      );
      setJobList(data);
      return data;
    };

    // search by jobId
    const filterByJobId = () => {
      const data = arr.filter((item) => item.job_id_no.includes(value));
      setJobList(data);
      return data;
    };

    // search by jobStatus
    const filterByStatus = () => {
      const data = arr.filter((item) => {
        let st = "Draft";

        if (
          st.toLowerCase().includes(value) &&
          (item.job_status === "Job Information" ||
            item.job_status === "Parser Configuration" ||
            item.job_status === "Validation Config" ||
            item.job_status === "Data Configuration" ||
            item.job_status === "Draft")
        ) {
          return item;
        }
      });
      setJobList(data);
      return data;
    };

    switch (name) {
      case "jobName":
        return filterByJobName();
      case "jobId":
        return filterByJobId();
      case "jobStatus":
        return filterByStatus();
      default:
        setJobList(arr);
        return arr;
    }
  };

  const handleChange = (e, key) => {
    setValues({ ...values, [key]: e });
    if (key === "company") {
      setValues({ ...values, corpCus: "", [key]: e });
    }
  };

  // handle search with company and corporate
  const OnSubmit = (e, purpose) => {
    e.preventDefault();
    const arr = [...jobs?.results];
    if (purpose === "reset") {
      setValues({ ...values, company: "", corpCus: "" });
      setJobList(arr);
    } else {
      if (values.corpCus && values.company) {
        const data = arr.filter((item) => {
          return item?.corporate?.id == values.corpCus;
        });
        setJobList(data);
      } else {
        setJobList(arr);
        swal({
          title: `Please select ${values.company ? "corporate" : "company"}`,
          icon: "warning",
        });
      }
    }
  };
  const sendEditIdtoJoblist = () => {
    navigate("/tabs/add-job");
  };

  const handleMovetoDev = (id, item) => {
    console.log(id, item.job_status);
    dispatch(saveDataParser(id, item.job_status === "Dev"))
      .then((res) => {
        console.log(res);
        swal("Status changed to Dev", "success");
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">Job Automation</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div
          className="col-md-6 col-lg-10 col-sm-12 services_section pt-5 table_section"
          style={{ position: "relative", top: 35, marginLeft: "7rem" }}
        >
          <div className="row">
            <div className="col-md-4">
              <label>Job Name</label>
              <Search
                placeholder="Job Name"
                allowClear
                name="jobName"
                value={values.jobName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-4">
              <label>Job Id</label>
              <Search
                placeholder="Job ID"
                allowClear
                name="jobId"
                value={values.jobId}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-4">
              <label>Job Status</label>
              <Search
                placeholder="Job Status"
                allowClear
                name="jobStatus"
                value={values.jobStatus}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mt-4 mb-3">
            <div className="col-md-4">
              <label>Company</label>
              <Select
                defaultValue="Original Name"
                value={values.company}
                onChange={(e) => {
                  let company = companies.filter(
                    (item) => item.id.toString() === e.toString()
                  );
                  if (company && company.length > 0) {
                    handleChange(company[0].name, "company");
                  }
                  corporateListFromCompany(e);
                }}
                name="company"
                style={{
                  width: "100%",
                }}
              >
                {companies &&
                  companies?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-4">
              <label>Corp Customer</label>
              <Select
                defaultValue="Rec"
                value={values.corpCus}
                name="corpCus"
                onChange={(e) => handleChange(e, "corpCus")}
                style={{
                  width: "100%",
                }}
              >
                {corpCus &&
                  corpCus?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
              </Select>
            </div>

            <div className="col-md-2 mt-4">
              <Button
                type="primary"
                onClick={OnSubmit}
                icon={<SearchOutlined />}
                style={{ marginTop: ".5rem", width: "100%" }}
              >
                Search
              </Button>
              <Button
                type="danger"
                onClick={(e) => OnSubmit(e, "reset")}
                icon={<SearchOutlined />}
                style={{ marginTop: ".5rem", width: "100%" }}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-10 col-sm-12 services_section pt-5 table_section"
          style={{ position: "relative", top: 50, marginLeft: "7rem" }}
        >
          <div className="">
            <div className="row table_box">
              <div className={"col-md-8"}>
                <h4>List of Job Automation</h4>
              </div>
              <div className={"col-md-2 -mt-2"}>
                <button
                  className="btn-sm btn-add-field float-end"
                  id="add-edit-corporate"
                  onClick={sendEditIdtoJoblist}
                >
                  <i className="fa fa-plus" />
                  Add Job
                </button>
              </div>
              <div className={"col-md-2 -mt-2"}>
                <button
                  className="btn-sm btn-add-field float-end"
                  id="add-edit-corporate"
                >
                  <i className="fa fa-cog" />
                  Export
                </button>
              </div>
              <table className="table" style={{ fontSize: "13px" }}>
                <thead>
                  <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Corporate Cust</th>
                    <th
                      scope="col"
                      onClick={() => applySorting("id", !sorting.ascending)}
                    >
                      Job Id&nbsp;
                      {sorting.key === "id" && (
                        <i
                          className={`fa fa-sort-${
                            sorting.ascending ? "up" : "down"
                          }`}
                        />
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        applySorting("job_name", !sorting.ascending)
                      }
                    >
                      Job Name&nbsp;
                      {sorting.key === "job_name" && (
                        <i
                          className={`fa fa-sort-${
                            sorting.ascending ? "up" : "down"
                          }`}
                        />
                      )}
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        applySorting("active_version_no", !sorting.ascending)
                      }
                    >
                      Version&nbsp;
                      {sorting.key === "active_version_no" && (
                        <i
                          className={`fa fa-sort-${
                            sorting.ascending ? "up" : "down"
                          }`}
                        />
                      )}
                    </th>
                    <th scope="col">Live Status&nbsp;</th>
                    <th scope="col">Job Status&nbsp;</th>
                    <th scope="col">Action&nbsp;</th>
                  </tr>
                </thead>

                {(jobList ? jobList : jobs?.results)?.length > 0 ? (
                  <tbody>
                    {(jobList ? jobList : jobs?.results).map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="sr_no">{item?.company?.name}</td>
                          <td>{item?.corporate?.name}</td>
                          <td>{item.job_id_no}</td>
                          <td>{item.job_name}</td>
                          <td>
                            {!item.active_version_no
                              ? "-"
                              : item.active_version_no}
                          </td>
                          <td>
                            <span>
                              <Switch
                                checked={item.status}
                                onChange={(event) => statusChange(event, item)}
                              />{" "}
                            </span>
                          </td>
                          <td>
                            <span>
                              {item.job_status === "Job Information" ||
                              item.job_status === "Parser Configuration" ||
                              item.job_status === "Validation Config" ||
                              item.job_status === "Data Configuration" ||
                              item.job_status === "Draft"
                                ? "Draft"
                                : item.job_status}
                            </span>
                          </td>
                          <td className="d-flex justify-content-between align-items-center action_btns">
                            <Link onClick={() => getDetail(item.id)} to={""}>
                              <i className="fa fa-pencil " />
                            </Link>
                            <Link onClick={() => remove(item.id)} to={""}>
                              <i className="fa fa-trash-o btn_delete mr-2" />
                            </Link>
                            {item.job_status === "Job Information" ||
                            item.job_status === "Parser Configuration" ||
                            item.job_status === "Validation Config" ||
                            item.job_status === "Data Configuration" ||
                            item.job_status === "Draft" ? (
                              <Popover
                                placement="right"
                                content={item.job_status}
                              >
                                <Button
                                  type="dashed"
                                  danger
                                  style={{ width: "50%" }}
                                  className="btn-sm "
                                  id="add-edit-corporate"
                                >
                                  <span className="ml-2">!Pending</span>
                                </Button>
                              </Popover>
                            ) : (
                              <div>
                                {item.job_status === "Dev" ? (
                                  <Button
                                    type="primary"
                                    className="btn-sm"
                                    style={{ width: "100%" }}
                                    id="add-edit-corporate"
                                  >
                                    <ArrowRightIcon />
                                    <span
                                      className=""
                                      onClick={() =>
                                        handleMovetoDev(item.id, item)
                                      }
                                    >
                                      Move to Prod
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    className="btn-sm"
                                    style={{ width: "100%" }}
                                    id="add-edit-corporate"
                                  >
                                    <ArrowRightIcon />
                                    <span
                                      className=""
                                      onClick={() =>
                                        handleMovetoDev(item.id, item)
                                      }
                                    >
                                      Move to Dev
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6}> No records found</td>
                    </tr>
                  </tbody>
                )}
              </table>
              <Pagination data={jobs} handlePageClick={handlePagination} />
            </div>
          </div>
          {show === "show" && (
            <EditJob
              open={show}
              editId={editId}
              handleClose={handleClose}
              handleRefresh={handleRefresh}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
