import axios from "axios";
import authHeader from "../helper/auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getJobList = (page, filter, filterStartDate, filterEndDate, sorting) => {
  let search = "",
    startDate = "",
    endDate = "",
    sort = "";
  if (filter !== "") {
    search = `&search=${filter}`;
  }
  if (filterStartDate !== null) {
    startDate = `&from=${filterStartDate}`;
  }
  if (filterEndDate !== null) {
    endDate = `&to=${filterEndDate}`;
  }
  if (sorting["key"] !== "") {
    if (!sorting["ascending"]) {
      sort = `&ordering=-${sorting["key"]}`;
    } else {
      sort = `&ordering=${sorting["key"]}`;
    }
  }
  return axios.get(
    API_URL +
      `api/v1/jobs/job-list?page=${page}${search}${sort}${startDate}${endDate}`,
    { headers: authHeader() }
  );
};

const getAllJob = () => {
  return axios.get(API_URL + `api/v1/jobs/all-jobs`, { headers: authHeader() });
};

const getJobDetail = (id) => {
  return axios.get(API_URL + `api/v1/jobs/job/${id}`, {
    headers: authHeader(),
  });
};

const addJob = (data) => {
  return axios.post(API_URL + `api/v1/jobs/job`, data, {
    headers: authHeader(),
  });
};

const updateJob = (id, data) => {
  return axios.put(API_URL + `api/v1/jobs/job/${id}`, data, {
    headers: authHeader(),
  });
};

const deleteJob = (id) => {
  return axios.delete(API_URL + `api/v1/jobs/job/${id}`, {
    headers: authHeader(),
  });
};

const chnageStatJob = (id, data) => {
  return axios.put(API_URL + `api/v1/jobs/job/${id}`, data, {
    headers: authHeader(),
  });
};
const changeStatToEnable = (id) => {
  return axios.post(API_URL + `api/v1/jobs/enabled-job/${id}`, null, {
    headers: authHeader(),
  });
};
const saveDataParser = (id, data) => {
  const token = localStorage.getItem("token");
  return axios.put(API_URL + `api/v1/jobs/settings/${id}`, data, {
    headers: {
      Authorization: `Token ${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });
};

const updateDataParser = (id, data) => {
  return axios.put(API_URL + `api/v1/jobs/settings/${id}`, data, {
    headers: authHeader(),
  });
};

const getJobLog = (id) => {
  return axios.get(API_URL + `api/v1/jobs/jobs-thread/${id}`, {
    headers: authHeader(),
  });
};
const getJobSettings = (id) => {
  return axios.get(API_URL + `api/v1/jobs/settings/${id}`, {
    headers: authHeader(),
  });
};
const getJobId = () => {
  return axios.get(API_URL + `api/v1/jobs/get-object-id`, {
    headers: authHeader(),
  });
};

const getRuleList = () => {
  return axios.get(API_URL + `api/v1/jobs/get-all-data-validation-type`, {
    headers: authHeader(),
  });
};
const getThreadList = (id) => {
  return axios.get(API_URL + `api/v1/jobs/jobs-thread/${id}`, {
    headers: authHeader(),
  });
};

const getSettingsList = () => {
  return axios.get(API_URL + `api/v1/jobs/settings`, { headers: authHeader() });
};

export default {
  getJobDetail,
  addJob,
  updateJob,
  deleteJob,
  chnageStatJob,
  changeStatToEnable,
  getJobList,
  saveDataParser,
  updateDataParser,
  getAllJob,
  getJobLog,
  getRuleList,
  getThreadList,
  getSettingsList,
  getJobId,
  getJobSettings,
};
