import axios from "axios";
import swal from "sweetalert";
import ReactJson from "react-json-view";
import React, { useEffect, useState } from "react";
import PythonCodeEditor from "./PythoCodeEditor";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/actions/other";
import { FaCode } from "react-icons/fa";
import Toggle from "react-toggle";
import "react-toggle/style.css";
const Code = (props) => {
  const { excelValue, handleInputChange, previewResult, filterDataToPython } =
    props;
  const dispatch = useDispatch();

  const [childExcelvalue, setChildExcelValue] = useState();
  const [code, setCode] = useState(
    "#This code will make the fields uppercase\nfinalArray = [] \nfor item in sheetArray: \n\t temp1=[] \n\t for row in item:\n\t\t temp1.append(str(row).upper())\n\t finalArray.append(temp1)\n"
  );

  // const handleCodeChange = (event) => {
  //   handleInputChange(event.target.value);
  //   setCode(event.target.value);
  // };
  const handleCodeChange = (newCode) => {
    handleInputChange(newCode);
    setCode(newCode);
  };

  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const currentTheme = isDarkTheme ? "monokai" : "xcode";
  const exampleCode =
    "let finalArray = []; \r\nsheetArray.map((item) => \r\n{ \r\nfinalArray.push(item);\r\n}\r\n);";

  const resetCode = () => setCode(exampleCode);

  const executeCode = async () => {
    let pre = "sheetArray=" + JSON.stringify(filterDataToPython.value) + "\n";
    let post = "print(finalArray);";
    let headers = {
      "access-control-allow-origin": "*",
      "X-RapidAPI-Key": "88f2804459mshde8af0043241c77p1e3430jsndc0f94d00852",
      "X-RapidAPI-Host": "online-code-compiler.p.rapidapi.com",
    };

    try {
      const response = await axios.post(
        "https://online-code-compiler.p.rapidapi.com/v1/",
        {
          code: pre + code + post,
          // code: "print('''Hello, World!''');",
          language: "python3",
          version: "latest",
          input: null,

          // clientId: "e036f4052ec0638a66d220a70ee9ce6f",
          // clientSecret:
          //   "14245a5160bf1e7187812ebda73bbe8d006ec3a344fdcadd180919319dd349aa",
          // stdin: "",
        },
        { headers }
      );
      dispatch(setLoading(true));
      if (response?.status === 200) {
        let str = response.data.output;
        console.log(str?.replace(/'/g, '"'));
        const arrayContent = JSON.parse(str?.replace(/'/g, '"'));
        console.log(arrayContent);
        dispatch(setLoading(false));
        previewResult(arrayContent);
        setChildExcelValue(str);

        // swal({
        //   title: "Code compiled successfully",
        //   // text: "Please save the rule for future use",
        //   icon: "success",
        //   text: JSON.stringify(str),
        //   dangerMode: true,
        //   showConfirmButton: true,
        //   confirmButtonText: "OK",
        // }).then(() => {});
      }
    } catch (e) {
      dispatch(setLoading(false));
      swal({
        title: "OOPS! The code compiled with error",
        text: JSON.stringify(e),
        icon: "error",
        dangerMode: true,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    handleInputChange(code);
  }, []);

  return (
    <div className="container py-4">
      <div className="row justify-content-center">
        <div className="col-6 mt-2">
          <h4>Currently we only support Python3 </h4>
          <div>
            <h6>Instructions.</h6>
            <p>
              Your sheet is being treated as array by our compiler, You just
              need to perform operations on this given array and changes will be
              saved to your current uploaded sheet. A sample code is currently
              shown in the code box. You can click on run and preview will be
              shown. Now you can modify the example as required and can always
              reset to the default example using Reset button
            </p>
            <div className="w-100 py-2 px-3 mt-2">
              <ReactJson
                name="sheetArray"
                src={excelValue?.sheetData[excelValue?.sheet]}
                collapsed={true}
              />
            </div>
          </div>
        </div>
        <div className="col-6 mt-2" style={{ height: "6rem" }}>
          <div style={{ display: "flex", gap: "1rem", marginLeft: "10rem" }}>
            <div>
              <h5>
                Python Code Editor <FaCode />{" "}
              </h5>
            </div>
            <span
              style={{
                textAlign: "center",
                color: "grey",
                fontWeight: 600,
                marginTop: ".1rem",
              }}
            >
              Mode
            </span>
            <div>
              <Toggle
                checked={isDarkTheme}
                onChange={({ target }) => setIsDarkTheme(target.checked)}
                icons={{ checked: "🌙", unchecked: "🔆" }}
                aria-label="Dark mode toggle"
              />
              {/* <Switch
                size="small"
                onClick={toggleTheme}
                style={{ color: "black" }}
                icons={{ checked: "🌙", unchecked: "🔆" }}
              /> */}
            </div>
          </div>

          <PythonCodeEditor
            code={code}
            onChange={handleCodeChange}
            currentTheme={currentTheme}
          />
          <span className="col-3 p-0 mt-20">
            <button
              className="btn btn-dark px-5 ml-auto mt-3"
              onClick={executeCode}
            >
              {" "}
              Run{" "}
            </button>
            <button
              className="btn btn-primary px-5 mt-3 ml-3"
              onClick={resetCode}
            >
              {" "}
              Reset{" "}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Code;
