import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  deleteCompany,
  getRuleList,
  getSettingsList,
  setLoading,
} from "../../redux/actions/other";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Code from "./DataConfigCode";

const DataConfigOne = (props) => {
  const dispatch = useDispatch();
  const { parserValue, setCustomRule, setActiveTab, customRule, excelValue } =
    props;
  // console.log(excelValue);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [custom_rule, setCustom_rule] = useState(false);
  const [validationOptions, setValidationOptions] = useState([]);
  const [headerOptions, setHeaderOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    id: "",
    functionName: "",
    configs: [],
  });

  const [configList, setConfigList] = useState([]);
  const [configSettings, setConfigSettings] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [childExcelValue, setChildExcelValue] = useState("");
  const handleChildExcelValue = (childExcelValue) => {
    setChildExcelValue(childExcelValue);
    setIsPreview(true);
  };
  useEffect(() => {
    allHeaderList();
    allRuleList();
    setInitialData();
    allConfigList();
  }, []);

  const validationSchema = Yup.object().shape({
    functionName: Yup.string().required("Function name is required"),
    configs: Yup.array().of(
      Yup.object().shape({
        field: Yup.object().required("Field is required"),
        rules: Yup.array().min(1, "At least one option is required").required(),
        // validation: Yup.array()
        //   .min(1, "At least one option is required")
        //   .required(),
      })
    ),
  });

  const setInitialData = () => {
    let functionName = customRule?.functionName,
      configs = [];
    customRule["configs"].map((item) => {
      configs.push({
        field: item["field"],
        rules: item["rules"],
        validation: item["validation"],
      });
    });
    setInitialValues({
      functionName: functionName,
      configs: configs,
      id: customRule?.id,
    });
  };

  const onAddRow = (values, setValues) => {
    const configs = [...values.configs];
    // const num = configs.length ;
    // const addNum = num + 1 ;
    setConfigSettings(configs);
    configs.push({ field: "", rules: "", validation: "" });
    setValues({ ...values, configs });
    if (configs[0].field) {
      const filteredArray = headerOptions.filter((item) => {
        const fieldValues = configs.map((obj) => obj.field.value);
        return !fieldValues.includes(item.value);
      });
      setHeaderOptions(filteredArray);
    }
  };

  const onRemoveRow = (i, values, setValues) => {
    const configs = [...values.configs];
    if (configs[0].field) {
      const filteredArray = headerOptions.filter((item) => {
        const fieldValues = configs.map((obj) => obj.field.value);
        return !fieldValues.includes(item.value);
      });
      setHeaderOptions(filteredArray);
    }
    configs.splice(i, 1);
    setValues({ ...values, configs });
    setConfigSettings(configs);
  };
  const onSubmit = (fields) => {
    console.log("fields-->", JSON.stringify(fields, null, 4));
    setActiveTab("two");
    setCustomRule(fields);
  };

  // Get all rule list
  const allRuleList = () => {
    dispatch(setLoading(true));
    dispatch(getRuleList())
      .then((response) => {
        const valRes = response["data"]["validation"];
        const resData = response["data"]["manipulation"];

        let temp = [];
        resData.map((item) => {
          let value = item.replaceAll("_", " ");
          temp.push({ value: item, label: titleCase(value) });
        });
        setRuleOptions(temp);

        let temp2 = [];
        valRes.map((item2) => {
          let value2 = item2.replaceAll("_", " ");
          temp2.push({ value: item2, label: titleCase(value2) });
        });
        setValidationOptions(temp2);
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  // Get all header list
  const allHeaderList = () => {
    const headers = Object.values(parserValue["alias_name"]);
    const temp = [];
    headers?.map((item) => {
      temp.push({ value: item, label: item });
    });
    setHeaderOptions(temp);
  };

  const allConfigList = () => {
    dispatch(setLoading(true));
    dispatch(getSettingsList())
      .then((response) => {
        if (response["data"].length > 0) {
          let temp = [];
          response["data"].find((obj) => {
            if (obj.job === parserValue["job"]) {
              obj["rules"] = getRulesFromString(obj["rules"]);
              temp.push(obj);
            }
          });
          setConfigList(temp);
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const getRulesFromString = (string) => {
    // regex pattern to match the details inside the string
    const pattern = /\('(.+?)',\s*\[(.+?)\]\)/g;
    // use regex to find all matches of the pattern in the string
    let matches = null;
    const matchResults = [];
    while ((matches = pattern.exec(string)) !== null) {
      matchResults.push(matches);
    }

    /*for (const match of matchResults) {
            const field = match[1];
            // const rules = match[2].split(", ");
            const rules = match[2].split(", ");
            console.log("Field:", field);
            console.log("Rules:", rules);
        }*/

    return matchResults;
  };

  const titleCase = (str) => {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  };

  const edit = (item) => {
    let mainRules = [];
    for (const match of item.rules) {
      let rules = [];
      mainRules.push({
        field: { value: match[1], label: match[1] },
        rules: rules,
      });
      match[2].split(", ").map((val) => {
        let value = val.replaceAll("_", " ").replaceAll("'", "");
        rules.push({ value: val.replaceAll("'", ""), label: titleCase(value) });
      });
    }
    setInitialValues({ functionName: "", configs: mainRules, id: item.id });
  };

  const remove = (id) => {
    swal({
      title: "Are you sure want to delete?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Yes"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(setLoading(true));
        dispatch(deleteCompany(id))
          .then((response) => {
            dispatch(setLoading(false));
            allConfigList();
          })
          .catch(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched, setValues, setFieldValue }) => (
        <Form>
          <div className="form_box mt-4">
            <div className="textarea2">
              <div className="row">
                <div className="col-md-6 col-12">
                  <h6> Function Name </h6>
                  <Field name="functionName">
                    {({ field }) => (
                      <>
                        <Field
                          {...field}
                          type="text"
                          className={
                            "form-control w-100" +
                            (errors.functionName && touched.functionName
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="functionName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-12">
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    onClick={(e) => onAddRow(values, setValues)}
                  >
                    Add more rules
                  </button>
                </div>
              </div>

              <FieldArray name="configs">
                {() =>
                  values.configs.map((config, i) => {
                    console.log("config", config);
                    const configErrors =
                      (errors.configs?.length && errors.configs[i]) || {};
                    const configTouched =
                      (touched.configs?.length && touched.configs[i]) || {};
                    return (
                      <div key={i} className="row mt-3">
                        <div className="col-md-6 col-12">
                          <label>Field</label>
                          <Select
                            isClearable
                            value={config["field"]}
                            name={`configs.${i}.field`}
                            options={headerOptions}
                            className={
                              "basic-multi-select" +
                              (configErrors.field && configTouched.field
                                ? " is-invalid"
                                : "")
                            }
                            classNamePrefix="select"
                            styles={{
                              border: "1px solid red",
                              padding: "5px 2px",
                            }}
                            onChange={(e) =>
                              setFieldValue(`configs.${i}.field`, e)
                            }
                          />
                          <ErrorMessage
                            component="div"
                            name={`configs.${i}.field`}
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-5 col-12">
                          <label>Rules</label>
                          {!custom_rule && (
                            <Select
                              isDisabled={custom_rule}
                              isMulti
                              value={config["rules"]}
                              name={`configs.${i}.rules`}
                              options={[
                                ...ruleOptions,
                                {
                                  label: "Custom Rules",
                                  value: "custom",
                                },
                              ]}
                              className={
                                "basic-multi-select" +
                                (configErrors.rules && configTouched.rules
                                  ? " is-invalid"
                                  : "")
                              }
                              classNamePrefix="select"
                              styles={{
                                border: "1px solid red",
                                padding: "5px 2px",
                              }}
                              onChange={(e) => {
                                console.log(e, "e");
                                if (e.length > 0 && e[0].value === "custom") {
                                  setCustom_rule(true);
                                } else {
                                  setFieldValue(`configs.${i}.rules`, e);
                                }
                              }}
                            />
                          )}

                          <ErrorMessage
                            name={`configs.${i}.rules`}
                            component="div"
                            className="invalid-feedback"
                          />
                          {custom_rule && (
                            <div className="col-md-5 col-12">
                              <button
                                type="button"
                                style={{ width: "17.5rem" }}
                                class="btn btn-outline-dark"
                                onClick={() => setCustom_rule(false)}
                              >
                                Remove custom rules
                              </button>
                            </div>
                          )}
                        </div>
                        {/* <div className="col-md-3 col-12">
                          <label>Validations</label>
                          <Select
                            isMulti
                            value={config["validation"]}
                            name={`configs.${i}.validation`}
                            options={validationOptions}
                            className={
                              "basic-multi-select" +
                              (configErrors.rules && configTouched.rules
                                ? " is-invalid"
                                : "")
                            }
                            classNamePrefix="select"
                            styles={{
                              border: "1px solid red",
                              padding: "5px 2px",
                            }}
                            onChange={(e) =>
                              setFieldValue(`configs.${i}.validation`, e)
                            }
                          />
                          <ErrorMessage
                            name={`configs.${i}.validation`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div> */}
                        <div className="col-md-1 col-12">
                          <label className={"d-block"}>&nbsp;</label>
                          <button
                            type={"button"}
                            className="btn btn-outline-secondary"
                            onClick={(e) => onRemoveRow(i, values, setValues)}
                          >
                            <i className="fa fa-times" />
                          </button>
                        </div>
                      </div>
                    );
                  })
                }
              </FieldArray>
              {custom_rule && (
                <div className="py-5">
                  <Code
                    onChildExcelValue={handleChildExcelValue}
                    excelValue={excelValue}
                  />
                </div>
              )}

              {configList.length > 0 && (
                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">Job No</th>
                      <th scope="col">Version</th>
                      <th scope="col">Status</th>
                      <th scope="col">Rules</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {configList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="sr_no">{i + 1}</td>
                          <td className="user_id">{item.job}</td>
                          <td>{item.version}</td>
                          <td>{item.status}</td>
                          <td>
                            {item.rules.map((data, index) => {
                              return (
                                <div key={index} className={"d-flex"}>
                                  <span>{data[1]} = </span>
                                  <br />
                                  <span>
                                    {data[2].split(", ").map((val, index) => {
                                      return (
                                        <span className={"d-block"} key={index}>
                                          {index} : {val.replaceAll("'", "")}
                                        </span>
                                      );
                                    })}
                                  </span>
                                </div>
                              );
                            })}
                          </td>
                          <td className="action_btns">
                            <Link onClick={() => edit(item)} to={""}>
                              <i className="fa fa-pencil btn_edit" />
                            </Link>
                            <Link onClick={() => remove(item.id)} to={""}>
                              <i className="fa fa-trash-o btn_delete" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="form-group col-md-12 pt-4 text-right btn_group_section">
            <button type="reset" className="btn_back">
              {" "}
              Reset
            </button>
            <button type="submit" className="btn_red">
              {" "}
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DataConfigOne;
