import { LOGIN_SUCCESS, LOGOUT, LOGIN_FAIL } from "./types";
import AuthService from "../../services/auth.service";
import swal from "sweetalert";

export const login = (email, password) => (dispatch) => {
  return AuthService.login(email, password).then(
    (response) => {
      if (response.data) {
        localStorage.setItem("role", response.data.data.user.role);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem(
          "User-info",
          JSON.stringify(response.data.data.user)
        );
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response.data },
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      swal(message, "", "error");

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  return AuthService.logout()
    .then((response) => {
      if (response.data) {
        localStorage.removeItem("token");
        localStorage.removeItem("User-info");
        localStorage.removeItem("role");
      }
      dispatch({
        type: LOGOUT,
      });

      window.location.href = "/";
      // browserHistory.push('/');

      return Promise.resolve();
    })
    .catch((error) => {
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
    });
};
