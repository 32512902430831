import axios from "axios";
import authHeader from "../helper/auth-header";

const API_URL = process.env.REACT_APP_API_URL;
console.log("API_URL", API_URL);

const login = (email, password) => {
  return axios.post(API_URL + "api/v1/auth/login", { email, password });
};

const logout = () => {
  return axios.post(API_URL + `api/v1/auth/logout`, null, {
    headers: authHeader(),
  });
};

const sendOTP = (data) => {
  return axios.post(API_URL + `api/v1/auth/forgate-password`, data);
};

const updatePassword = (data) => {
  return axios.post(API_URL + `api/v1/auth/save-password`, data);
};

export default {
  login,
  logout,
  sendOTP,
  updatePassword,
};
