import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SideBar = ({ sideBarOpen }) => {
  const [menuOpen, setMenuOpen] = useState({
    dashboard: false,
    user: false,
    job: false,
    config: false,
    export: false,
    report: false,
    automation: false,
  });

  const ToggleMenu = (type) => {
    setMenuOpen({
      ...{
        dashboard: false,
        user: false,
        job: false,
        config: false,
        export: false,
        report: false,
        automation: false,
      },
      [type]: !menuOpen[type],
    });
  };

  const location = useLocation();

  useEffect(() => {
    if (
      (location.pathname === "/user-creation/admin" ||
        location.pathname === "/user-creation/sub-admin" ||
        location.pathname === "/user-creation" ||
        location.pathname === "/user-creation/user") &&
      !menuOpen.user
    ) {
      ToggleMenu("user");
    } else if (
      (location.pathname === "/job/company" ||
        location.pathname === "/job/corporate" ||
        location.pathname === "/job/jobs" ||
        location.pathname === "/job/summary") &&
      !menuOpen.job
    ) {
      ToggleMenu("job");
    } else if (
      (location.pathname === "/config/data-parser" ||
        location.pathname === "/config/data-config" ||
        location.pathname === "/config/data-validate") &&
      !menuOpen.config
    ) {
      ToggleMenu("config");
    }
  }, [location.pathname]);

  return (
    <>
      <nav id="sidebar" className={sideBarOpen ? "" : "active"}>
        <div className="sidebar-header">
          <img
            src={"/img/AiBits_D.png"}
            alt=""
            style={{ height: "66px", width: "190px" }}
          />
        </div>
        <div id="accordion">
          <ul className="list-unstyled components">
            <li className={`${menuOpen["dashboard"] ? "active dropup" : ""}`}>
              <Link
                className={
                  location.pathname === "/dashboard"
                    ? "active-tag"
                    : "inactive-tag"
                }
                to="/dashboard"
                data-toggle="collapse"
                onClick={() => ToggleMenu("dashboard")}
              >
                Dashboard
              </Link>
            </li>

            <li className={`${menuOpen["config"] ? "active dropup" : ""}`}>
              <a
                data-toggle="collapse"
                aria-expanded={`${menuOpen["config"] ? "true" : "false"}`}
                onClick={() => ToggleMenu("config")}
                className="dropdown-toggle menuOpen"
              >
                Job Automation
              </a>
              <ul
                className={`collapse ${
                  menuOpen["config"] ? "show" : ""
                }  list-unstyled`}
                id="homeSubmenu"
              >
                <li
                  className={`${menuOpen["automation"] ? "active dropup" : ""}`}
                >
                  <Link
                    className={
                      location.pathname === "/automation"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                    to="/automation"
                    data-toggle="collapse"
                    onClick={() => ToggleMenu("automation")}
                  >
                    Job Automation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/job/jobs"
                    className={
                      location.pathname === "/job/jobs"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Job Creation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-parser"
                    className={
                      location.pathname === "/config/data-parser"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Parsing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-validate"
                    className={
                      location.pathname === "/config/data-validate"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Rules
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-validate"
                    className={
                      location.pathname === "/config/data-validate"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Validation
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-validate"
                    className={
                      location.pathname === "/config/data-validate"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Export
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${menuOpen["job"] ? "active dropup" : ""}`}>
              <a
                data-toggle="collapse"
                aria-expanded={`${menuOpen["job"] ? "true" : "false"}`}
                onClick={() => ToggleMenu("job")}
                className="dropdown-toggle menuOpen"
              >
                Company Masters
              </a>
              <ul
                className={`collapse ${
                  menuOpen["job"] ? "show" : ""
                }  list-unstyled`}
                id="homeSubmenu"
              >
                <li>
                  <Link
                    to="/job/company"
                    className={
                      location.pathname === "/job/company"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Company
                  </Link>
                </li>
                <li>
                  <Link
                    to="/job/corporate"
                    className={
                      location.pathname === "/job/corporate"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Corporate
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/job/jobs"
                    className={
                      location.pathname === "/job/jobs"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Jobs
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to="/job/summary"
                    className={
                      location.pathname === "/job/summary"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Summary
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className={`${menuOpen["admin-panel"] ? "active dropup" : ""}`}>
              <Link
                className={
                  location.pathname === "/admin-panel"
                    ? "active-tag"
                    : "inactive-tag"
                }
                to="/admin-panel"
                data-toggle="collapse"
                onClick={() => ToggleMenu("dashboard")}
              >
                Admin
              </Link>
            </li>
            {/* <li className={`${menuOpen["user"] ? "active dropup" : ""}`}>
              <a
                data-toggle="collapse"
                aria-expanded={`${menuOpen["user"] ? "true" : "false"}`}
                onClick={() => ToggleMenu("user")}
                className="dropdown-toggle menuOpen"
              >
                Admin
              </a>
              <ul
                className={`collapse ${
                  menuOpen["user"] ? "show" : ""
                }  list-unstyled`}
                id="homeSubmenu"
              >
                <li>
                  <Link
                    to="/user-creation/admin"
                    className={
                      location.pathname === "/user-creation/admin"
                        ? "active-tag"
                        : " "
                    }
                  >
                    Admin
                  </Link>
                </li>
                <li>
                  <Link
                    to="/user-creation/sub-admin"
                    className={
                      location.pathname === "/user-creation/sub-admin"
                        ? "active-tag"
                        : " "
                    }
                  >
                    Sub-Admin
                  </Link>
                </li>
                <li>
                  <Link
                    to="/user-creation"
                    className={
                      location.pathname === "/user-creation" ||
                      location.pathname === "/user-creation/user"
                        ? "active-tag"
                        : " "
                    }
                  >
                    User
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin-panel"
                    className={
                      location.pathname === "/admin-panel" ||
                      location.pathname === "/admin-panel"
                        ? "active-tag"
                        : " "
                    }
                  >
                    Admin-panel
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className={`${menuOpen["config"] ? "active dropup" : ""}`}>
              <a
                data-toggle="collapse"
                aria-expanded={`${menuOpen["config"] ? "true" : "false"}`}
                onClick={() => ToggleMenu("config")}
                className="dropdown-toggle menuOpen"
              >
                Configuration
              </a>
              <ul
                className={`collapse ${
                  menuOpen["config"] ? "show" : ""
                }  list-unstyled`}
                id="homeSubmenu"
              >
                <li>
                  <Link
                    to="/config/data-parser"
                    className={
                      location.pathname === "/config/data-parser"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Parser
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-config"
                    className={
                      location.pathname === "/config/data-config"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Config
                  </Link>
                </li>
                <li>
                  <Link
                    to="/config/data-validate"
                    className={
                      location.pathname === "/config/data-validate"
                        ? "active-tag"
                        : "inactive-tag"
                    }
                  >
                    Data Validation
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className={`${menuOpen["export"] ? "active dropup" : ""}`}>
              <a
                data-toggle="collapse"
                aria-expanded={`${menuOpen["export"] ? "true" : "false"}`}
                onClick={() => ToggleMenu("export")}
                className="dropdown-toggle menuOpen"
              >
                Export
              </a>
              <ul
                className={`collapse ${
                  menuOpen["export"] ? "show" : ""
                }  list-unstyled`}
                id="homeSubmenu"
              >
                <li>
                  <a href="#">Export </a>
                </li>
              </ul>
            </li> */}
            {/* <li className={`${menuOpen["reports"] ? "active dropup" : ""}`}>
              <Link
                className={
                  location.pathname === "/reports"
                    ? "active-tag"
                    : "inactive-tag"
                }
                to="/reports"
                data-toggle="collapse"
                onClick={() => ToggleMenu("reports")}
              >
                Report
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideBar;
