import React from "react";
import { Link, useParams } from "react-router-dom";
import JobTabs from "./tabs";
import CompanyList from "./Company";
import CorporateList from "./Corporate";
import JobList from "./Job";

const Jobs = () => {
  const { type = "jobs" } = useParams();
  localStorage.setItem("job", type);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">Ai Bit Masters</h1>
              <Link
                style={{ textDecoration: "none" }}
                className="float-right btn_dashboard"
                to="/dashboard"
              >
                <i className="fa fa-home" />
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <JobTabs type={type} />
      {type === "company" && <CompanyList />}
      {type === "corporate" && <CorporateList />}
      {/* {type === "jobs" && <JobList />}
      {type === "summary" && <JobList />} */}
    </div>
  );
};

export default Jobs;
