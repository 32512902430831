import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DataConfigOne from "../../components/DataConfig/DataConfigOne";
import DataConfigTwo from "../../components/DataConfig/DataConfigTwo";
import DataConfigThird from "../../components/DataConfig/DataConfigThird";
import DataConfigFourth from "../../components/DataConfig/DataConfigFourth";
const DataConfig = () => {
  const [type, setType] = useState("one");
  const [customScript, setCustomScriptVal] = useState();

  // get sheet data from redux store
  const { excelValue, parserValue } = useSelector((state) => state.other);
  console.log(excelValue, parserValue,"new");
  const setCustomScript = (val) => {
    console.log("custom script", excelValue, parserValue);
    setCustomScriptVal(val);
  };

  const [customRule, setCustomRule] = useState({
    functionName: "",
    configs: [],
  });
  const [customRule2, setCustomRule2] = useState({
    functionName: "",
    configs: [],
  });

  const setActiveTab = (type) => {
    setType(type);
  };

  if (parserValue === null) {
    alert("Please go to data parser first then you can config data.");
    return <Navigate to="/config/data-parser" replace={true} />;
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">Data Config</h1>
              <Link
                style={{ textDecoration: "none" }}
                className="float-right btn_dashboard"
                to="/dashboard"
              >
                <i className="fa fa-home" />
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 table_section tab-section">
            <button
              type="button"
              className={`btn btn-config-tab ${type === "one" ? "active" : ""}`}
              onClick={() => setType("one")}
            >
              Create Function
            </button>
            <button
              type="button"
              className={`btn btn-config-tab ${type === "two" ? "active" : ""}`}
              onClick={() => setType("two")}
            >
              Preview Output
            </button>
            <button
              type="button"
              className={`btn btn-config-tab ${
                type === "three" ? "active" : ""
              }`}
              onClick={() => setType("three")}
            >
              Mandatory
            </button>
            <button
              type="button"
              className={`btn btn-config-tab ${
                type === "four" ? "active" : ""
              }`}
              onClick={() => setType("four")}
            >
              Data Rules Set
            </button>

            {type === "one" && excelValue != null && (
              <DataConfigOne
                setActiveTab={setActiveTab}
                excelValue={excelValue}
                parserValue={parserValue}
                setCustomRule={setCustomRule}
                customRule={customRule}
              />
            )}
            {type === "two" && excelValue != null && (
              <DataConfigTwo
                setActiveTab={setActiveTab}
                parserValue={parserValue}
                excelValue={excelValue}
                customRule={customRule}
              />
            )}
            {
              <div className={type === "three" ? "" : "d-none"}>
                <DataConfigThird
                  setActiveTab={setActiveTab}
                  parserValue={parserValue}
                  excelValue={excelValue}
                  customRule={customRule}
                  customRule2={customRule2}
                  setCustomRule={setCustomRule}
                  setCustomRule2={setCustomRule2}
                  setCustomScript={setCustomScript}
                />
              </div>
            }
            {type === "four" && excelValue != null && (
              <DataConfigFourth
                setActiveTab={setActiveTab}
                customRule={customRule}
                setCustomRule={setCustomRule}
                customScript={customScript}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataConfig;
