import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-xcode";

import "ace-builds/src-noconflict/ext-searchbox";
import { FaCode } from "react-icons/fa";

const PythonCodeEditor = ({ code, onChange, currentTheme }) => {
  const handleChange = (newCode) => {
    onChange(newCode);
  };

  return (
    <div>
      <AceEditor
        mode="python"
        theme={currentTheme}
        value={code}
        onChange={handleChange}
        name="python-editor"
        editorProps={{ $blockScrolling: true }}
        style={{ width: "100%", height: "380px" }} // Adjust the height as needed
      />
    </div>
  );
};

export default PythonCodeEditor;
