import React from "react";
import moment from "moment";

export const JobLogTable = (props) => {
  const jobLogs = props.jobLogs;

  return (
    <>
      <div className="row table_box red_table" style={{ maxHeight: "500px" }}>
        {jobLogs && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Job ID no</th>
                <th scope="col">Job Name</th>
                <th scope="col">Status</th>
                <th scope="col">Completed</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {jobLogs.length === 0 ? (
                <tr>
                  <td colSpan={5}>No thread found for this job</td>
                </tr>
              ) : (
                jobLogs.map((row, index) => (
                  <tr key={index}>
                    <td>{row["job"]["job_id_no"]}</td>
                    <td>{row["job"]["job_name"]}</td>
                    <td>{row["status"]}</td>
                    <td>
                      {row["is_complete"] ? (
                        <i className="fa fa-check" />
                      ) : (
                        <i className="fa fa-times text-danger" />
                      )}
                    </td>
                    <td>
                      {moment(row["created_at"]).format("YYYY-MM-DD hh:mm a")}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
