import React from "react";

export const GroupCheckTableMandory = (props) => {
  const groupCheckPreviewData = props.groupCheckPreviewData;
  const groupCheckData = props.groupCheckData;
  console.log(groupCheckData, "grp");

  return (
    <div className="table_box red_table" style={{ maxHeight: "500px" }}>
      {groupCheckPreviewData && groupCheckPreviewData.length > 0 && (
        <>
          <h4>File Preview</h4>
          <table className="table">
            <thead>
              <tr>
                {groupCheckData?.map((item, index) => (
                  <th key={index} scope="col">
                    {item["field"]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {groupCheckPreviewData?.slice(1).map((row, index) => (
                <tr key={index}>
                  {row.map((item, index) => (
                    <td key={index}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
