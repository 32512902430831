import React, { useEffect, useState } from "react";

export const GroupCheckTable = (props) => {
  const [groupCheckData, setgroupCheckData] = useState([]);
  const groupCheckPreviewData = props.lastTable
    ? props.groupCheckPreviewData
    : props.groupCheckPreviewData.Sheet1; //changed to sheetdata
  // const groupCheckData = props.groupCheckData;
  console.log(props, "mySheetDataFinal");

  useEffect(() => {
    setgroupCheckData(props.groupCheckData);
  }, [props.groupCheckData]);

  return (
    <div className="table_box red_table" style={{ maxHeight: "500px" }}>
      {groupCheckPreviewData && groupCheckPreviewData.length > 0 && (
        <>
          <h4>File Preview</h4>
          <table className="table">
            <thead>
              <tr>
                {groupCheckData?.map((item, index) => (
                  <th key={index} scope="col">
                    {item["alias"]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {groupCheckPreviewData?.slice(1).map((row, index) => (
                <tr key={index}>
                  {row.map((item, index) => (
                    <td key={index}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
