import axios from "axios";
import authHeader from "../helper/auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getCompanyList = (page, filter, sorting) => {
  let search = "",
    sort = "";
  if (filter !== "") {
    search = `&search=${filter}`;
  }
  if (sorting["key"] !== "") {
    if (!sorting["ascending"]) {
      sort = `&ordering=-${sorting["key"]}`;
    } else {
      sort = `&ordering=${sorting["key"]}`;
    }
  }
  return axios.get(
    API_URL + `api/v1/jobs/company-list?page=${page}${search}${sort}`,
    { headers: authHeader() }
  );
};

const getAllCompany = () => {
  return axios.get(API_URL + `api/v1/jobs/all-company`, {
    headers: authHeader(),
  });
};

const getCompanyDetail = (id) => {
  return axios.get(API_URL + `api/v1/jobs/company/${id}`, {
    headers: authHeader(),
  });
};

const addCompany = (data) => {
  return axios.post(API_URL + `api/v1/jobs/company`, data, {
    headers: authHeader(),
  });
};

const updateCompany = (id, data) => {
  return axios.put(API_URL + `api/v1/jobs/company/${id}`, data, {
    headers: authHeader(),
  });
};

const deleteCompany = (id) => {
  return axios.delete(API_URL + `api/v1/jobs/company/${id}`, {
    headers: authHeader(),
  });
};

export default {
  getCompanyList,
  getCompanyDetail,
  addCompany,
  updateCompany,
  deleteCompany,
  getAllCompany,
};
