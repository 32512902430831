import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import DataParser from "../pages/DataParser";
import DataConfig from "../pages/DataConfig";
import SideBar from "./Sidebar";
import Header from "./Header";
import Jobs from "../pages/Jobs";
import UserCreation from "../pages/UserCreation";
import Reports from "../pages/Reports";
import Automation from "../pages/Automation";
import AddJob from "../pages/Automation/AddJob";
import AdminPanel from "../pages/UserCreation/AdminPanel";
import MainTab from "../pages/Automation/MainTab";

const DefaultLayout = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <>
      <div className="wrapper">
        <SideBar sideBarOpen={sideBarOpen} />
        <div id="content">
          <Header setSideBarOpen={setSideBarOpen} sideBarOpen={sideBarOpen} />
          <main>
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/user-creation"
                element={
                  <ProtectedRoute>
                    <UserCreation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-creation/:type"
                element={
                  <ProtectedRoute>
                    <UserCreation />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/job"
                element={
                  <ProtectedRoute>
                    <Jobs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/job/:type"
                element={
                  <ProtectedRoute>
                    <Jobs />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/config/data-parser"
                element={
                  <ProtectedRoute>
                    <DataParser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/config/data-config"
                element={
                  <ProtectedRoute>
                    <DataConfig />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/config/data-validate"
                element={
                  <ProtectedRoute>
                    <DataConfig />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/reports"
                element={
                  <ProtectedRoute>
                    <Reports />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/add-job"
                element={
                  <ProtectedRoute>
                    <AddJob />
                  </ProtectedRoute>
                }
              /> */}

              <Route
                path="/admin-panel"
                element={
                  <ProtectedRoute>
                    <AdminPanel />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/tabs/:slug"
                element={
                  <ProtectedRoute>
                    <MainTab />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/automation"
                element={
                  <ProtectedRoute>
                    <Automation />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
