import React from "react";
import { Link, useParams } from "react-router-dom";
import UserTabs from "./tabs";
import AdminList from "./Admin";
import SubAdminList from "./SubAdmin";
import UserList from "./Users";

const UserCreation = () => {
  const { type = "user" } = useParams();
  localStorage.setItem("type", type);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 text-center sub_section">
            <div className="container">
              <h1 className="page_heading">User Management</h1>
              <Link
                style={{ textDecoration: "none" }}
                className="float-right btn_dashboard"
                to="/dashboard"
              >
                <i className="fa fa-home" />
                Go To Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <UserTabs type={type} />
      {type === "admin" && <AdminList />}
      {type === "sub-admin" && <SubAdminList />}
      {type === "user" && <UserList />}
    </div>
  );
};

export default UserCreation;
