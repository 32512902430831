import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const { data, handlePageClick } = props;
  const currentItem =
    data?.page === 1 ? data?.page : (data?.page - 1) * data?.page_size + 1;
  const nthItem = currentItem + (data?.results?.length - 1);
  return (
    <>
      {data?.results?.length > 0 && (
        <div className="table_pagination col-md-12">
          <p className="float-left pagination_text">
            Showing {currentItem} to {nthItem} of {data.total} results
          </p>
          <ul className="pagination float-right">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageCount={data.total / data.page_size}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination-num"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              activeClassName="active"
            />
          </ul>
        </div>
      )}
    </>
  );
};

export default Pagination;
