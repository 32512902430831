import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  updateUser,
  addUser,
  getUserDetail,
} from "../../../redux/actions/other";
import { setLoading } from "../../../redux/actions/other";

const UserCreate = ({ editId, open, handleClose, handleRefresh }) => {
  const dispatch = useDispatch();
  const [Admin, setSubAdmin] = useState({
    email: "",
    user_id: "",
    name: "",
    status: false,
    id: null,
  });

  useEffect(() => {
    setSubAdmin({
      email: "",
      user_id: "",
      name: "",
      status: false,
    });
    if (editId != null) {
      SubAdminDetail(editId);
    }
  }, [editId]);

  let SubAdminSchema = Yup.object().shape({
    name: Yup.string().required("The name is required."),
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
    id: Yup.string(),
    password: Yup.string()
      .when("id", {
        is: (id) => id == null,
        then: Yup.string().required("The Password is required"),
      })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    user_id: Yup.string().required("The user id is required."),
  });

  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    if (editId) {
      dispatch(updateUser(editId, values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(addUser(values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const closeModal = () => {
    setSubAdmin({
      id: null,
      email: "",
      user_id: "",
      name: "",
      status: false,
    });
    handleClose();
  };

  const SubAdminDetail = (id) => {
    dispatch(setLoading(true));
    dispatch(getUserDetail(id))
      .then((response) => {
        const result = response["data"];
        setSubAdmin({
          email: result["email"],
          user_id: result["user_id"],
          name: result["name"],
          status: result["status"],
          id: result["id"],
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <div className="addModals">
      <div
        className={`modal fade ${open}`}
        id="editData"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog  modal-lg modal-dialog-centered">
          <Formik
            initialValues={Admin}
            validationSchema={SubAdminSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, touched, resetForm, handleReset }) => (
              <Form className="">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title fw-bold"
                      style={{ color: "#2565ab" }}
                    >
                      {editId ? "Update" : "Add"} Detail
                    </h5>
                    <div
                      onClick={closeModal}
                      type="button"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="modal_form row">
                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="name"> Name </label>
                        <img
                          className="form_icon"
                          src={"/images/form_icon_user.png"}
                          alt=""
                        />
                        <Field
                          name="name"
                          placeholder="Enter Name"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="email"> Email </label>
                        <img
                          className="form_icon"
                          src={"/images/form_icon_mail.png"}
                          alt=""
                        />
                        <Field
                          name="email"
                          placeholder="Enter Email"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="user_id"> User ID </label>
                        <img
                          className="form_icon"
                          src={"/images/form_icon_user.png"}
                          alt=""
                        />
                        <Field
                          name="user_id"
                          placeholder="Enter User id"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.user_id && errors.user_id
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="user_id"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="password"> Password </label>
                        <img
                          className="form_icon"
                          src={"/images/form_icon_user.png"}
                          alt=""
                        />
                        <Field
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          className={`form-control border-0 border-bottom bg-transparent ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group col-md-6 col-sm-12">
                        <label htmlFor="status">Status </label>
                        <div className="custom-switch d-flex">
                          <Field
                            type="checkbox"
                            name="status"
                            className="custom-control-input"
                            id="enable_btn"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="enable_btn"
                          />
                          <p style={{ marginLeft: "10px" }}>Enable/Disable</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary blue_button"
                    >
                      {editId ? "Update" : "Submit"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => handleReset(resetForm)}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UserCreate;
