import { Box, TextField } from "@mui/material";
import React from "react";
import {
  MdAccountCircle,
  MdBrandingWatermark,
  MdWorkspacesFilled,
} from "react-icons/md";
import { BsPassFill, BsUniversalAccessCircle } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";

function AdminPanel({ type }) {
  return (
    <div className="container">
      <div className="col-md-12 table_section">
        <div className="row table_box">
          <div className={"col-md-3"}>
            <h4>Admin Panel</h4>
          </div>
          <div>
            <Box
              sx={{
                width: "full",
                maxWidth: "100%",
              }}
            >
              <TextField fullWidth id="fullWidth" placeholder="Search" />
            </Box>
          </div>
          <div className={"col-md-6 mt-4"}>
            <h4>User Management</h4>
            <p className="" style={{ fontSize: ".8rem" }}>
              Manage Account Configuration and customizations for your service
              desk
            </p>
          </div>
          <div className="container sub_page">
            <div className="col-sm-12 col-lg-12 col-md-12 services_section ">
              <div className="row p-5">
                <div className="col-md-4 col-lg-6 col-sm-4 ">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to="/user-creation/admin"
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow ${
                        type === "admin" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                              alt=""
                            />
                            <div
                              className="text-muted small fw-bold"
                              style={{ marginLeft: "10rem" }}
                            >
                              Ai Bits Users
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-6 col-sm-4 ">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to="/user-creation/sub-admin"
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow ${
                        type === "sub-admin" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                              alt=""
                            />
                            <div
                              className="text-muted small fw-bold"
                              style={{ marginLeft: "9rem" }}
                            >
                              Corp Customer Users
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-6 col-sm-4 ">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to="/user-creation/user"
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow${
                        type === "user" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                              alt=""
                            />
                            <div
                              className="text-muted small fw-bold"
                              style={{ marginLeft: "9rem" }}
                            >
                              Company Users
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-6 col-sm-4 ">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to=""
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow${
                        type === "user" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                              alt=""
                            />
                            <div
                              className="text-muted small fw-bold"
                              style={{ marginLeft: "10rem" }}
                            >
                              Roles
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <h4>Ai Bit Masters</h4>
          <p className="" style={{ fontSize: ".8rem" }}>
            Manage Account Configuration and customizations for your service
            desk
          </p>
          <div className="container sub_page">
            <div className="col-sm-12 col-lg-12 col-md-12 services_section">
              <div className="row p-5">
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to="/job/company"
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow${
                        type === "company" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                            />
                            <div
                              className="text-muted small fw-bold "
                              style={{ marginLeft: "11rem" }}
                            >
                              Company
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    to="/job/corporate"
                  >
                    <div
                      className={`widget-stat card bg-dashboard shadow${
                        type === "corporate" ? "active-widget" : ""
                      } `}
                      style={{ borderRadius: 15 }}
                    >
                      <div className="card-body ">
                        <div className="media">
                          <div>
                            <img
                              src={"/images/icon_sub_domain.png"}
                              className="services_icon"
                            />
                            <div
                              className="text-muted small fw-bold"
                              style={{ marginLeft: "11rem" }}
                            >
                              Corp Customer
                              <i className="fa fa-arrow-circle-right" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
