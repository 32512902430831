/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth";
import { setLoading } from "../redux/actions/other";
import { useSelector } from "react-redux";

const Header = ({ setSideBarOpen, sideBarOpen }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    if (sideBarOpen === true) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

  const logoutApi = () => {
    dispatch(setLoading(true));
    dispatch(logout())
      .then((response) => {
        dispatch(setLoading(false));
        // navigate("/");
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light"
      style={{ height: "56px" }}
    >
      <div className="container-fluid mt-1">
        <button
          className="openbtn"
          onClick={toggleSidebar}
          style={{ height: "46px" }}
        >
          <span
            className="fs-6 text"
            style={{ position: "relative", bottom: "3px" }}
          >
            menu
          </span>
        </button>
        <button
          className="btn btn-dark d-inline-block d-lg-none ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-align-justify" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                onClick={toggleOpen}
                style={{ width: "171px" }}
              >
                <img
                  src={"/images/profile-img.png"}
                  width={"40px"}
                  height={"40px"}
                  alt=""
                />{" "}
                {user ? user.name : "Admin User"}{" "}
              </a>
              <div className="dropdown bg-transparent" onClick={toggleOpen}>
                <div className={menuClass} aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" onClick={logoutApi}>
                    Log Out
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
