import React from "react";
import { Link } from "react-router-dom";

const JobTabs = ({ type }) => {
  return (
    <div className="container sub_page">
      <div className="col-sm-12 col-lg-12 col-md-12 services_section">
        <div className="row p-5">
          <div className="col-md-6 col-lg-6 col-sm-6">
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/job/company"
            >
              <div
                className={`widget-stat card bg-dashboard ${
                  type === "company" ? "active-widget" : ""
                } `}
                style={{ borderRadius: 15 }}
              >
                <div className="card-body ">
                  <div className="media">
                    <div>
                      <img
                        src={"/images/icon_sub_domain.png"}
                        className="services_icon"
                      />
                      <div
                        className="text-muted small fw-bold "
                        style={{ marginLeft: "13rem" }}
                      >
                        Company
                        <i className="fa fa-arrow-circle-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-lg-6 col-sm-6">
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/job/corporate"
            >
              <div
                className={`widget-stat card bg-dashboard ${
                  type === "corporate" ? "active-widget" : ""
                } `}
                style={{ borderRadius: 15 }}
              >
                <div className="card-body ">
                  <div className="media">
                    <div>
                      <img
                        src={"/images/icon_sub_domain.png"}
                        className="services_icon"
                      />
                      <div
                        className="text-muted small fw-bold"
                        style={{ marginLeft: "12rem" }}
                      >
                        Corp Customer
                        <i className="fa fa-arrow-circle-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* <div className="col-md-3 col-lg-3 col-sm-6">
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/job/jobs"
            >
              <div
                className={`widget-stat card bg-dashboard ${
                  type === "jobs" ? "active-widget" : ""
                } `}
                style={{ borderRadius: 15 }}
              >
                <div className="card-body ">
                  <div className="media">
                    <div>
                      <img
                        src={"/images/icon_sub_domain.png"}
                        className="services_icon"
                      />
                      <div
                        className="text-muted small fw-bold"
                        style={{ marginLeft: "6.5rem" }}
                      >
                        Jobs
                        <i className="fa fa-arrow-circle-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
          {/* <div className="col-md-3 col-lg-3 col-sm-6">
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/job/summary"
            >
              <div
                className={`widget-stat card bg-dashboard ${
                  type === "summary" ? "active-widget" : ""
                } `}
                style={{ borderRadius: 15 }}
              >
                <div className="card-body ">
                  <div className="media">
                    <div>
                      <img
                        src={"/images/icon_sub_domain.png"}
                        className="services_icon"
                      />
                      <div
                        className="text-muted small fw-bold"
                        style={{ marginLeft: "6.5rem" }}
                      >
                        Summary
                        <i className="fa fa-arrow-circle-right" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default JobTabs;
