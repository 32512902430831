import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  addCompany,
  getCompanyDetail,
  setLoading,
  updateCompany,
} from "../../../redux/actions/other";
import { useDispatch } from "react-redux";

const CompanyCreate = ({ editId, open, handleClose, handleRefresh }) => {
  const dispatch = useDispatch();
  const companyInitialValue = {
    name: "",
    contact_no: "",
    email: "",
    address: "",
    no_pods: "",
    dev_url: "",
    dev_user_id: "",
    dev_password: "",
    prod_url: "",
    prod_user_id: "",
    prod_password: "",
  };
  const [initialValue, setInitialValue] = useState(companyInitialValue);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("The Email is required"),
    address: Yup.string().required("The Address is required"),
    name: Yup.string().required("The name is required."),
    contact_no: Yup.string().required("The Contact number is required"),
    no_pods: Yup.number().required("The Number of pods is required."),
    dev_user_id: Yup.string().required("The dev user id  is required."),
    dev_password: Yup.string().required("The password is required."),
    dev_url: Yup.string()
      /*.matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                "Enter correct url!"
            )*/
      .required("The dev url is required."),
    prod_url: Yup.string()
      /*.matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                "Enter correct url!"
            )*/
      .required("The prod url is required."),
    prod_user_id: Yup.string().required("The prod user id  is required."),
    prod_password: Yup.string().required("The prod password is required."),
  });

  useEffect(() => {
    setInitialValue(companyInitialValue);
    if (editId != null) {
      companyDetail(editId);
    }
  }, [editId]);

  const companyDetail = (id) => {
    dispatch(setLoading(true));
    dispatch(getCompanyDetail(id))
      .then((response) => {
        let companyData = response["data"];
        setInitialValue({
          name: companyData.name,
          contact_no: companyData.contact_no,
          email: companyData.email,
          address: companyData.address,
          no_pods: companyData.no_pods,
          dev_url: companyData.dev_url,
          dev_user_id: companyData.dev_user_id,
          dev_password: companyData.dev_password,
          prod_url: companyData.prod_url,
          prod_user_id: companyData.prod_user_id,
          prod_password: companyData.prod_password,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    if (editId) {
      dispatch(updateCompany(editId, values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      dispatch(addCompany(values))
        .then((response) => {
          dispatch(setLoading(false));
          handleRefresh();
          closeModal();
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const closeModal = () => {
    setInitialValue(companyInitialValue);
    handleClose();
  };

  return (
    <div
      className={`modal fade ${open}`}
      id="editData"
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, resetForm, handleReset }) => (
            <Form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title fw-bold"
                    style={{ color: "#2565ab" }}
                  >
                    {editId ? "Update" : "Add"} Detail
                  </h5>
                  <div
                    onClick={closeModal}
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="modal_form row">
                    <div className="form-group col-md-4 col-sm-6">
                      <label htmlFor="name">POC Name</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_user.png"}
                        alt=""
                      />
                      <Field
                        type={"text"}
                        placeholder="Name"
                        name={`name`}
                        id={"name"}
                        className={`form-control ${
                          touched.name && errors.name ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-6">
                      <label htmlFor="contact_no">POC Contact No</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_phone.png"}
                        alt=""
                      />
                      <Field
                        type={"text"}
                        placeholder="contact no"
                        name={`contact_no`}
                        className={`form-control ${
                          touched.contact_no && errors.contact_no
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="contact_no"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-6">
                      <label htmlFor="Email">POC Email</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_mail.png"
                        alt=""
                      />
                      <Field
                        type={"email"}
                        placeholder="email"
                        name={`email`}
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-6">
                      <label htmlFor="address">POC Address </label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_address.png"
                        alt=""
                      />
                      <Field
                        type={"text"}
                        placeholder="Address"
                        name={`address`}
                        className={`form-control ${
                          touched.address && errors.address ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="address"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-4 col-sm-6">
                      <label htmlFor="no_pods">No. of PODs</label>
                      <img
                        className="form_icon"
                        src={"/images/form_icon_setting.png"}
                        alt=""
                      />
                      <Field
                        type={"number"}
                        placeholder="Enter no pods"
                        name={`no_pods`}
                        className={`form-control ${
                          touched.no_pods && errors.no_pods ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="no_pods"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="modal_form sub_modal_form row mb-3">
                    <div className="title">FTP Drop Location (Development)</div>

                    <div className="form-group col-md-12 col-sm-12">
                      <label htmlFor="dev_url">URL</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_user.png"
                        alt=""
                      />
                      <Field
                        type={"url"}
                        placeholder="Enter URL"
                        name={`dev_url`}
                        className={`form-control ${
                          touched.dev_url && errors.dev_url ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="dev_url"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="dev_user_id">User ID</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_user.png"
                        alt=""
                      />
                      <Field
                        type={"text"}
                        placeholder="Enter User Id"
                        name={`dev_user_id`}
                        className={`form-control ${
                          touched.dev_user_id && errors.dev_user_id
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="dev_user_id"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="dev_password">Password</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_password.png"
                        alt=""
                      />
                      <Field
                        type={"password"}
                        placeholder="Enter User Password"
                        name={`dev_password`}
                        className={`form-control ${
                          touched.dev_password && errors.dev_password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="dev_password"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="modal_form sub_modal_form row mb-3">
                    <div className="title">FTP Drop Location (Production)</div>

                    <div className="form-group col-md-12 col-sm-12">
                      <label htmlFor="prod_url">URL</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_user.png"
                        alt=""
                      />
                      <Field
                        type={"url"}
                        placeholder="Enter URL"
                        name={`prod_url`}
                        className={`form-control ${
                          touched.prod_url && errors.prod_url
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="prod_url"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="prod_user_id">User ID</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_user.png"
                        alt=""
                      />
                      <Field
                        type={"text"}
                        placeholder="Enter User Id"
                        name={`prod_user_id`}
                        className={`form-control ${
                          touched.prod_user_id && errors.prod_user_id
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="prod_user_id"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label htmlFor="prod_password">Password</label>
                      <img
                        className="form_icon"
                        src="/images/form_icon_password.png"
                        alt=""
                      />
                      <Field
                        type={"password"}
                        placeholder="Enter User Password"
                        name={`prod_password`}
                        className={`form-control ${
                          touched.prod_password && errors.prod_password
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="prod_password"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-center">
                  <button type="submit" className="btn btn-primary blue_button">
                    {editId ? "Update" : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CompanyCreate;
