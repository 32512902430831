import { Input } from "antd";
import React from "react";

export const MandatoryGroupSheetTableValidation = (props) => {
  const groupCheckData = props.groupCheckData;
  console.log(groupCheckData);
  // const groupCheckData = [{"id":0,"field":"Internal ID","alias":"Internal ID","mandatory":false,"group_check":false},{"id":1,"field":"PO Date","alias":"PO Date","mandatory":false,"group_check":false},{"id":2,"field":"In Augusta Date","alias":"In Augusta Date","mandatory":false,"group_check":false},{"id":3,"field":"Document Number","alias":"Document Number","mandatory":false,"group_check":false},{"id":4,"field":"Vendor Name","alias":"Vendor Name","mandatory":false,"group_check":false},{"id":5,"field":"Style Number","alias":"Style Number","mandatory":false,"group_check":false},{"id":6,"field":"Color","alias":"Color","mandatory":false,"group_check":false},{"id":7,"field":"Item Size","alias":"Item Size","mandatory":false,"group_check":false},{"id":8,"field":"SKU","alias":"SKU","mandatory":false,"group_check":false},{"id":9,"field":"Display Name","alias":"Display Name","mandatory":false,"group_check":false},{"id":10,"field":"Quantity","alias":"Quantity","mandatory":false,"group_check":false},{"id":11,"field":"Retail","alias":"Retail","mandatory":false,"group_check":false},{"id":12,"field":"Hangtag Product ID","alias":"Hangtag Product ID","mandatory":false,"group_check":false},{"id":13,"field":"Polybag Product ID","alias":"Polybag Product ID","mandatory":false,"group_check":false},{"id":14,"field":"Carton Product ID","alias":"Carton Product ID","mandatory":false,"group_check":false},{"id":15,"field":"COO","alias":"COO","mandatory":false,"group_check":false}];
  const { onclickMandatory, onClickGroupCheck, onTypeAlias } = props;
  return (
    <>
      <div className="row table_box red_table mt-3">
        {groupCheckData && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Fields</th>
                <th scope="col">Alias</th>
                <th scope="col">Mandatory</th>
                <th scope="col">Group Check</th>
              </tr>
            </thead>
            <tbody>
              {groupCheckData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item["field"]}</td>
                    <td>{item["alias"]}</td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input check-box"
                        defaultChecked={item["mandatory"]}
                        value=""
                        onChange={(e) => {
                          onclickMandatory(e, index);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input check-box"
                        defaultChecked={item["group_check"]}
                        value=""
                        onChange={(e) => {
                          onClickGroupCheck(e, index);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
